import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPhasesBySocietyId } from '../@store/phase/phaseActions';

const usePhases = (societyId) => {
  const dispatch = useDispatch();
  const { phases, loading, error } = useSelector((state) => state.phase);
  const [filteredPhases, setFilteredPhases] = useState([]);

  useEffect(() => {
    if (societyId) {
      dispatch(fetchPhasesBySocietyId(societyId));
    } else {
      setFilteredPhases([]);
    }
  }, [societyId]);

  useEffect(() => {
    if (societyId && Array.isArray(phases) && phases.length > 0) {
      setFilteredPhases(phases);
    }
  }, [phases]);

  return { phases: filteredPhases, loading, error };
};

export { usePhases };
