import {
	createBrowserRouter,
	createRoutesFromElements,
	Navigate,
	Route,
} from 'react-router-dom';
import {
	LoginSignup,
	NotFound,
	Projects,
	ResetPassword,
	SearchList,
	SocietyManagement,
	Users,
	WorkPlace,
	CustomerManagement,
} from './pages';
import {
	Admin,
	SocietyHead,
	SocietyStaff,
	SuperAdmin,
} from './components/ProtectedLayout';
import Tutorial from './pages/Tutorial/Tutorial';
import SocietyProfile from './components/Profiles/societyProfile';
import { PhaseManagement } from './pages/Phases/PhaseManagement';
import BankManagement from './pages/Banks/BankManagement';
import SalesOverview from './pages/Sales/SalesOverview';
import CreateSale from './pages/Sales/CreateSale';
import SalesManagement from './pages/Sales/SalesManagement';
import ConfirmSales from './pages/Sales/ConfirmSales';
import ExpenseManagement from './pages/Expenses/ExpenseManagement';
import CreateExpense from './pages/Expenses/CreateExpense';
import ConfirmExpenses from './pages/Expenses/ConfirmExpenses';
import AgentManagement from './pages/Agent/AgentManagement';
import PlotManagement from './pages/Plots/PlotManagement';
import GenerateSalesPDF from '@pages/Sales/GenerateSalesPDF';

const router = createBrowserRouter(
	createRoutesFromElements(
		<>
			{/* -------  super admin ----- */}
			<Route element={<SuperAdmin />}>
				<Route
					path='/super-admin/societies/search-list'
					element={<SearchList />}
				/>
				<Route index path='/super-admin/workplace' element={<WorkPlace />} />
				<Route path='/super-admin/projects' element={<Projects />} />
				<Route path='/super-admin/users' element={<Users />} />
				<Route path='/super-admin/customers' element={<CustomerManagement />} />
				<Route path='/super-admin/banks' element={<BankManagement />} />
				<Route path='/super-admin/salesOverview' element={<SalesOverview />} />
				<Route path='/super-admin/expenses' element={<ExpenseManagement />} />
				<Route
					path='/super-admin/expenses/create'
					element={<CreateExpense />}
				/>
				<Route
					path={'/super-admin/expenses/confirm'}
					element={<ConfirmExpenses />}
				/>
				<Route
					path='/super-admin/expenses/create/search'
					element={<CreateExpense />}
				/>
				<Route
					path='/super-admin/sales'
					element={<SalesManagement confirmSalesMode={false} />}
				/>
				<Route path='/super-admin/pdf-sales' element={<GenerateSalesPDF />} />
				<Route path='/super-admin/sales/create' element={<CreateSale />} />
				<Route path='/super-admin/sales/confirm' element={<ConfirmSales />} />
				<Route
					path='/super-admin/society/create'
					element={<SocietyManagement />}
				/>
				<Route
					path='/super-admin/societies/profile/:id'
					element={<SocietyProfile />}
				/>
				<Route
					path='/super-admin/societies/phases/:id'
					element={<PhaseManagement />}
				/>
				<Route path='/super-admin/plots' element={<PlotManagement />} />
				<Route
					path='/super-admin/society/edit/:id'
					element={<SocietyManagement />}
				/>
				<Route path='/super-admin/agent/' element={<AgentManagement />} />
				<Route path='/super-admin/tutorials' element={<Tutorial />} />
			</Route>
			{/* -------   admin ----- */}
			<Route element={<Admin />}>
				<Route path='/admin/workplace' element={<WorkPlace />} />
				<Route path='/admin/projects' element={<Projects />} />
				<Route path='/admin/users' element={<Users />} />
				<Route path='/admin/search-list' element={<SearchList />} />
				<Route path='/admin/tutorial' element={<Tutorial />} />
				<Route path='/admin/sales' element={<SalesManagement />} />
				<Route path='/admin/sales/create' element={<CreateSale />} />
				<Route path='/admin/sales/confirm' element={<ConfirmSales />} />
				<Route path='/admin/banks' element={<BankManagement />} />
				<Route path='/admin/customers' element={<CustomerManagement />} />
				<Route path='/admin/society/create' element={<SocietyManagement />} />
				<Route path='/admin/societies/search-list' element={<SearchList />} />
				<Route path='/admin/society/edit/:id' element={<SocietyManagement />} />
				<Route
					path='/admin/societies/profile/:id'
					element={<SocietyProfile />}
				/>
				<Route
					path='/admin/societies/phases/:id'
					element={<PhaseManagement />}
				/>
				<Route path='/admin/tutorials' element={<Tutorial />} />
			</Route>
			{/* -------   Society head ----- */}
			<Route element={<SocietyHead />}>
				<Route path='/society-head/projects' element={<Projects />} />
				<Route path='/society-head/users' element={<Users />} />
				<Route path='/society-head/search-list' element={<SearchList />} />
				<Route path='/society-head/tutorial' element={<Tutorial />} />
				<Route path='/society-head/sales' element={<SalesManagement />} />
				<Route path='/society-head/banks' element={<BankManagement />} />
				<Route path='/society-head/sales/create' element={<CreateSale />} />
				<Route
					path='/society-head/customers'
					element={<CustomerManagement />}
				/>
				<Route
					path='/society-head/societies/profile/:id'
					element={<SocietyProfile />}
				/>

				<Route
					path='/society-head/societies/phases/:id'
					element={<PhaseManagement />}
				/>
				<Route path='/society-head/expenses' element={<ExpenseManagement />} />
				<Route
					path='/society-head/expenses/create'
					element={<CreateExpense />}
				/>
			</Route>
			{/* -------  Society staff  ----- */}
			<Route element={<SocietyStaff />}>
				<Route path='/society-staff/projects' element={<Projects />} />
				<Route path='/society-staff/users' element={<Users />} />
				<Route path='/society-staff/search-list' element={<SearchList />} />
				<Route path='/society-staff/tutorial' element={<Tutorial />} />
				<Route path='/society-staff/sales' element={<SalesManagement />} />
				<Route path='/society-staff/sales/create' element={<CreateSale />} />

				<Route
					path='/society-staff/societies/profile/:id'
					element={<SocietyProfile />}
				/>

				<Route
					path='/society-staff/societies/phases/:id'
					element={<PhaseManagement />}
				/>
				<Route
					path='/society-staff/customers'
					element={<CustomerManagement />}
				/>
				<Route path='/society-staff/expenses' element={<ExpenseManagement />} />
				<Route
					path='/society-staff/expenses/create'
					element={<CreateExpense />}
				/>
			</Route>
			{/* -------  non protected routes ----- */}
			<Route>
				<Route index path='/' element={<Navigate to='/login-signup' />} />
				<Route path='/login-signup' element={<LoginSignup />} />
				<Route path='/reset-password' element={<ResetPassword />} />
				<Route path='*' element={<NotFound />} />
			</Route>
		</>
	)
);

export default router;
