import React, { useEffect, useLayoutEffect, useState } from 'react';
import Modal from '../macros/Modal';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Radio,
  Select,
  Spin,
  Switch,
} from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import useBanks from '../../hooks/useBanks';

const BankForm = ({
  openModal,
  handleClose,
  form,
  onFinish,
  isEdit,
  toEdit,
}) => {
  const { loading, formedBanks } = useBanks('main');
  const defaultValue =
    (isEdit &&
      Array.isArray(formedBanks) &&
      formedBanks?.find((bank) => bank.value === toEdit?.parentBanks[0])) ||
    {};
  const [isSelectEnabled, setIsSelectEnabled] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsSelectEnabled(e.target.checked);
    if (!e.target.checked) {
      form.setFieldsValue({ parentBanks: '' });
    }
  };

  useLayoutEffect(() => {
    if (Object.keys(defaultValue).length > 0) {
      setIsSelectEnabled(true);
    }
  }, [isEdit, defaultValue]);

  const options = [
    { value: 'savings', label: 'Savings' },
    { value: 'current', label: 'Current' },
    { value: 'fixed_deposit', label: 'Fixed' },
  ];

  return (
    <>
      <Modal
        open={openModal}
        handleClose={() => {
          handleClose();
          setIsSelectEnabled(false);
        }}
        className='w-[full]'
      >
        <Spin spinning={loading}>
          <div className='w-[500px]'>
            <Form
              form={form}
              onFinish={onFinish}
              layout='vertical'
              name='basic'
              className='w-full'
            >
              <Form.Item
                label='Account Number'
                name='accountNumber'
                rules={[
                  {
                    required: true,
                    message: 'Please input account number!',
                  },
                ]}
                className='w-full'
              >
                <Input />
              </Form.Item>
              <div className='flex justify-between gap-4'>
                <Form.Item
                  label='Account Type'
                  name='accountType'
                  style={{ width: '100%' }}
                  rules={[
                    {
                      required: true,
                      message: 'Please input account type!',
                    },
                  ]}
                >
                  <Radio.Group>
                    {options.map((option) => (
                      <Radio key={option.value} value={option.value}>
                        {option.label}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  style={{ width: '100%' }}
                  label='Bank Name'
                  name='bankName'
                  rules={[
                    { required: true, message: 'Please input bank name!' },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className='flex justify-between gap-4'>
                <Form.Item
                  label='Branch'
                  name='branch'
                  style={{ width: '100%' }}
                  rules={[
                    { required: true, message: 'Please input branch details!' },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%' }}
                  label='Opening Balance'
                  name='balance'
                  rules={[{ required: true, message: 'Please input balance!' }]}
                >
                  <Input type='number' />
                </Form.Item>
              </div>
              <h3>Account Holder</h3>
              <div className='flex  gap-4 w-full'>
                <Form.Item
                  label='First Name'
                  name='firstName'
                  style={{ width: '100%' }}
                  rules={[
                    {
                      required: true,
                      message: 'Please input first name!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label='Last Name'
                  name='lastName'
                  style={{ width: '100%' }}
                  rules={[
                    {
                      required: true,
                      message: 'Please input last name!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>

              <div className='flex justify-between gap-4'>
                <Form.Item
                  label='Email'
                  name='email'
                  style={{ width: '100%' }}
                  rules={[
                    {
                      required: true,
                      message: 'Please input email!',
                    },
                  ]}
                >
                  <Input type='email' />
                </Form.Item>
                <Form.Item
                  label='Phone Number'
                  name='phoneNumber'
                  style={{ width: '100%' }}
                  rules={[
                    {
                      required: true,
                      message: 'Please input email!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className='flex items-center gap-4 justify-between w-full'>
                <Checkbox
                  checked={isSelectEnabled}
                  onChange={handleCheckboxChange}
                  style={{ width: '100%' }}
                >
                  Sub Branch
                </Checkbox>
                <Form.Item
                  label='Select Parent Bank'
                  name='parentBanks'
                  style={{ width: '100%' }}
                  rules={[
                    {
                      required: formedBanks.length > 0 && isSelectEnabled,
                      message: 'Please select bank!',
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    defaultValue={defaultValue || {}}
                    disabled={!isSelectEnabled}
                    
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={formedBanks}
                  />
                </Form.Item>
              </div>
              <div className='flex justify-between'>
                <Button
                  block
                  icon={isEdit ? <EditOutlined /> : <PlusOutlined />}
                  className='w-full'
                  loading={loading}
                  type='primary'
                  htmlType='submit'
                >
                  {isEdit ? 'Edit Bank' : 'Add Bank'}
                </Button>
              </div>
            </Form>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default BankForm;
