import { Button, Form, Input, Modal } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createAgent, updateAgent } from '../../@store/users/userActions';

function AgentModal({ openModal, current, form, isEdit, handleCancel }) {
	const dispatch = useDispatch();
	const onFinish = (data) => {
		if (isEdit) {
			dispatch(
				updateAgent({
					id: current,
					info: { ...data, phone: '+92' + data.phone },
				})
			)
				.unwrap()
				.then(() => {
					handleCancel();
				});
		} else {
			dispatch(createAgent({ ...data, phone: '+92' + data.phone }))
				.unwrap()
				.then(() => {
					handleCancel();
				});
		}
	};
	return (
		<Modal
			title='Add Agent Info'
			visible={openModal}
			onCancel={handleCancel}
			footer={null}
		>
			<Form form={form} layout='vertical' name='basic' onFinish={onFinish}>
				<Form.Item
					name='name'
					label='Name'
					rules={[{ required: true, message: 'Please input a name!' }]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label='CNIC'
					name='cnic'
					rules={[
						{ required: true, message: 'Please input CNIC!' },
						{
							pattern: /^\d{5}-\d{7}-\d{1}$/,
							message: 'Invalid CNIC format!',
						},
						{ len: 15, message: 'CNIC must be 15 characters!' },
					]}
				>
					<Input placeholder='23322-9511870-1' />
				</Form.Item>

				<Form.Item
					label='Phone'
					name='phone'
					rules={[
						{ required: true, message: 'Please enter your phone number' },
						{
							pattern: /^[0-9]{10}$/,
							message: 'Please enter a valid phone number (+92)',
						},
					]}
				>
					<Input addonBefore='+92' placeholder='3311345678' />
				</Form.Item>

				<Form.Item>
					<Button className='w-full' type='primary' htmlType='submit'>
						Submit
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	);
}

export default AgentModal;
