import AWS from 'aws-sdk';

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AMAZON_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AMAZON_SECRET_ACCESS_KEY,
  region: 'ap-south-1',
});

async function uploadFilesToS3(files) {
  const uploadPromises = files.map(async (file) => {
    const sanitizedFileName = file.name.replace(/\s+/g, '_');
    const fileName = `${Date.now()}-${sanitizedFileName}`;

    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: fileName,
      ContentType: file.type,
      Body: file,
      ACL: 'public-read',
    };

    const result = await s3.putObject(params).promise();

    const { REACT_APP_BUCKET_NAME } = process.env;
    const { region } = s3.config;
    const s3ObjectLink = `https://${REACT_APP_BUCKET_NAME}.s3.${region}.amazonaws.com/${encodeURIComponent(
      fileName
    )}`;
    return { url: s3ObjectLink, fileName };
  });

  const results = await Promise.all(uploadPromises);

  return results.map(({ url }) => url);
}

export default uploadFilesToS3;
