import React, { useEffect, useState } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Spin } from 'antd';
import { loginUser } from '../../@store/auth/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { redirectToWorkplace } from '../../utils/functions';

function Login() {
  const dispatch = useDispatch();
  const { loading, entities } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => setIsLoading(loading), [loading]);

  const onFinish = async (values) => {
    dispatch(
      loginUser({
        email: values.email,
        password: values.password,
      })
    );
  };

  return (
    <div className='max-w-[400px] w-full'>
      <Form
        name='normal_login'
        className='login-form'
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name='email'
          rules={[
            {
              required: true,
              message: 'Please input your email!',
            },
          ]}
        >
          <Input
            prefix={
              <UserOutlined className='site-form-item-icon' color='#ffff' />
            }
            placeholder='Email'
          />
        </Form.Item>
        <Form.Item
          name='password'
          rules={[
            {
              required: true,
              message: 'Please input your Password!',
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className='site-form-item-icon' />}
            type='password'
            placeholder='Password'
          />
        </Form.Item>
        <Form.Item>
          <div className='flex justify-between w-full'>
            <Form.Item name='remember' valuePropName='checked' noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <a
              className='login-form-forgot text-[#4942E4]'
              href='/reset-password'
            >
              Forgot password?
            </a>
          </div>
        </Form.Item>

        <Form.Item>
          <Button
            loading={isLoading}
            type='primary'
            htmlType='submit'
            className='bg-[#4942E4]'
            // className="login-form-button"
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Login;
