import { createSlice } from "@reduxjs/toolkit";
import {
  createExpense,
  confirmExpense,
  getAllExpenses,
  getExpenseById,
  updateExpense,
  deleteExpense,
} from "./expenseActions";

const expensesSlice = createSlice({
  name: "expense",
  initialState: {
    expenses: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createExpense.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createExpense.fulfilled, (state, action) => {
        state.expenses.push(action.payload);
        state.loading = false;
      })
      .addCase(createExpense.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllExpenses.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllExpenses.fulfilled, (state, action) => {
        state.loading = false;
        state.expenses = action.payload;
      })
      .addCase(getAllExpenses.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateExpense.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateExpense.fulfilled, (state, action) => {
        state.loading = false;
        const { expense } = action.payload;
        const index = state.expenses.findIndex((e) => e._id === expense);
        if (index >= 0) {
          state.expenses[index] = expense;
        }
      })
      .addCase(updateExpense.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteExpense.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteExpense.fulfilled, (state, action) => {
        state.loading = false;
        state.expenses = state.expenses.filter(
          (expense) => expense._id !== action.payload
        );
      })
      .addCase(deleteExpense.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getExpenseById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getExpenseById.fulfilled, (state, action) => {
        state.loading = false;
        state.expenses.map((expense) => {
          if (expense._id === action.payload._id) {
            expense = action.payload;
          }
        });
      })
      .addCase(getExpenseById.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(confirmExpense.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(confirmExpense.fulfilled, (state, action) => {
        state.loading = false;
        state.expenses.map((expense) => {
          if (expense._id === action.payload._id) {
            expense = action.payload;
          }
        });
      })
      .addCase(confirmExpense.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default expensesSlice.reducer;
