import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../@api/axios';
import { message } from 'antd';
import { updatePlot } from '../plots/plotActions';
import { isObject } from '../../utils/functions';
import uploadFilesToS3 from '../../utils/s3FileUpload';
const createSale = createAsyncThunk(
	'sale/add',
	async (salesData, { rejectWithValue }) => {
		try {
			let data = salesData;
			let resp;
			let uploadedFiles;

			if (data.files.length > 0) {
				uploadedFiles = await uploadFilesToS3(salesData.files);
				if (uploadedFiles.length > 0) {
					data = {
						...salesData,
						files: uploadedFiles,
					};
				}
			}

			const response = axiosInstance.post('/sales', data).then((res) => {
				resp = res;
				axiosInstance
					.put(`/plots/${salesData.plot}`, { status: 'sold' })
					.then((plotResponse) => {
						console.log('plotResponse plot sold: ', plotResponse);
					});
			});
			message.success('Sale created Successfully');

			return response.data;
		} catch (err) {
			message.error(err.message);
			return rejectWithValue(err.response.data);
		}
	}
);

const getAllSales = createAsyncThunk(
	'sale/getAll',
	async (_, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get('/sales');
			return response?.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

const getSaleById = createAsyncThunk(
	'sale/getById',
	async (saleId, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(`/sales/${saleId}`);
			console.log('getSaleById', response.data);
			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

const updateSale = createAsyncThunk(
	'sale/update',
	async ({ saleId, sale }, { rejectWithValue }) => {
		try {
			let newUrls;
			let newData = sale;

			const newFiles = newData.files.filter((file) => typeof file !== 'string');

			//--- upload files and logos to the s3
			if (newFiles.length >= 0) {
				newUrls = await Promise.all([uploadFilesToS3(newFiles)]);
			}

			// --- check if files are upload then add/merge with the prev files
			if (newUrls[0].length >= 0 && newFiles) {
				newData.files = newData.files.concat(newUrls[0]);
				newData.files = newData.files.filter(
					(item) => typeof item === 'string'
				);
			}

			const updatedInfo = {
				...newData,
				society: isObject(newData.society)
					? newData.society.key
					: newData.society,
				plot: isObject(newData.plot) ? newData.plot.key : newData.plot,
				phase: isObject(newData.phase) ? newData.phase.key : newData.phase,
				customer: isObject(newData.customer)
					? newData.customer.key
					: newData.customer,
			};

			const response = await axiosInstance.put(`/sales/${saleId}`, updatedInfo);
			message.success('Sale updated Successfully');
			return response.data;
		} catch (err) {
			console.log(err);
			message.error(err.message);
			return rejectWithValue(err.response.data);
		}
	}
);

const deleteSale = createAsyncThunk(
	'sale/delete',
	async (salesId, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.delete(`/sales/${salesId}`);
			message.success('Sale deleted Successfully');
			return response.data;
		} catch (err) {
			message.error(err.message);
			return rejectWithValue(err.response.data);
		}
	}
);

const updateInstallment = createAsyncThunk(
	'installment/add',
	async ({ saleId, installment }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.patch(
				`sales/installments/${saleId}`,
				installment
			);
			message.success('Installment Paid Successfully');
			return response.data;
		} catch (err) {
			message.error(err.message);
			return rejectWithValue(err.response.data);
		}
	}
);

const deleteInstallment = createAsyncThunk(
	'installment/delete',
	async ({ saleId, installmentNumber }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.delete(
				`sales/${saleId}/installments/${installmentNumber}`
			);
			message.success('Installment Deleted Successfully');
			return response.data;
		} catch (err) {
			message.error(err.message);
			return rejectWithValue(err.response.data);
		}
	}
);

const confirmSales = createAsyncThunk(
	'sales/confirm',
	async ({ sales, installmentsSelect }, { rejectWithValue }) => {
		try {
			const user = JSON.parse(localStorage.getItem('user'));
			const salesIds = sales.map((sale) => sale._id);

			const installmentIds = installmentsSelect.map(
				(installment) => installment._id
			);
			const response = await axiosInstance.patch(`/sales/approve`, {
				salesId: salesIds,
				userId: user._id,
				approvedSales: salesIds.length > 0,
				approveInstallments: installmentIds.length > 0,
				installArrId: installmentIds,
			});
			message.success('Sales Confirmed Successfully');
			return response.data;
		} catch (err) {
			message.error(err.message);
			return rejectWithValue(err.response.data);
		}
	}
);

/***
 *
 * call to get information for sales, expenses and plots,
 *
 ***/

const getGraphInfo = createAsyncThunk(
	'sales-info/for-graph',
	async ({ startDate, endDate }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(`/graph-data`, {
				startDate,
				endDate,
			});

			return response.data;
		} catch (err) {
			message.error(err.message);
			return rejectWithValue(err.response.data);
		}
	}
);

const getSalesByMonth = createAsyncThunk(
	'sales-info/by-month',
	async ({ startDate, endDate }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(
				`/sales/bymonth?startDate=${startDate}&endDate=${endDate}`
			);

			return response.data;
		} catch (err) {
			message.error(err.message);
			return rejectWithValue(err.response.data);
		}
	}
);

export {
	createSale,
	getAllSales,
	updateSale,
	deleteSale,
	getSaleById,
	updateInstallment,
	deleteInstallment,
	confirmSales,
	getGraphInfo,
	getSalesByMonth,
};
