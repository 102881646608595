import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Select, Table } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  useCustomers,
  usePhases,
  usePlots,
  useSaleById,
  useSocieties,
} from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { createSale, updateSale } from '../../@store/sales/salesActions';
import dayjs from 'dayjs';
import InstallmentModal from '../Modals/InstallmentModal';
import moment from 'moment';
import ImagesUpload from '../macros/ImageUpload';
import { getStructuredRole } from '../../utils/functions';
import useAgents from '../../hooks/useAgents';
import InstallmentTable from '../Tables/installmentTable';

const { Option } = Select;
const { RangePicker } = DatePicker;

function SalesForm() {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [societyId, setSocietyId] = useState(null);
  const [phaseId, setPhaseId] = useState(null);
  const { societies, loading: societyLoading } = useSocieties();
  const { phases, loading: phaseLoading } = usePhases(societyId);
  const { plots, loading: plotLoading } = usePlots(phaseId, 'sell');
  const { agents, loading: agentsLoading, error: agentsError } = useAgents();
  // sell is a default status for plots that is like unsold
  const { customers, loading: customerLoading } = useCustomers();
  const [plotId, setPlotId] = useState(null);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const saleId = searchParams.get('saleId');
  const { sale, loading: saleLoading } = useSaleById(saleId);
  const { entities: auth } = useSelector((state) => state.auth);
  const { entities } = useSelector((state) => state.sale);
  const [mode, setMode] = useState('create');
  const [addInstallmentModal, setAddInstallmentModal] = useState(false);
  const [createdInstallments, setCreatedInstallments] = useState([]);
  const [selectedPlot, setSelectedPlot] = useState();
  const [files, setFiles] = useState();

  const columns = [
    {
      title: 'Installment Number',
      dataIndex: 'installmentNumber',
      key: 'installmentNumber',
      align: 'center',
    },
    {
      title: 'Amount',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
      align: 'center',
    },

    {
      title: 'Payment Date',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      align: 'center',
      render: (paymentDate, record) => moment(paymentDate).format('YYYY-MM-DD'),
    },

    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (_, record) => <Button type='primary'>Delete</Button>,
    },
  ];

  useEffect(() => {
    if (saleId) {
      setMode('edit');
    }
    if (sale) {
      const installmentPeriod = sale.paymentMethod.includes('installments')
        ? [
            dayjs(sale.installmentDetails.startDate),
            dayjs(sale.installmentDetails.endDate),
          ]
        : undefined;
      // Set the fetched sale data as the initial values for the form in edit mode
      form.setFieldsValue({
        society: {
          key: sale.society?._id,
          label: sale.society?.name,
        },
        phase: {
          key: sale.phase?._id,
          label: sale.phase.name,
        },
        plot: {
          key: sale.plot?._id,
          label: sale.plot?.number,
        },
        customer: {
          key: sale?.customer?._id,
          label: sale?.customer?.name,
        },
        // sale.paymentMehod is an array of strings
        paymentMethod: sale.paymentMethod,
        // paymentMethods: sale.paymentMethod
        numberOfInstallments: sale.paymentMethod.includes('installments')
          ? sale.installmentDetails.numberOfInstallments
          : undefined,
        installmentAmount: sale.paymentMethod.includes('installments')
          ? sale.installmentDetails.installmentAmount
          : undefined,
        installmentPeriod: installmentPeriod,
        salePrice: sale?.salePrice,
        remarks: sale?.remarks,
        files: sale?.files,
      });

      setSocietyId(sale.society._id);
      setPhaseId(sale.phase._id);
      setPlotId(sale.plot._id);
      setFiles(sale.files);
      setPaymentMethods(sale.paymentMethod);
      setCreatedInstallments(sale.installments);
    }
  }, [saleId, sale]);

  const handlePlotChange = (value) => {
    setPlotId(value);
    const selectedPlot = plots?.find((user) => user?._id === value);
    setSelectedPlot(selectedPlot);
  };

  const handleSocietyChange = (value) => {
    form.resetFields(['phase', 'plot']);
    setSocietyId(value);
  };

  const handlePhaseChange = (value) => {
    form.resetFields(['plot']);
    setPhaseId(value);
  };

  const handlePaymentMethodChange = (value) => {
    setPaymentMethods(value);
  };
  const handleCloseInstallmentModal = () => {
    setAddInstallmentModal(false);
  };

  const handleNavigateToSales = () => {
    const r = `/${getStructuredRole(auth?.role)}/sales/`;
    window.location.replace(r);
  };

  const onFinish = (data) => {
    try {
      setButtonLoading(true);

      if (paymentMethods.includes('installments')) {
        data = {
          ...data,
          installmentDetails: {
            numberOfInstallments: data?.numberOfInstallments,
            installmentAmount: data?.installmentAmount,
            startDate: data?.installmentPeriod[0],
            endDate: data?.installmentPeriod[1],
          },
          installments: createdInstallments,
        };
      }

      if (saleId) {
        // Update existing sale
        data = {
          ...data,
          files: files || [],
        };

        dispatch(updateSale({ saleId: saleId, sale: data }))
          .unwrap()
          .then((res) => {
            setButtonLoading(false);
            form.resetFields();
            handleNavigateToSales();
          })
          .catch((error) => {
            console.log('updateSale onFinish error:', error);
            setButtonLoading(false);
          });
      } else {
        // Create new sale
        data = {
          ...data,
          files: files || [],
        };

        dispatch(createSale({ ...data, plot: plotId }))
          .unwrap()
          .then((res) => {
            setButtonLoading(false);
            setSelectedPlot();
            setTimeout(() => {
              handleNavigateToSales();
            }, 2000);

            form.resetFields();
          })
          .catch((error) => {
            setButtonLoading(false);
          });
      }
    } catch (e) {
      console.log('onFinish error:', e);
      setButtonLoading(false);
    }
  };

  const handleInstallment = () => {
    setAddInstallmentModal(true);
  };

  return (
    <div className='w-[80%]'>
      {selectedPlot && (
        <div className='flex text-primary font-medium border-indigo-500 rounded-md p-3 max-w-[16rem] gap-10'>
          <p className=' flex '>
            <span>AREA: </span>
            <span className='flex gap-1'>
              <span>{selectedPlot?.area} </span>
              <span>
                {selectedPlot?.areaUnit?.toUpperCase() ||
                  (selectedPlot && ' MARLA')}
              </span>
            </span>
          </p>
          <p className='flex gap-1'>
            <span>PRICE: </span>
            <span>
              <span>{selectedPlot?.price} </span>
            </span>
          </p>
        </div>
      )}

      <Form
        name='my-form'
        onFinish={onFinish}
        layout='vertical'
        className='w-full'
        form={form}
        initialValues={mode === 'create' ? undefined : null}
        disabled={mode === 'show'} // Disable editing of the form fields in show mode
      >
        <Form.Item className='justify-end flex'>
          <Button
            type='primary'
            htmlType='submit'
            icon={mode === 'create' ? <PlusOutlined /> : <EditOutlined />}
            loading={buttonLoading}
          >
            {mode === 'create' ? 'Create Sale' : 'Save'}
          </Button>
        </Form.Item>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full'>
          <Form.Item
            name='society'
            label='Society'
            required={true}
            rules={[{ required: true, message: 'Please select a society' }]}
          >
            <Select
              showSearch
              placeholder='Select society'
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleSocietyChange}
            >
              {societies &&
                societies?.length > 0 &&
                societies.map((society) => (
                  <Option key={society._id} value={society._id}>
                    {society.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name='phase'
            label='Phase'
            required={true}
            rules={[{ required: true, message: 'Please select a phase' }]}
          >
            <Select
              showSearch
              placeholder='Select phase'
              optionFilterProp='children'
              filterOption={(input, option) => {
                return (
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                );
              }}
              loading={phaseLoading}
              onChange={handlePhaseChange}
              value={phaseId} // Set the selected phase based on state
              disabled={!societyId} // Disable phase select if society is not selected
            >
              {phases &&
                phases.map((phase) => (
                  <Option key={phase._id} value={phase._id}>
                    {phase.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name='plot'
            label='Plots'
            required={true}
            rules={[{ required: true, message: 'Please select a plot' }]}
          >
            <Select
              showSearch
              placeholder='Select Plot'
              loading={plotLoading}
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={!phaseId}
              onChange={handlePlotChange}
              value={plotId}
            >
              {plots &&
                plots?.map((plot) => (
                  <Option key={plot._id} value={plot._id}>
                    {plot.number}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name='customer'
            label='Customer'
            required={true}
            rules={[{ required: true, message: 'Please select a customer' }]}
          >
            <Select
              showSearch={true}
              loading={customerLoading}
              placeholder='Select customer'
              optionFilterProp='children'
              filterOption={(input, option) => {
                const customerName = option.children.props.children;
                const customerCNIC = option.value;
                const searchData = customerName + ' ' + customerCNIC;
                return (
                  searchData.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }}
            >
              {customers &&
                customers.map((customer) => (
                  <Option key={customer._id} value={customer._id}>
                    <p>{customer.name + ' (' + customer.cnic + ')'}</p>
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name='paymentMethod'
            label='Payment Method'
            required={true}
            rules={[
              { required: true, message: 'Please select a payment method' },
            ]}
          >
            <Select
              onChange={handlePaymentMethodChange}
              placeholder='Select payment method'
              mode='multiple'
            >
              <Option value='net_payment'>Cheque/Cash</Option>
              <Option value='installments'>Installment</Option>
              <Option value='barter'>Barter System</Option>
            </Select>
          </Form.Item>

          {/* Rest of the form items */}
          <Form.Item
            label='Sale Price'
            name='salePrice'
            rules={[
              {
                required: true,
                message: 'Sale price is required',
              },
            ]}
          >
            <Input type='number' />
          </Form.Item>
          <Form.Item
            name='installmentAmount'
            label='Installment Amount'
            required={true}
          >
            <Input disabled={!paymentMethods.includes('installments')} />
          </Form.Item>

          <Form.Item
            name='installmentPeriod'
            label='Installment Period'
            required={true}
          >
            <RangePicker
              className='w-full'
              disabled={!paymentMethods.includes('installments')}
            />
          </Form.Item>

          <Form.Item
            name='numberOfInstallments'
            label='Number of Installments'
            required={true}
          >
            <Input disabled={!paymentMethods.includes('installments')} />
          </Form.Item>
          <Form.Item
            name='agent'
            label='Agent'
            rules={[
              {
                required: true,
                message: 'Sale price is required',
              },
            ]}
          >
            <Select
              showSearch
              placeholder='Select agent'
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {agents &&
                agents?.length > 0 &&
                agents.map((agent) => (
                  <Option key={agent._id} value={agent._id}>
                    {agent.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name='commission'
            className='w-full'
            label='Commission'
            rules={[
              {
                required: true,
                message: 'Sale price is required',
              },
            ]}
          >
            <Input type='number' />
          </Form.Item>
          <Form.Item name='files' label='Attach files'>
            <ImagesUpload
              fileList={files}
              className='w-full'
              setFileList={setFiles}
              acceptedTypes={'.png,.jpg,.jpeg,.pdf,.doc,.docx'}
              maxSize={5}
              maxFiles={5}
              form={form}
              disablePreview={true}
              label='Attachments'
            />
          </Form.Item>
          <Form.Item name='remarks' label='Remarks'>
            <TextArea rows={2} />
          </Form.Item>
        </div>
      </Form>

      <InstallmentTable
        handleInstallment={handleInstallment}
        paymentMethods={paymentMethods}
        createdInstallments={createdInstallments}
        saleId={saleId}
      />

      <InstallmentModal
        saleId={saleId}
        openModal={addInstallmentModal}
        setCreatedInstallments={setCreatedInstallments}
        createdInstallments={createdInstallments}
        handleClose={handleCloseInstallmentModal}
      />
    </div>
  );
}

export default SalesForm;
