import { Layout } from 'antd'
import { NavBar, SideBar } from '../Layout'
import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { ADMIN } from '../../utils/mock/sidebar'
import { useSelector } from 'react-redux'

const { Content } = Layout

function Admin() {
  const { entities } = useSelector((state) => state.auth)
  if (
    entities.token &&
    entities.role === 'admin' &&
    localStorage.getItem('token')
  ) {
    return (
      <div className="max-h-[100vh]">
        <NavBar />
        <Layout>
          <SideBar routes={ADMIN} />
          <Content className="overflow-auto bg-[white]  max-h-[90vh]  w-full">
            <Outlet />
          </Content>
        </Layout>
      </div>
    )
  } else {
    return <Navigate to="/" />
  }
}

export { Admin }
