import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Popconfirm, Switch, Table } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  deleteAgent,
  getAgents,
  updateAgent,
} from "@store/users/userActions";
import { BorderHeading } from "@components/macros";
import AgentModal from "@components/Modals/AgentModal";
import ExportableTableHOC from "@hoc/ExportTable";

function AgentManagement() {
  const [openModal, setOpenModal] = useState(false);
  const { agents, agentLoading } = useSelector((state) => state.user);
  const [isEdit, setIsEdit] = useState(false);
  const [current, setCurrent] = useState("");
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getAgents());
  }, [dispatch]);

  const handleStatusChange = (agent, checked) => {
    dispatch(updateAgent({ id: agent._id, info: { blocked: checked } }));
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "CNIC",
      dataIndex: "cnic",
      key: "cnic",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "blocked",
      render: (text, record) => (
        <Switch
          key={`switch-${record._id}`}
          loading={agentLoading}
          checked={record.blocked}
          onChange={(blocked) => handleStatusChange(record, blocked)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-5">
          <EditOutlined
            className="text-blue-600 text-base"
            onClick={() => onEditClick(record)}
          />
          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to delete this item?"
            onConfirm={() => {
              handleDeleteAgent(record._id);
            }}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined className="text-red-600 text-base" />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const handleDeleteAgent = (id) => {
    dispatch(deleteAgent(id));
  };

  const onEditClick = useCallback(
    (record) => {
      console.log("record", record);
      if (record) {
        setOpenModal(true);
        setCurrent(record?._id);
        setIsEdit(true);
        form.setFieldsValue({
          ...record,
        });
      }
    },
    [form]
  );

  return (
    <div className="container-padding h-full bg-white">
      <div className="w--[50%] md:w-full flex-column gap-6">
        <div className="flex flex-col gap-5 md:flex-row justify-between ml-4 mr-4 mt-4">
          <BorderHeading heading="Agents" />

          <Button
            onClick={() => {
              setOpenModal(true);
            }}
            className="w-[140px]"
            type="primary"
            icon={<PlusOutlined />}
          >
            Add Agent
          </Button>
        </div>
        <Table
          id={"table-to-export"}
          dataSource={agents?.length > 0 && agents}
          columns={columns}
        />
      </div>
      <AgentModal
        form={form}
        isEdit={isEdit}
        openModal={openModal}
        current={current}
        handleCancel={() => {
          setOpenModal(false);
          setCurrent("");
          form.resetFields();
        }}
      />
    </div>
  );
}

export default ExportableTableHOC({
  TableComponent: AgentManagement,
  FileName: "agents",
});
