import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Avatar, Card, Modal } from 'antd';
import { useState } from 'react';

import Meta from 'antd/es/card/Meta';
import { deleteSociety } from '../../@store/society/societyActions';
import { useDispatch, useSelector } from 'react-redux';
import { capitalizeSentence, getStructuredRole } from '../../utils/functions';
import { useNavigate } from 'react-router-dom';

const SocietyCard = ({ society, updater, loading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const { entities: auth } = useSelector((state) => state.auth);
  console.log('role', auth?.role);
  const role = getStructuredRole(auth?.role);

  const handleEdit = (e, id) => {
    e.stopPropagation();
    console.log('role', role);
    navigate(`/${role}/society/edit/${id}`);
  };
  const handleDelete = (e) => {
    e.stopPropagation();
    setIsConfirmModalVisible(true);
  };

  const handleCardClick = (e) => {
    e.stopPropagation();
    console.log('role', role);
    navigate(`/${role}/societies/profile/${society._id}`);
  };

  const handleConfirmModalOk = () => {
    dispatch(deleteSociety(society._id));
    setIsConfirmModalVisible(false);
  };

  const handleConfirmModalCancel = () => {
    setIsConfirmModalVisible(false);
  };

  return (
    <>
      <Card
        className='w-full cursor-pointer overflow-auto 1320:min-w-[300px] shadow-md'
        actions={
          auth.role === 'superAdmin' || auth.role === 'admin'
            ? [
                <EditOutlined
                  key='edit'
                  onClick={(e) => handleEdit(e, society._id)}
                />,
                <DeleteOutlined key={society._id} onClick={handleDelete} />,
              ]
            : null
        }
      >
        <Meta
          avatar={<Avatar src={society.logo} />}
          title={
            <p onClick={handleCardClick} className='cursor-pointer'>
              {capitalizeSentence(society.name)},{' '}
              {capitalizeSentence(society.location)}
            </p>
          }
          className='flex items-center'
          style={{ marginBottom: 10 }}
        />
        <div className='ml-12'>
          <p>Phases: {society.phases.length}</p>
          <p>Credit: {society.credit}</p>
          <p>Debit: {society.debit}</p>
        </div>
      </Card>
      <Modal
        title='Confirm Delete'
        open={isConfirmModalVisible}
        onOk={handleConfirmModalOk}
        onCancel={handleConfirmModalCancel}
        okText='Confirm'
        cancelText='Cancel'
      >
        <p>Are you sure you want to delete this society?</p>
      </Modal>
    </>
  );
};

export default SocietyCard;
