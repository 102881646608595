import React, { useState } from 'react';
import PlotTable from '@components/Tables/plotTable';
import { usePhases, usePlots, useSocieties } from '@hooks';
import { Select } from 'antd';
import { BorderHeading } from '@components/macros';

const { Option } = Select;

function PlotManagement() {
  const [phaseId, setPhaseId] = useState(null);
  const [societyId, setSocietyId] = useState(null);
  const { societies, loading: societyLoading } = useSocieties();
  const { phases, loading: phaseLoading } = usePhases(societyId);
  const { plots, loading: plotLoading } = usePlots(phaseId, '');

  const handlePlotChange = (value) => {
    // setPlotId(value);
    const selectedPlot = plots?.find((user) => user?._id === value);
    // setSelectedPlot(selectedPlot);
  };

  const handleSocietyChange = (value) => {
    setSocietyId(value);
    setPhaseId('');
  };

  const handlePhaseChange = (value) => {
    setPhaseId(value);
  };

  return (
    <div className='container-padding'>
      <div className='flex gap-2'>
        <BorderHeading heading='Plots' />
        <div className='w-full grid gap-2 grid-cols-2'>
          <span>
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder='Select society'
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleSocietyChange}
            >
              {societies &&
                societies?.length > 0 &&
                societies.map((society) => (
                  <Option key={society._id} value={society._id}>
                    {society.name}
                  </Option>
                ))}
            </Select>
          </span>
          <span>
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder='Select phase'
              optionFilterProp='children'
              filterOption={(input, option) => {
                return (
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                );
              }}
              loading={phaseLoading}
              onChange={handlePhaseChange}
              // disabled={!societyId} // Disable phase select if society is not selected
            >
              {phases &&
                phases.map((phase) => (
                  <Option key={phase._id} value={phase._id}>
                    {phase.name}
                  </Option>
                ))}
            </Select>
          </span>
        </div>
      </div>
      <PlotTable externalPlots={plots} disableCrud={true} />;
    </div>
  );
}

export default PlotManagement;
