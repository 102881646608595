import React, { useState, useEffect } from 'react';
import { Button, Input, Form, Radio, Spin, Select } from 'antd';
import { useSelector } from 'react-redux';
import { getFilteredRoles } from '../../utils/functions';
import { Modal } from '../macros';
import { useSocieties } from '../../hooks';

function UserModal({
	openModal,
	handleClose,
	form,
	onFinishFailed,
	onFinish,
	isEdit,
}) {
	const { entities } = useSelector((state) => state.auth);
	const { loading } = useSelector((state) => state.user);
	const [filteredRoles, setFilteredRoles] = useState(null);
	const [showSocieties, setShowSocieties] = useState(false);
	const [disabledSociety, setDisabledSociety] = useState(false);
	const { societies: allSocieties } = useSocieties('', false, true);

	useEffect(() => {
		setFilteredRoles(getFilteredRoles(entities.role));
		if (isEdit) {
			const role = form.getFieldValue('role');
			if (role === 'societyHeadStaff' || role === 'societyHead') {
				setShowSocieties(true);
			} else if (role === 'admin') {
				setDisabledSociety(true);
			}
		}
	}, [isEdit]);

	const handleRoleChange = (e) => {
		let role = e.target.value;
		if (role === 'societyHeadStaff' || role === 'societyHead') {
			setShowSocieties(true);
		} else {
			setShowSocieties(false);
		}
	};

	return (
		<Modal open={openModal} handleClose={handleClose} className='w-[500px]'>
			<Spin spinning={loading}>
				<div className='w-full bg-white'>
					<Form
						layout='vertical'
						name='basic'
						form={form}
						initialValues={{ remember: false }}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						className='w-full'
					>
						<Form.Item
							label='Name'
							name='name'
							rules={[{ required: true, message: 'Please input your name!' }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							label='Email'
							name='email'
							rules={[
								{
									type: 'email',
									required: true,
									message: 'Please input valid email!',
								},
							]}
						>
							<Input />
						</Form.Item>

						<Form.Item
							label='CNIC'
							name='cnic'
							rules={[
								{ required: true, message: 'Please input CNIC!' },
								{
									pattern: /^\d{5}-\d{7}-\d{1}$/,
									message: 'Invalid CNIC format!',
								},
								{ len: 15, message: 'CNIC must be 15 characters!' },
							]}
						>
							<Input placeholder='23322-9511870-1' />
						</Form.Item>
						<div className='grid grid-cols-2 gap-5'>
							<Form.Item
								label='Phone'
								name='phone'
								rules={[
									{ required: true, message: 'Please enter your phone number' },
									{
										pattern: /^[0-9]{10}$/,
										message: 'Please enter a valid phone number (+92)',
									},
								]}
							>
								<Input addonBefore='+92' placeholder='3311345678' />
							</Form.Item>
							<Form.Item
								label={isEdit ? 'Update Password' : 'Password'}
								name='password'
								rules={[
									{
										required: !isEdit,
										message: 'Please input password!',
									},
								]}
							>
								<Input type='password' />
							</Form.Item>
						</div>
						<Form.Item
							name='role'
							label='Select role'
							rules={[{ required: true, message: 'Please select a role' }]}
						>
							<Radio.Group buttonStyle='solid' onChange={handleRoleChange}>
								{filteredRoles?.map(({ name, key }) => (
									<Radio.Button key={key} value={key}>
										{name}
									</Radio.Button>
								))}
							</Radio.Group>
						</Form.Item>

						<Form.Item
							label='Select society you want to add the staff'
							name='societies'
							style={{ width: '100%' }}
							rules={[
								{
									required: !disabledSociety && showSocieties,
									message: 'Please select a society!',
								},
							]}
						>
							<Select
								getPopupContainer={(triggerNode) => {
									return triggerNode.parentNode;
								}}
								disabled={!showSocieties}
								mode='single'
								showSearch
								filterOption={(input, option) =>
									(option?.label ?? '')
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								options={allSocieties}
							/>
						</Form.Item>

						<Form.Item>
							<div className='flex gap-2 justify-end'>
								<Button onClick={handleClose} type='default'>
									Cancel
								</Button>
								<Button loading={loading} type='primary' htmlType='submit'>
									Submit
								</Button>
							</div>
						</Form.Item>
					</Form>
				</div>
			</Spin>
		</Modal>
	);
}

export default UserModal;
