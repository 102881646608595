import { useEffect, useState } from 'react';
import { getSaleById } from '../@store/sales/salesActions';
import { useDispatch } from 'react-redux';

export function useSaleById(saleId) {
  const [sale, setSale] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (saleId) {
      setLoading(true);
      dispatch(getSaleById(saleId))
        .unwrap()
        .then((res) => {
          setSale(res.sale.sale);
          setLoading(false);
        });
    } else {
      setSale(null);
    }
  }, [saleId]);

  return { sale, loading };
}
