import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Select, Spin } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  AiFillContainer,
  AiFillDollarCircle,
  AiFillProject,
  AiFillShop,
} from 'react-icons/ai';
import {
  createPhase,
  fetchPhasesBySocietyId,
  updatePhase,
} from '@store/phase/phaseActions';
import { getSocietyById } from '@store/society/societyActions';
import PlotTable from '@components/Tables/plotTable';
import AddPhaseModal from '@components/macros/AddPhaseModal';
import { BorderHeading, SmallCard } from '@components/macros';

export const PhaseManagement = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [current, setCurrent] = useState();
  const { phases } = useSelector((state) => state.phase);
  const [society, setSociety] = useState();
  const [selectedPhase, setSelectedPhase] = useState();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(fetchPhasesBySocietyId(id))
        .unwrap()
        .then((res) => {
          setSelectedPhase(res[0]);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(getSocietyById(id))
        .unwrap()
        .then((data) => {
          setSociety(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  const onAddPhase = () => {
    setOpenModal(true);
  };

  const onFinish = (values) => {
    if (isEdit) {
      dispatch(updatePhase({ ...values, _id: current }))
        .unwrap()
        .then((res) => {
          handleClose();
          setSelectedPhase(res);
        });
    } else {
      dispatch(createPhase({ ...values, society: id }))
        .unwrap()
        .then(() => {
          handleClose();
          dispatch(fetchPhasesBySocietyId(id));
        });
    }
  };

  const handleFinish = async (values) => {
    try {
      setIsLoading((prevIsLoading) => true);
      onFinish(values);
    } catch (error) {
    } finally {
      setIsLoading((prevIsLoading) => false);
    }
  };

  const onEditClick = (record) => {
    if (selectedPhase) {
      setOpenModal(true);
      setIsEdit(true);
      setCurrent(selectedPhase._id);
      form.setFieldsValue({
        _id: selectedPhase._id,
        name: selectedPhase.name,
        financer: selectedPhase.financer,
        totalArea: selectedPhase.totalArea,
        legalAuthority: selectedPhase.legalAuthority,
        association: selectedPhase.association,
        cost: selectedPhase.cost,
      });
    }
  };

  const handleClose = useCallback(() => {
    setOpenModal(false);
    setIsEdit(false);
    form.resetFields();
  }, [form]);

  const onDelete = () => {
    dispatch(fetchPhasesBySocietyId(id));
  };

  return (
    <div className='flex-column gap-10 p-10'>
      {loading ? (
        <Spin size='large' />
      ) : (
        <div className='flex-column gap-10 p-10'>
          <div className='flex justify-between'>
            <BorderHeading heading={`${society?.name} (Phases)`} />
            <div className='flex items-center gap-4'>
              <Select
                style={{
                  width: '200px',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                }}
                value={selectedPhase?.name}
                defaultValue={phases[0]}
                onChange={(value) => {
                  setSelectedPhase(phases.find((phase) => phase._id === value));
                }}
                options={phases.map((phase) => ({
                  label: phase.name,
                  value: phase._id,
                }))}
              />
              <div className='lg:flex-nowrap gap-4 md:flex-nowrap flex flex-wrap'>
                <Button
                  className='shadow-sm'
                  icon={<EditOutlined />}
                  onClick={onEditClick}
                >
                  Edit
                </Button>
                <Button
                  className='shadow-sm'
                  type='primary'
                  onClick={onAddPhase}
                  icon={<PlusOutlined />}
                >
                  Add Phase
                </Button>
              </div>
            </div>
          </div>

          <div className='grid gap-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-4'>
            <SmallCard
              subheading={'Total Area'}
              title={selectedPhase?.totalArea}
              variant='white'
              Icon={<AiFillShop fill='#1890ff' size='30px' />}
            />
            <SmallCard
              subheading={'Cost'}
              title={selectedPhase?.cost}
              variant='white'
              Icon={<AiFillDollarCircle fill='#1890ff' size='30px' />}
            />
            <SmallCard
              subheading={'Association'}
              title={selectedPhase?.association}
              variant='white'
              Icon={<AiFillContainer fill='#1890ff' size='30px' />}
            />
            <SmallCard
              subheading={'Financer'}
              title={selectedPhase?.financer}
              variant='white'
              Icon={<AiFillProject fill='#1890ff' size='30px' />}
            />
            {/*<SmallCard*/}
            {/*  subheading={"Legal Authority"}*/}
            {/*  heading={selectedPhase?.legalAuthority}*/}
            {/*  variant="white"*/}
            {/*  Icon={""}*/}
            {/*/>*/}
          </div>

          <div>{<PlotTable phaseId={selectedPhase?._id} />}</div>

          <AddPhaseModal
            openModal={openModal}
            handleClose={handleClose}
            onFinish={handleFinish}
            form={form}
            isLoading={isLoading}
            isEdit={isEdit}
          />
        </div>
      )}
    </div>
  );
};
