import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBanks } from '../@store/banks/bankActions';

const useBanks = (bankType) => {
  const dispatch = useDispatch();
  const { banks, loading, error } = useSelector((state) => state.bank);
  const [current, setCurrent] = useState([]);

  useEffect(() => {
    dispatch(fetchBanks());
  }, [dispatch]);

  useEffect(() => {
    if (bankType && !loading && Array.isArray(banks) && banks.length > 0) {
      const filteredBanks = banks.filter((bank) => bank.bankType === bankType);
      setCurrent(filteredBanks);
    } else {
      setCurrent(banks);
    }
  }, [banks, bankType, loading]);

  const formedBanks =
    Array.isArray(current) &&
    current.length > 0 &&
    current.map((item) => ({
      value: item._id,
      label: item.bankName,
    }));

  const getBanksByIds = (ids) => {
    if (
      Array.isArray(banks) &&
      banks.length > 0 &&
      Array.isArray(ids) &&
      ids.length > 0
    ) {
      const formedChildBanks = banks.filter((bank) =>
        bank.parentBanks.some((parentId) => ids.includes(parentId))
      );
      const childBanks = formedChildBanks.map((item) => ({
        value: item._id,
        label: item.bankName,
      }));
      return childBanks;
    }
    return [];
  };

  return { banks: current, formedBanks, loading, error, getBanksByIds };
};

export default useBanks;
