import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

export const { RangePicker } = DatePicker;
export const filterTypeMap = ['Daily', 'Weekly', 'Monthly', 'Yearly', 'All'];
export const filterTypeShort = ['Daily', 'Weekly', 'Monthly'];

export const daysPresets = {
	label: 'Last 90 Days',
	value: [dayjs().add(-90, 'd'), dayjs()],
};

export const rangePresets = [
	{
		label: 'Last 7 Days',
		value: [dayjs().add(-7, 'd'), dayjs()],
	},
	{
		label: 'Last 14 Days',
		value: [dayjs().add(-14, 'd'), dayjs()],
	},
	{
		label: 'Last 30 Days',
		value: [dayjs().add(-30, 'd'), dayjs()],
	},
];
