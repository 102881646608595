import React, { useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Button, Input } from 'antd'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { searchSocieties } from '../@store/society/societyActions'

const { Search } = Input

function SearchBar({ placeholder,handleSearch }) {
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleSearchClick = () => {
    handleSearch(inputValue)
  }


  return (
    <div className="w-[550px]">
      <Search
        placeholder={placeholder}
        enterButton={
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={handleSearchClick}
          >
            Search
          </Button>
        }
        value={inputValue}
        onChange={handleInputChange}

      />
    </div>
  )
}

SearchBar.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onSearch: PropTypes.func,
}

export default SearchBar
