import React from 'react';
import { useNavigate } from 'react-router-dom';
import Error404 from '../images/error-404.png';

function NotFound({ flag }) {
	const navigate = useNavigate();

	const goBack = () => {
		navigate(-1);
	};

	return (
		<div className='flex flex-col items-center justify-center h-screen bg-gray-100'>
			<img
				src={Error404}
				alt='404 Illustration'
				className='mt-8'
				style={{ maxWidth: '300px' }} // Adjust the size as needed
			/>
			<div className='text-center'>
				<h1 className='text-4xl font-bold text-primary mb-4'>
					{flag ? '404 Work in Progress' : '404 Page Not Found'}
				</h1>
				<p className='text-lg text-gray-600'>
					{flag
						? "We're sorry, this page is still under construction."
						: "The page you are looking for doesn't exist."}
				</p>
			</div>

			<a
				href='/'
				onClick={(e) => {
					e.preventDefault(); // Prevent the default link behavior
					goBack(); // Call the function to go back
				}}
				className='mt-8 text-primary hover:underline text-lg'
			>
				Go back to the previous page
			</a>
		</div>
	);
}

export default NotFound;
