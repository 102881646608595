import {
  deleteUser,
  getAllUsers,
  blockUser,
} from "../../@store/users/userActions";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Table, Tag, Popconfirm, Switch } from "antd";
import { useSelector, useDispatch } from "react-redux";
import getColumnSearchProps from "./searchFunction";
import React, { useState, useRef, useEffect } from "react";
import { getPermission } from "../../utils/functions";
import { capitalizeSentence, convertToTitleCase } from "../../utils/functions";
import "../../antd.css";

const roleColors = {
  superAdmin: "magenta",
  admin: "green",
  societyHead: "cyan",
  societyHeadStaff: "purple",
};

function UserTable({ onEditClick, filterType }) {
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState(null);
  const { entities: auth } = useSelector((state) => state.auth);
  const { entities, loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const searchInput = useRef(null);

  useEffect(() => {
    setData(() => {
      if (filterType !== "all" && filterType) {
        return entities.filter((item) => item.role === filterType);
      } else {
        // Exclude Super Admin roles if myRole is admin
        if (auth.role === "admin") {
          return entities.filter((item) => item.role !== "superAdmin");
        } else {
          return entities;
        }
      }
    });
  }, [filterType, entities]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters && clearFilters();
    setSearchText("");
    setSearchedColumn("");
  };

  const handleDeleteUser = ({ _id }) => {
    dispatch(deleteUser(_id));
  };

  const handleBlockUser = (record) => {
    dispatch(
      blockUser({
        userId: record._id,
        status: !record.blocked,
      })
    );
  };

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (value) => capitalizeSentence(value),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
    },
    {
      title: "CNIC",
      dataIndex: "cnic",
      key: "cnic",
      align: "center",
      ...getColumnSearchProps(
        "cnic",
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        handleSearch,
        handleReset
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      align: "center",
      render: (role) => (
        <Tag color={roleColors[role]}>
          {role.substring(0, 1).toUpperCase() +
            role
              .substring(1)
              .replace(/([A-Z])/g, " $1")
              .trim()}
        </Tag>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      render: (phone) => (
        <Tag title="true" color="lime">
          {phone}
        </Tag>
      ),
      ...getColumnSearchProps(
        "phone",
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        handleSearch,
        handleReset
      ),
    },
    {
      title: "Blocked",
      dataIndex: "block",
      key: "Block",
      align: "center",
      render: (blocked, record) => {
        const disabled = !getPermission(auth.role, record.role);
        return (
          <Switch
            checked={record.blocked}
            onChange={() => handleBlockUser(record)}
            disabled={disabled}
          />
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        const isDisabled = !getPermission(auth.role, record.role);
        return (
          <div className="flex justify-between m-2">
            <EditOutlined
              style={{ color: isDisabled ? '#ccc' : '#1890ff', cursor: isDisabled ? 'not-allowed' : 'pointer' }}
              onClick={() => {
                if(isDisabled) return
                 onEditClick(record)
              }}
            />
            {
              isDisabled ? null :  <Popconfirm
              placement="topLeft"
              title="Are you sure you want to delete this item?"
              onConfirm={() => {
                if(isDisabled) return
                handleDeleteUser(record)
              }}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined
                style={{
                  color: isDisabled ? "#ccc" : "#ff4d4f",
                  cursor: isDisabled ? "not-allowed" : "pointer",
                }}
              />
            </Popconfirm>
            }

          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="flex-column gap-4">
        <Table
          size="small"
          id={'table-to-export'}
          bordered
          loading={loading}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "even-row" : "odd-row"
          }
          dataSource={data ? data : entities}
          columns={columns}
          pagination={{
            defaultPageSize: 20,
            pageSizeOptions: ["10", "20", "50", "100"],
            showSizeChanger: true,
          }}
        />
      </div>
    </>
  );
}

export default UserTable;
