import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSocieties,
  searchSocieties,
} from '../@store/society/societyActions';

const useSocieties = (
  searchValue,
  calculateActiveCount = false,
  formatted = false
) => {
  const dispatch = useDispatch();
  const { societies, loading, error } = useSelector((state) => state.society);

  useEffect(() => {
    if (searchValue) {
      dispatch(searchSocieties(searchValue));
    } else {
      dispatch(fetchSocieties());
    }
  }, [dispatch, searchValue]);

  let activeSocietyCount = 0;
  if (calculateActiveCount) {
    activeSocietyCount = societies?.filter(
      (society) => society.status === 'active'
    ).length;
  }

  let formattedSocieties = formatted
    ? societies.length > 0 &&
      societies?.map((society) => ({
        label: society.name,
        value: society._id,
      }))
    : societies;

  return {
    societies: formatted ? formattedSocieties : societies,
    loading,
    error,
    activeSocietyCount,
  };
};

export { useSocieties };
