import React from 'react';
import ExpenseManagement from './ExpenseManagement';

export default function ConfirmExpenses() {
  return (
    <div className='container-padding'>
      <ExpenseManagement confirmExpensesMode={true} />
    </div>
  );
}
