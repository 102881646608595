import { Tabs } from 'antd';
import React from 'react';

const { TabPane } = Tabs;

const Tutorial = () => {
	return (
		<div
			className='flex font-bold text-center items-center
               justify-center  text-primary text-3xl'
		>
			Tutorials
		</div>
	);
};

export default Tutorial;
