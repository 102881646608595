import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOffices } from "../@store/office/officeActions";

const useOffice = () => {
  const dispatch = useDispatch();
  const { offices, loading, error } = useSelector((state) => state.office);

  useEffect(() => {
    dispatch(getAllOffices());
  }, [dispatch]);

  return { offices, loading, error };
};

export default useOffice;
