import { createSlice } from "@reduxjs/toolkit";
import {
  createOffice,
  getAllOffices,
  getOfficeById,
  updateOffice,
  deleteOffice,
} from "./officeActions";

const officeSlice = createSlice({
  name: "office",
  initialState: {
    offices: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createOffice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createOffice.fulfilled, (state, action) => {
        state.offices.push(action.payload);
        state.loading = false;
      })
      .addCase(createOffice.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllOffices.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllOffices.fulfilled, (state, action) => {
        state.loading = false;
        state.offices = action.payload;
      })
      .addCase(getAllOffices.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateOffice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateOffice.fulfilled, (state, action) => {
        state.loading = false;
        const { office } = action.payload;
        const index = state.offices.findIndex((e) => e._id === office);
        if (index >= 0) {
          state.offices[index] = office;
        }
      })
      .addCase(updateOffice.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteOffice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteOffice.fulfilled, (state, action) => {
        state.loading = false;
        state.offices = state.offices.filter(
          (office) => office._id !== action.payload
        );
      })
      .addCase(deleteOffice.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getOfficeById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getOfficeById.fulfilled, (state, action) => {
        state.loading = false;
        state.offices.map((office) => {
          if (office._id === action.payload._id) {
            office = action.payload;
          }
        });
      })
      .addCase(getOfficeById.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default officeSlice.reducer;
