import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../@api/axios";
import { message } from "antd";

const createCustomer = createAsyncThunk(
  "customer/add",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/customers", userData);
      message.success("Customer created Successfully");
      return response.data;
    } catch (err) {
      message.error(err.message);
      return rejectWithValue(err.response.data);
    }
  }
);

const updateCustomer = createAsyncThunk(
  "customer/update",
  async (updatedData, { rejectWithValue }) => {
    try {
      console.log(updatedData);
      const response = await axiosInstance.put(
        `/customers/${updatedData._id}`,
        updatedData
      );
      message.success("Customer updated Successfully");
      return response.data;
    } catch (err) {
      message.error(err.message);
      return rejectWithValue(err.response.data);
    }
  }
);

const deleteCustomer = createAsyncThunk(
  "customer/delete",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/customers/${userId}`);
      message.success("Customer deleted Successfully");
      return response.data.userId;
    } catch (err) {
      message.error(err.message);
      return rejectWithValue(err.response.data);
    }
  }
);

const getAllCustomers = createAsyncThunk(
  "customer/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/customers");
      console.log(response.data.customers);
      return response.data.customers;
    } catch (err) {
      message.error("something went wrong");
      return rejectWithValue(err.response.data);
    }
  }
);

export { deleteCustomer, updateCustomer, createCustomer, getAllCustomers };
