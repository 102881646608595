import React, { useCallback, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Button, Form } from 'antd';

import {
	createCustomer,
	getAllCustomers,
	updateCustomer,
} from '@store/customer/customerActions';
import ExportableTableHOC from '@hoc/ExportTable';
import { useSocieties } from '@hooks/useSocieties';
import { BorderHeading } from '@components/macros';
import { CustomerModal } from '@components/index';
import CustomerTable from '@components/Tables/customerTable';

const filterTypeMap = {
	'Super Admin': 'superAdmin',
	'Society Head': 'societyHead',
	'Society Staff': 'societyHeadStaff',
	Admin: 'admin',
	All: 'all',
};

function CustomerManagement() {
	const [current, setCurrent] = useState(null);
	const [filterType, setFilterType] = useState('all');
	const [isEdit, setIsEdit] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const { societies: allSocieties } = useSocieties('', false, true);
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const onFinishFailed = (errorInfo) => {};

	const handleClose = useCallback(() => {
		setOpenModal(false);
		setIsEdit(false);
		form.resetFields();
	}, [form]);

	function handleFilterTypeChange(value) {
		setFilterType(filterTypeMap[value]);
	}

	const onFinish = (values) => {
		if (isEdit) {
			dispatch(
				updateCustomer({
					...values,
					_id: current,
					contact: {
						...values.contact,
						phone: '+92' + values.contact.phone,
					},
				})
			)
				.unwrap()
				.then(() => {
					dispatch(getAllCustomers());
					handleClose();
				});
		} else {
			dispatch(
				createCustomer({
					...values,
					contact: {
						...values.contact,
						phone: '+92' + values.contact.phone,
					},
				})
			)
				.unwrap()
				.then(() => {
					dispatch(getAllCustomers());
					handleClose();
				});
		}
	};

	const handleFinish = async (values) => {
		try {
			setIsLoading((prevIsLoading) => true);
			onFinish(values);
		} catch (error) {
		} finally {
			setIsLoading((prevIsLoading) => false);
		}
	};

	const onEditClick = useCallback(
		(record) => {
			if (record) {
				const defaultSocieties =
					(record?.societies?.length > 0 &&
						allSocieties?.filter((s) => record?.societies.includes(s.value))) ||
					[];
				setOpenModal(true);
				setIsEdit(true);
				setCurrent(record._id);
				form.setFieldsValue({
					_id: record._id,
					name: record.name,
					password: '',
					cnic: record.cnic,
					role: record.role,
					societies: defaultSocieties || [],
					contact: {
						phone: record.contact.phone.substring(3),
						email: record.contact.email,
					},
					address: {
						city: record.address.city,
						street: record.address.street,
						zip: record.address.zip,
						state: record.address.state,
					},
				});
			}
		},
		[form]
	);

	return (
		<>
			<div className='container-padding h-full bg-white'>
				<div className='w--[50%] md:w-full flex-column gap-6'>
					<div className='flex flex-col gap-5 md:flex-row justify-between ml-4 mr-4 mt-4'>
						<BorderHeading heading='Customers' />

						<Button
							onClick={() => {
								setOpenModal(true);
							}}
							className='w-[140px]'
							type='primary'
							icon={<PlusOutlined />}
						>
							Add Customer
						</Button>
					</div>
					<CustomerTable onEditClick={onEditClick} filterType={filterType} />
					<CustomerModal
						openModal={openModal}
						handleClose={handleClose}
						onFinish={handleFinish}
						onFinishFailed={onFinishFailed}
						formValues={form}
						isLoading={isLoading}
						isEdit={isEdit}
					/>
				</div>
			</div>
		</>
	);
}

export default ExportableTableHOC({
	TableComponent: CustomerManagement,
	FileName: 'customers',
});
