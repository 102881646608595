import { Form, InputNumber, Button, Spin, Select } from 'antd';
import { useParams } from 'react-router-dom';

const Expenses = ({
  onNext,
  handlePrevious,
  expenseRef,
  current,
  setFormState,
}) => {
  const onFinish = (values) => {
    onNext('4');
    setFormState((prev) => ({ ...prev, expenses: { ...values } }));
  };

  const { id } = useParams();

  const defaultValues = {
    safetyAndSecurity: current?.expenses?.safetyAndSecurity || '',
    publicSpacing: current?.expenses?.publicSpacing || '',
    housing: current?.expenses?.housing || '',
    commercial: current?.expenses?.commercial || '',
    infrastructure: current?.expenses.infrastructure || '',
    facilities: current?.expenses?.facilities || '',
  };

  if (!current && id) {
    return (
      <div className='flex items-center justify-center h-[70vh]'>
        <Spin loading={true} />
      </div>
    );
  } else {
    return (
      <Form
        name='expenses-form'
        className='w-full'
        onFinish={onFinish}
        ref={expenseRef}
        layout='vertical'
        initialValues={defaultValues}
      >
        <div className='flex justify-between'>
          <Form.Item className='flex justify-end'>
            <Button onClick={() => handlePrevious('2')} type='default'>
              Previous
            </Button>
          </Form.Item>
          <Form.Item className='flex justify-end'>
            <Button type='primary' htmlType='submit'>
              Next
            </Button>
          </Form.Item>
        </div>

        <Form.Item
          rules={[
            {
              required: true,
              message: 'Please input safety and security expense',
            },
          ]}
          label='Safety and Security'
          name='safetyAndSecurity'
        >
          <InputNumber className='w-full' min={0} />
        </Form.Item>

        <Form.Item
          rules={[
            { required: true, message: 'Please input public spacing expense' },
          ]}
          label='Public Spacing'
          name='publicSpacing'
        >
          <InputNumber className='w-full' min={0} />
        </Form.Item>

        <Form.Item
          rules={[{ required: true, message: 'Please input housing expense' }]}
          label='Housing'
          name='housing'
        >
          <InputNumber className='w-full' min={0} />
        </Form.Item>

        <Form.Item
          rules={[
            { required: true, message: 'Please input commercial expense' },
          ]}
          label='Commercial'
          name='commercial'
        >
          <InputNumber className='w-full' min={0} />
        </Form.Item>

        <Form.Item
          rules={[
            { required: true, message: 'Please input infrastructure expense' },
          ]}
          label='Infrastructure'
          name='infrastructure'
        >
          <InputNumber className='w-full' min={0} />
        </Form.Item>

        <Form.Item
          rules={[
            { required: true, message: 'Please input facilities expense' },
          ]}
          label='Facilities'
          name='facilities'
        >
          <InputNumber className='w-full' min={0} />
        </Form.Item>
      </Form>
    );
  }
};

export default Expenses;
