import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../@api/axios';
import { message } from 'antd';

const createBank = createAsyncThunk(
  'banks/add',
  async (bankData, { rejectWithValue }) => {
    const finalData = {
      ...bankData,
      parentBanks: bankData?.parentBanks || [],
      bankType: bankData?.parentBanks ? 'sub' : 'main',
    };
    try {
      const response = await axiosInstance.post('/bankaccounts', finalData);
      message.success('Bank added Successfully');
      return response.data;
    } catch (err) {
      message.error('Bank creation failed', err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchBanks = createAsyncThunk(
  'banks/getAll',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/bankaccounts');
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchBankById = createAsyncThunk(
  'banks/getById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/bankaccounts/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const updateBank = createAsyncThunk(
  'banks/update',
  async (bankData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/bankaccounts/${bankData._id}`,
        bankData
      );
      message.success('Bank updated successfully');
      return response.data;
    } catch (err) {
      message.error('Something went wrong');
      return rejectWithValue(err.response.data);
    }
  }
);

const deleteBank = createAsyncThunk(
  'banks/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/bankaccounts/${id}`);
      message.success('Bank deleted successfully');
      return id;
    } catch (err) {
      message.error('Something went wrong');
      return rejectWithValue(err.response.data);
    }
  }
);

export { createBank, fetchBanks, fetchBankById, updateBank, deleteBank };
