import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../@api/axios';
import { message } from 'antd';

const createUser = createAsyncThunk(
  'users/add',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/users', userData);
      message.success('User created Successfully');
      return response.data;
    } catch (err) {
      message.error(err.message);
      return rejectWithValue(err.response.data);
    }
  }
);

const updateUser = createAsyncThunk(
  'users/update',
  async ({ userId, userData }, { rejectWithValue }) => {
    try {
      delete userData?.newPassword;
      delete userData?.confirmPassword;
      const response = await axiosInstance.put(`/users/${userId}`, userData);
      message.success('User updated Successfully');
      return response.data.user;
    } catch (err) {
      message.error('something went wrong');
      return rejectWithValue(err.response.data);
    }
  }
);

const deleteUser = createAsyncThunk(
  'users/delete',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/users/${userId}`);
      message.success('User deleted Successfully');
      return { id: userId, message: response.data.message };
    } catch (err) {
      message.error('something went wrong');
      return rejectWithValue(err.response.data);
    }
  }
);

const getAllUsers = createAsyncThunk(
  'users/getAll',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/users');
      return response.data;
    } catch (err) {
      message.error('something went wrong');
      return rejectWithValue(err.response.data);
    }
  }
);

const blockUser = createAsyncThunk(
  'users/block',
  async ({ userId, status }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/users/${userId}/block`, {
        blockStatus: status,
      });
      if (status) {
        message.success('User blocked Successfully');
      } else {
        message.success('User Unblocked Successfully');
      }
      return response.data;
    } catch (err) {
      message.error('Something went wrong while blocking the user.');
      return rejectWithValue(err.response.data);
    }
  }
);

const createAgent = createAsyncThunk(
  'create-agent',
  async (info, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/agent`, {
        ...info,
      });
      message.success('Agent Added Successfully');
      return response.data;
    } catch (err) {
      message.error('Something went wrong creating agent.');
      return rejectWithValue(err.response.data);
    }
  }
);

const getAgents = createAsyncThunk(
  'get-agent',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/agents`);
      return response.data;
    } catch (err) {
      message.error('Something went wrong creating agent.');
      return rejectWithValue(err.response.data);
    }
  }
);

const updateAgent = createAsyncThunk(
  'updateAgent-agent',
  async ({ id, info }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/agent/${id}`, { ...info });
      return response.data;
    } catch (err) {
      message.error('Something went wrong while updating agent.');
      return rejectWithValue(err.response.data);
    }
  }
);

const deleteAgent = createAsyncThunk(
  'delete-agent',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/agent/${id}`);
      return id;
    } catch (err) {
      message.error('Something went wrong while deleting agent.');
      return rejectWithValue(err.response.data);
    }
  }
);

export {
  updateUser,
  deleteUser,
  createUser,
  getAllUsers,
  blockUser,
  createAgent,
  getAgents,
  updateAgent,
  deleteAgent,
};
