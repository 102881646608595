import { createSlice } from "@reduxjs/toolkit";
import {
  createBank,
  fetchBanks,
  fetchBankById,
  updateBank,
  deleteBank,
} from "./bankActions";

const bankSlice = createSlice({
  name: "bank",
  initialState: {
    banks: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createBank.pending, (state) => {
        state.loading = true;
      })
      .addCase(createBank.fulfilled, (state, action) => {
        state.loading = false;
        state.banks.push(action.payload);
      })
      .addCase(createBank.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchBanks.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBanks.fulfilled, (state, action) => {
        state.loading = false;
        state.banks = action.payload;
      })
      .addCase(fetchBanks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchBankById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBankById.fulfilled, (state, action) => {
        state.loading = false;
        state.banks = action.payload;
      })
      .addCase(fetchBankById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateBank.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBank.fulfilled, (state, action) => {
        state.loading = false;
        state.banks = action.payload;
      })
      .addCase(updateBank.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteBank.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBank.fulfilled, (state, action) => {
        state.loading = false;
        state.banks = action.payload;
      })
      .addCase(deleteBank.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export default bankSlice.reducer;
