import React, { useEffect } from 'react';
import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSocieties } from '@store/society/societyActions';
import { SearchBar } from '@components';
import { SocietyCard } from '@components/macros';

function SearchList() {
  const dispatch = useDispatch();
  const { societies, loading, error } = useSelector((state) => state.society);

  useEffect(() => {
    loading && message.loading('Loading societies...');
  }, [loading]);

  useEffect(() => {
    dispatch(fetchSocieties());
  }, []);

  const onSelect = () => {};

  const onSearch = (value) => {
    console.log('here');
    console.log(value);
    alert(`Search value: ${value}`);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleSearch = () => {};
  return (
    <div className='w-full bg-white overflow-auto'>
      <section className='bg-white container-padding'>
        <div className='flex justify-center items-center w-full'>
          <SearchBar
            placeholder='input search text'
            handleSearch={handleSearch}
          />
        </div>
      </section>
      <section className='mt-2 mx-2'>
        <div className='card-list-wrapper'>
          {societies.length >= 0 &&
            societies?.map((society) => (
              <SocietyCard key={society.id} society={society} />
            ))}
        </div>
      </section>
    </div>
  );
}

export default SearchList;
