import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPlotByPhaseId } from '../@store/plots/plotActions';

const usePlots = (phaseId, filter = 'unsold') => {
  const dispatch = useDispatch();
  const { plots, loading, error } = useSelector((state) => state.plot);
  const [filteredPlots, setFilteredPlots] = useState([]);

  useEffect(() => {
    if (phaseId) {
      dispatch(fetchPlotByPhaseId(phaseId));
    }
    if (!phaseId) {
      setFilteredPlots([]);
    }
  }, [phaseId]);

  useEffect(() => {
    const isValid = Array.isArray(plots?.plots) && plots?.plots?.length > 0;
    if (isValid && filter) {
      let d = plots.plots.filter((plot) => plot.status === filter);
      setFilteredPlots([...d]);
    }
    if (isValid && !filter) {
      setFilteredPlots([...plots.plots]);
    }
  }, [plots, filter]);

  return { plots: filteredPlots, loading, error };
};

export { usePlots };
