import { createAction, createReducer } from '@reduxjs/toolkit';
import {
	loginUser,
	logoutUser,
	resetPassword,
	signUp,
	updateUser,
	verifyToken,
} from './authActions';

const initialState = {
	entities: {},
	loading: false,
	error: null,
	isTokenValid: false,
};

export const logoutUserAction = createAction('auth/logoutUser');

const authReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(signUp.pending, (state) => {
			state.loading = true;
		})
		.addCase(signUp.fulfilled, (state, action) => {
			state.loading = false;
			state.entities = action.payload;
		})
		.addCase(signUp.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload;
		})
		.addCase(verifyToken.pending, (state) => {
			state.loading = true;
		})
		.addCase(verifyToken.fulfilled, (state, action) => {
			state.loading = false;
			state.isTokenValid = action.payload;
		})
		.addCase(verifyToken.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload;
		})
		.addCase(loginUser.pending, (state) => {
			state.loading = true;
		})
		.addCase(loginUser.fulfilled, (state, action) => {
			state.loading = false;
			state.entities = action.payload;
		})
		.addCase(loginUser.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload;
		})
		.addCase(resetPassword.pending, (state) => {
			state.loading = true;
		})
		.addCase(resetPassword.fulfilled, (state, action) => {
			state.loading = false;
			state.entities = action.payload;
		})
		.addCase(resetPassword.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload;
		})
		.addCase(logoutUserAction, (state) => {
			state.entities = {};
		})
		.addCase(updateUser.pending, (state) => {
			state.loading = true;
		})
		.addCase(updateUser.fulfilled, (state, action) => {
			state.loading = false;
			state.entities = action.payload;
		})
		.addCase(updateUser.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload;
		});
});

export default authReducer;
