import React, { useRef, useEffect } from 'react'
import { useOnClickOutside } from '../../hooks'
import { RxCross1 } from 'react-icons/rx'

const Modal = ({
  open,
  handleClose,
  children,
  className,
  style,
  childrenClass,
}) => {
  const ref = useRef()
  useOnClickOutside(ref, () => handleClose())

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        handleClose()
      }
    }
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleClose])
  return (
    <>
      {open && (
        <div
          className="w-screen    h-screen z-50 right-0 top-0 fixed shadow bg-white flex justify-center items-center m-0"
          style={{
            backgroundColor: 'rgba(0,0,0,0.8)',
            margin: '0px',
          }}
        >
          <div
            className={`relative bg-white p-7 text-white rounded-[0.8rem]  ${className}`}
            ref={ref}
            style={style}
          >
            <div
              className={`flex flex-col items-center justify-center w-full h-full ${childrenClass}`}
            >
              {children}
            </div>

            <RxCross1
              color="black"
              size={18}
              onClick={() => {
                handleClose()
              }}
              className="absolute top-4 right-4 cursor-pointer"
            />
          </div>
        </div>
      )}
    </>
  )
}
export default Modal
