import { createSlice } from '@reduxjs/toolkit';
import {
  createSociety,
  deleteSociety,
  fetchSocieties,
  updateSociety,
  getSocietyById,
  searchSocieties,
} from './societyActions';

const societySlice = createSlice({
  name: 'society',
  initialState: {
    societies: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSociety.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createSociety.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createSociety.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSocieties.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSocieties.fulfilled, (state, action) => {
        state.loading = false;
        state.societies = action.payload;
      })
      .addCase(fetchSocieties.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(searchSocieties.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchSocieties.fulfilled, (state, action) => {
        state.loading = false;
        state.societies = action.payload;
      })
      .addCase(searchSocieties.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSocietyById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSocietyById.fulfilled, (state, action) => {
        state.loading = false;
        state.societies = action.payload;
      })
      .addCase(getSocietyById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(deleteSociety.pending, (state) => {
        state.loading = false;
      })
      .addCase(deleteSociety.fulfilled, (state, action) => {
        console.log(action.payload);
        state.loading = false;
        state.societies = state.societies.filter(
          (society) => society._id !== action.payload
        );
      })
      .addCase(deleteSociety.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateSociety.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSociety.fulfilled, (state, action) => {
        state.loading = false;
        // state.societies = state.societies.map((society) =>
        //   society._id === action.payload._id ? action.payload : society,
        // )
      })
      .addCase(updateSociety.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default societySlice.reducer;
