import { createSlice } from "@reduxjs/toolkit";
import {
  createPlot,
  deletePlot,
  fetchPlotByPhaseId,
  updatePlot,
} from "./plotActions";

const plotSlice = createSlice({
  name: "plot",
  initialState: {
    plots: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPlot.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createPlot.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createPlot.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchPlotByPhaseId.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPlotByPhaseId.fulfilled, (state, action) => {
        state.loading = false;
        state.plots = action.payload;
      })
      .addCase(fetchPlotByPhaseId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deletePlot.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePlot.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deletePlot.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updatePlot.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePlot.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updatePlot.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default plotSlice.reducer;
