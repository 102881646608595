import { Button, Popconfirm, Table } from 'antd';
import React from 'react';
import ExportableTableHOC from '../../hoc/ExportTable';
import moment from 'moment/moment';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { deleteInstallment } from '../../@store/sales/salesActions';

function InstallmentTable({
  handleInstallment,
  paymentMethods,
  createdInstallments,
  saleId,
}) {
  const dispatch = useDispatch();

  const handleDeleteInstallment = (record) => {
    dispatch(
      deleteInstallment({
        saleId: saleId,
        installmentNumber: record?.installmentNumber,
      })
    );
  };

  const columns = [
    {
      title: 'Installment Number',
      dataIndex: 'installmentNumber',
      key: 'installmentNumber',
      align: 'center',
    },
    {
      title: 'Amount',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
      align: 'center',
    },
    {
      title: 'Payment Date',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      align: 'center',
      render: (paymentDate, record) => moment(paymentDate).format('YYYY-MM-DD'),
    },

    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <Popconfirm
          title='Are you sure to delete this installment?'
          onConfirm={() => handleDeleteInstallment(record)}
          okText='Yes'
          cancelText='No'
        >
          <DeleteOutlined className='cursor-pointer text-red-600 text-base ml-5' />
        </Popconfirm>
      ),
    },
  ];

  return (
    <Table
      dataSource={createdInstallments}
      id={'table-to-export'}
      columns={columns}
      pagination={{
        defaultPageSize: 5,
        pageSizeOptions: ['5', '10'],
        showSizeChanger: true,
      }}
      size='small'
      title={() => (
        <div className='w-full'>
          <span className='flex justify-end'>
            <Button
              type='primary'
              onClick={handleInstallment}
              disabled={!paymentMethods.includes('installments')}
              icon={<PlusOutlined />}
            >
              Add Installment
            </Button>
          </span>
        </div>
      )}
      bordered
    />
  );
}

export default ExportableTableHOC({
  TableComponent: InstallmentTable,
  FileName: 'installments',
});
