import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAgents } from "../@store/users/userActions";

const useAgents = () => {
  const dispatch = useDispatch();
  const { agents, loading, error } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getAgents());
  }, [dispatch]);

  return { agents, loading, error };
};

export default useAgents;
