import { Carousel, Image } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { BorderHeading, SmallCard } from '../macros';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSocietyById } from '../../@store/society/societyActions';
import { FaExpeditedssl } from 'react-icons/fa';
import {
  BsFillArrowDownLeftCircleFill, BsFillArrowRightCircleFill,
  BsFillArrowUpRightCircleFill,
  BsFillBuildingsFill,
  BsFillHouseFill,
} from 'react-icons/bs';
import { HiWrenchScrewdriver } from 'react-icons/hi2';
import { HiOutlineMailOpen, HiPhone } from 'react-icons/hi';
import { MdOutlineHouseSiding, MdOutlineSpaceDashboard } from 'react-icons/md';
import { AiTwotoneBuild } from 'react-icons/ai';
import { FiLink } from 'react-icons/fi';

const SocietyProfile = () => {
  const { id } = useParams();
  const [society, setSociety] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { entities } = useSelector((state) => state.auth);

  useEffect(() => {
    if (id) {
      dispatch(getSocietyById(id))
        .unwrap()
        .then((data) => {
          // setLoading(true)
          setSociety(data);
          // setLoading(false)
        });
    }
  }, [id]);

  const handlePhasesClick = () => {
    if (entities?.role === 'superAdmin') {
      navigate(`/super-admin/societies/phases/${id}`);
    }
    if (entities?.role === 'admin') {
      navigate(`/admin/societies/phases/${id}`);
    }
  };
  return (
    <div className='flex-column gap-10 p-10'>
      <div>
        <div className='flex justify-between'>
          <BorderHeading heading='Society Profile' />
          <div>
            <div className='flex gap-2 items-center'>
              {society &&
                society.social &&
                Object.entries(society?.social)?.map(([key, value]) => (
                  <a
                    key={key}
                    href={value}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img
                      src={`https://img.icons8.com/color/48/000000/${key}.png`}
                      alt={key}
                      className='mr-2 w-8 h-8'
                    />
                  </a>
                ))}
              <a href={society?.contact.website}>
                <FiLink size='25' />
              </a>
            </div>
          </div>
        </div>

        <div className='grid gap-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-4'>
          <SmallCard
            image={society?.logo}
            variant='white'
            title={society?.name}
            subheading='Society Name'
          />
          <SmallCard
            subheading='Debit'
            Icon={
              <BsFillArrowUpRightCircleFill fill='text-primary' size='30px' />
            }
            variant='white'
            title={society?.debit}
          />
          <SmallCard
            subheading='Credit'
            Icon={
              <BsFillArrowDownLeftCircleFill fill='text-primary' size='30px' />
            }
            variant='white'
            title={society?.credit}
          />

          <SmallCard
            subheading='Total Phases'
            Icon={<BsFillArrowRightCircleFill fill='text-primary' size='30' />}
            variant='white'
            title={society?.phases.length}
            onClick={handlePhasesClick}
          />
        </div>
      </div>
      <div>
        <BorderHeading heading='Expenses' />
        <div className='grid gap-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-4'>
          <SmallCard
            subheading='Safety and Security'
            Icon={<FaExpeditedssl fill='text-primary' size='30' />}
            variant='white'
            title={society?.expenses?.safetyAndSecurity}
          />
          <SmallCard
            subheading='Commercial'
            Icon={<BsFillBuildingsFill fill='text-primary' size='30' />}
            variant='white'
            title={society?.expenses?.commercial}
          />
          <SmallCard
            subheading='Housing'
            Icon={<BsFillHouseFill fill='text-primary' size='30' />}
            variant='white'
            title={society?.expenses?.housing}
          />
          <SmallCard
            subheading='Public Spacing'
            Icon={<MdOutlineSpaceDashboard fill='text-primary' size='30' />}
            variant='white'
            title={society?.expenses?.publicSpacing}
          />
          <SmallCard
            subheading='Infrastructure'
            Icon={<AiTwotoneBuild fill='text-primary' size='30' />}
            variant='white'
            title={society?.expenses?.infrastructure}
          />{' '}
          <SmallCard
            subheading='Facilities'
            Icon={<HiWrenchScrewdriver fill='text-primary' size='30' />}
            variant='white'
            title={society?.expenses?.facilities}
          />
        </div>
      </div>
      <div>
        <BorderHeading heading='Address' />
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
          <SmallCard
            subheading='Address'
            Icon={<BsFillHouseFill fill='text-primary' size='30' />}
            variant='white'
            title={`${society?.address?.street}, ${society?.address?.city}, ${society?.address?.state}, ${society?.address?.country} ${society?.address?.zip}`}
          />
        </div>
      </div>
      <div>
        <BorderHeading heading='Contact' />
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
          <SmallCard
            subheading='Phone'
            Icon={<HiPhone fill='#1890ff' size='30' />}
            variant='white'
            title={society?.contact.phone}
          />
          <SmallCard
            subheading='Email'
            Icon={<HiOutlineMailOpen fill='#1890ff' size='30' />}
            title={society?.contact.email}
          />
        </div>
      </div>

      <div className='flex flex-col'>
        <BorderHeading heading='Summary' />
        <p>{society?.description}</p>
      </div>

      <div className='w-[400px]'>
        <Carousel className='rounded-xl' autoplay>
          {society &&
            society?.images.map((imageUrl) => (
              <Image
                key={imageUrl}
                src={imageUrl}
                height={200}
                width='100%'
                className='rounded-xl'
                fallback='https://dev.w3.org/SVG/tools/svgweb/samples/svg-files/gaussian3.svg'
              />
            ))}
        </Carousel>
      </div>
    </div>
  );
};

export default SocietyProfile;
