import React from "react";
import { Modal, Table } from "antd";
import moment from "moment";

const TableComponent = ({ openModal, expense: data, handleClose }) => {
  const columns = [
    {
      title: "Field",
      dataIndex: "field",
      key: "field",
      className: "font-bold",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Field",
      dataIndex: "field2",
      key: "field2",
      className: "font-bold",
    },
    {
      title: "Value",
      dataIndex: "value2",
      key: "value2",
    },
  ];

  const dataSource = [
    {
      field: "Name",
      value: data?.name || "---",
      field2: "Type",
      value2: data?.type || "---",
    },
    {
      field: "Description",
      value: data?.description || "---",
      field2: "Amount",
      value2: data?.amount || "---",
    },
    {
      field: "Date",
      value: data?.date ? moment(data.date).format("YYYY-MM-DD") : "---",
      field2: "Office",
      value2: data?.office?.name || "---",
    },
    {
      field: "Society",
      value: data?.society?.name || "---",
      field2: "",
      value2: "",
    },
  ];

  return (
    <Modal visible={openModal} onCancel={handleClose} footer={null} width={800}>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        showHeader={false}
      />
    </Modal>
  );
};

export default TableComponent;
