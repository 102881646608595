import React from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';

const ExportableTableHOC = ({ TableComponent, FileName }) => {
  return function ExportableTable({ ...props }) {
    function createSimplifiedTable(table) {
      const newTable = document.createElement('table');
      let excludedColumns = []; // To store the indices of columns to be excluded

      const thead = document.createElement('thead');
      newTable.appendChild(thead);

      // Extract the headers
      const headerCells = table.querySelectorAll('thead th');
      const headerRow = document.createElement('tr');
      thead.appendChild(headerRow); // Append header row to thead, not directly to the table
      headerCells.forEach((cell, index) => {
        if (cell.textContent === 'Action') {
          excludedColumns.push(index);
          return;
        }
        const newHeader = document.createElement('th');
        newHeader.textContent = cell.textContent;
        headerRow.appendChild(newHeader);
      });

      const tbody = document.createElement('tbody');
      newTable.appendChild(tbody); // Append tbody to the table

      // Extract the data rows
      const dataRows = table.querySelectorAll('tbody tr');
      dataRows.forEach((row) => {
        const cells = row.querySelectorAll('td');
        const dataRow = document.createElement('tr');
        tbody.appendChild(dataRow); // Append data rows to tbody, not directly to the table
        cells.forEach((cell, index) => {
          if (excludedColumns.includes(index)) {
            return;
          }
          const newCell = document.createElement('td');
          const switchElem = cell.querySelector('.ant-switch');
          if (switchElem) {
            newCell.textContent = switchElem.classList.contains(
              'ant-switch-checked'
            )
              ? 'Yes'
              : 'No';
          } else {
            newCell.textContent = cell.textContent;
          }
          dataRow.appendChild(newCell);
        });
      });

      return newTable;
    }

    function getFormattedDate() {
      const currentDate = new Date();
      return `${currentDate.getDate().toString().padStart(2, '0')}-${(
        currentDate.getMonth() + 1
      )
        .toString()
        .padStart(2, '0')}-${currentDate.getFullYear()}`;
    }

    const handleExportToPdf = async () => {
      const doc = new jsPDF();

      const logoURL = process.env.PUBLIC_URL + '/logo.png';
      const response = await fetch(logoURL);
      const blob = await response.blob();
      const logo = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });

      doc.addImage(logo, 'PNG', 50, 5, 100, 25); // Adjust x, y, width, and height as needed

      const node = createSimplifiedTable(
        document.querySelector('#table-to-export')
      );

      // Adjust the starting Y-coordinate for the table if the logo takes space at the top
      autoTable(doc, {
        html: node,
        startY: 30,
        styles: {
          fontSize: 8, // Adjust the font size of table content. Default is 10.
          cellPadding: { top: 2, right: 2, bottom: 2, left: 2 }, // Adjust cell padding. Default is { top: 4, right: 4, bottom: 4, left: 4 }
        },
        headStyles: {
          fontStyle: 'bold',
          fontSize: 8, // Adjust the font size of table header. Default is 10.
          cellPadding: { top: 2, right: 2, bottom: 2, left: 2 }, // Adjust cell padding for the header
        },
      });

      doc.save(`${FileName} - ${getFormattedDate()}.pdf`);
    };

    const handleExportToExcel = () => {
      const table = createSimplifiedTable(
        document.querySelector('#table-to-export')
      ); // Use the simplified table for Excel as well

      // Convert to workbook format and bold headers
      const ws = XLSX.utils.table_to_sheet(table);
      const wb = XLSX.utils.book_new();
      const headerRange = XLSX.utils.decode_range(ws['!ref']);
      for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
        const address = XLSX.utils.encode_cell({ c: col, r: headerRange.s.r });
        if (ws[address]) ws[address].s = { font: { bold: true } };
      }

      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, `${FileName} - ${getFormattedDate()}.xlsx`);
    };

    return (
      <div>
        <TableComponent {...props} />
        <div className={'flex gap-3 justify-end m-3'}>
          <Button
            onClick={handleExportToPdf}
            icon={<DownloadOutlined />}
            type={'default'}
            size='small'
          >
            PDF
          </Button>
          <Button
            onClick={handleExportToExcel}
            icon={<DownloadOutlined />}
            type={'default'}
            size='small'
          >
            Excel
          </Button>
        </div>
      </div>
    );
  };
};

export default ExportableTableHOC;
