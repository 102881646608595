import { createSlice } from '@reduxjs/toolkit'
import {
  createCustomer,
  updateCustomer,
  deleteCustomer,
  getAllCustomers
} from './customerActions'

const customerSlice = createSlice({
  name: 'customers',
  initialState: {
    customers: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCustomer.pending, (state, action) => {
        state.loading = true
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.loading = false
        state.entities = action.payload.customer
      })
      .addCase(createCustomer.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(getAllCustomers.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getAllCustomers.fulfilled, (state, action) => {
        state.loading = false
        state.customers = action.payload
      })
      .addCase(getAllCustomers.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(updateCustomer.pending, (state, action) => {
        state.loading = true
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.loading = false
        const { customer } = action.payload
        const index = state.customers.findIndex((e) => e._id === customer)
        if (index >= 0) {
          state.customers[index] = customer
        }
      })
      .addCase(updateCustomer.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(deleteCustomer.pending, (state, action) => {
        state.loading = true
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(deleteCustomer.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

  },
})

export default customerSlice.reducer
