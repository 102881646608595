import React from "react";

const SmallCard = ({
  title,
  image,
  variant,
  subheading,
  content,
  cardHeight = "100px",
  Icon = null,
  onClick = null,
  bg = "bg-white",
}) => {
  const colorScheme = variant === "white" ? `text-gray-700 ${bg}` : "";
  return (
    <div
      className={`flex p-3 justify-between text-center ${colorScheme} items-center gap-4 h-[${cardHeight}] w-full rounded-md shadow-md cursor-pointer ${bg}`}
      onClick={onClick}
    >
      <div className="flex-column text-left gap-2">
        <span className="text-[#8c8c8c]">{subheading}</span>
        <h3>{title}</h3>
      </div>
      {content && <span className="text-[#8c8c8c]">{content}</span>}
      {image && (
        <img src={image} className="w-[50px] h-[50px] rounded-[100%]" />
      )}
      <span>{Icon && Icon}</span>
    </div>
  );
};

export default SmallCard;
