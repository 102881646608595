import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import dayjs from 'dayjs';
import {
	ArrowRightOutlined,
	CheckCircleOutlined,
	DeleteOutlined,
	EditOutlined,
	EyeOutlined,
} from '@ant-design/icons';
import { Button, DatePicker, Popconfirm, Segmented, Table } from 'antd';
import ExportableTableHOC from '@hoc/ExportTable';
import {
	confirmExpense,
	deleteExpense,
	getAllExpenses,
} from '@store/expense/expenseActions';
import getColumnSearchProps from '@components/Tables/searchFunction';
import ExpensesModal from '@components/Modals/ExpensesModal';
import './expense.css';

const { RangePicker } = DatePicker;
const filterTypeMap = ['Daily', 'Weekly', 'Monthly', 'Yearly', 'All'];
const filterTypeShort = ['Daily', 'Weekly', 'Monthly'];

const daysPresets = {
	label: 'Last 90 Days',
	value: [dayjs().add(-90, 'd'), dayjs()],
};
const rangePresets = [
	{
		label: 'Last 7 Days',
		value: [dayjs().add(-7, 'd'), dayjs()],
	},
	{
		label: 'Last 14 Days',
		value: [dayjs().add(-14, 'd'), dayjs()],
	},
	{
		label: 'Last 30 Days',
		value: [dayjs().add(-30, 'd'), dayjs()],
	},
];

function ExpenseManagement({ onEditClick, confirmExpensesMode = false }) {
	const [searchedColumn, setSearchedColumn] = useState('');
	const [searchText, setSearchText] = useState('');
	const [dateRange, setDateRange] = useState([]);
	const { expenses, loading } = useSelector((state) => state.expense); // Change 'sale' to 'expense'
	const { entities } = useSelector((state) => state.auth); // Change 'sale' to 'expense'
	const [filter, setFilter] = useState('Daily');
	const dispatch = useDispatch();
	const searchInput = useRef(null);
	const [showModal, setShowModal] = useState(false);
	const [selectedExpense, setSelectedExpense] = useState(null); // Change 'selectedSale' to 'selectedExpense'
	const [isSelected, setIsSelected] = useState(false);
	const [tableColumns, setTableColumns] = useState();

	let total = 0;

	if (expenses?.length > 0) {
		// Change 'sales' to 'expenses'
		expenses.forEach((expense) => {
			// Change 'sale' to 'expense'
			total += expense.amount; // Change 'salePrice' to 'amount'
		});
	}

	const handleShowModalOpen = (record) => {
		setShowModal(true);
		setSelectedExpense(record); // Change 'selectedSale' to 'selectedExpense'
	};

	const handleModalClose = () => {
		setShowModal(false);
		setSelectedExpense(null); // Change 'selectedSale' to 'selectedExpense'
	};

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText('');
		setSearchedColumn('');
	};

	const handleDeleteExpense = (record) => {
		// Change 'deleteSale' to 'deleteExpense'
		dispatch(deleteExpense(record._id)) // Change 'deleteSale' to 'deleteExpense'
			.unwrap()
			.then((res) => {
				dispatch(getAllExpenses()); // Change 'getAllSales' to 'getAllExpenses'
			});
	};

	function handleFilterTypeChange(value) {
		setFilter(value);
		setDateRange([]);
	}

	const handleDetailsModalOpen = (record) => {};

	useEffect(() => {
		dispatch(getAllExpenses());
	}, [dispatch]);

	const handleEditExpense = (expenseId) => {
		// Change 'handleEditSale' to 'handleEditExpense'
		const url = new URL(window.location.href);
		url.pathname = '/super-admin/expenses/create/search'; // Change '/super-admin/sales/create/search' to '/super-admin/expenses/create/search'
		url.searchParams.set('expenseId', expenseId); // Change 'saleId' to 'expenseId'
		window.location.href = url.toString();
	};

	const rowSelection = {
		onSelect: (record, selected, selectedRows) => {
			if (selectedRows.length > 0) {
				setIsSelected([...selectedRows]);
			} else {
				setIsSelected();
			}
		},
		onSelectAll: (selected, selectedRows, changeRows) => {
			if (selectedRows.length > 0) {
				setIsSelected([...selectedRows]);
			} else {
				setIsSelected();
			}
		},
	};

	const handleConfirmExpense = () => {
		// Change 'handleConfirmSale' to 'handleConfirmExpense'
		dispatch(confirmExpense(isSelected)); // Change 'confirmSales' to 'confirmExpenses'
	};

	const actions = {
		title: 'Action',
		key: 'action',
		align: 'center',
		render: (text, record) => {
			const isSocietyHeadStaff = entities?.role === 'societyHeadStaff';

			const editIcon = (
				<EditOutlined
					className='text-blue-600 text-base'
					onClick={() => handleEditExpense(record._id)} // Change 'handleEditSale' to 'handleEditExpense'
				/>
			);

			const deleteIcon = (
				<Popconfirm
					placement='topLeft'
					title='Are you sure you want to delete this item?'
					onConfirm={() => {
						handleDeleteExpense(record); // Change 'handleDeleteSale' to 'handleDeleteExpense'
					}}
					okText='Yes'
					cancelText='No'
				>
					<DeleteOutlined className='text-red-600 text-base' />
				</Popconfirm>
			);

			return (
				<div className='flex gap-3 justify-between'>
					<EyeOutlined
						className='text-green-600 text-base'
						onClick={() => handleShowModalOpen(record)}
					/>
					{!isSocietyHeadStaff && editIcon}
					{!isSocietyHeadStaff && deleteIcon}
				</div>
			);
		},
	};

	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			align: 'center',
			...getColumnSearchProps(
				'name',
				searchText,
				searchedColumn,
				setSearchText,
				setSearchedColumn,
				searchInput,
				handleSearch,
				handleReset
			),
		},
		{
			title: 'Type',
			dataIndex: 'type',
			key: 'type',
			align: 'center',
			...getColumnSearchProps(
				'type',
				searchText,
				searchedColumn,
				setSearchText,
				setSearchedColumn,
				searchInput,
				handleSearch,
				handleReset
			),
		},
		{
			title: 'Description',
			dataIndex: 'description', // Use the 'description' field from the Expense model
			key: 'description', // Use the 'description' field from the Expense model
			align: 'center',
		},
		{
			title: 'Amount',
			dataIndex: 'amount', // Use the 'amount' field from the Expense model
			key: 'amount', // Use the 'amount' field from the Expense model
			align: 'center',
		},
		{
			title: 'Office', // You can add this column if you have an 'office' reference in your Expense model
			dataIndex: ['office', 'name'], // Adjust this path according to your Expense model's structure
			key: 'office.name', // Adjust this path according to your Expense model's structure
			align: 'center',
			...getColumnSearchProps(
				'office.name', // Adjust this path according to your Expense model's structure
				searchText,
				searchedColumn,
				setSearchText,
				setSearchedColumn,
				searchInput,
				handleSearch,
				handleReset
			),
			// render: (office) => {
			//   // Assuming 'society' is an array of objects with 'name' property
			//   return office.map((s) => s.name).join(", ");
			// },
		},
		// society: [
		//     {
		//         "_id": "645f88a51ffa4ed89b11430e",
		//         "name": "Usmania Village"
		//     }
		// ]

		{
			title: 'Society', // You can add this column if you have a 'society' reference in your Expense model
			dataIndex: ['society'], // Adjust this path according to your Expense model's structure
			key: 'society', // Adjust this path according to your Expense model's structure
			align: 'center',
			...getColumnSearchProps(
				'society', // Adjust this path according to your Expense model's structure
				searchText,
				searchedColumn,
				setSearchText,
				setSearchedColumn,
				searchInput,
				handleSearch,
				handleReset
			),
			render: (society) => {
				// Assuming 'society' is an array of objects with 'name' property
				return society.map((s) => s.name).join(', ');
			},
		},
		{
			title: 'Date',
			dataIndex: 'date', // Use the 'date' field from the Expense model
			key: 'expenseDate', // Use the 'date' field from the Expense model
			align: 'center',
			render: (text) => {
				const expenseDate = new Date(text); // Use the 'date' field from the Expense model
				return expenseDate.toLocaleDateString('en-GB');
			},
		},
	];

	useEffect(() => {
		if (!confirmExpensesMode) {
			setTableColumns([...columns, actions]);
		} else {
			setTableColumns([...columns]);
		}
	}, [confirmExpensesMode]);

	const disabledDate = (current) => {
		// Disable dates that are more than 30 days in the past
		return (
			confirmExpensesMode &&
			current &&
			current < moment().subtract(30, 'days').endOf('day')
		);
	};

	return (
		<>
			<div>
				<>
					<div className='container-padding h-full bg-white'>
						<div className='w-[50%] md:w-full flex-column gap-2'>
							<Table
								id={'table-to-export'}
								size='small'
								showHeader={true}
								loading={loading}
								filterType={filter}
								rowSelection={{ ...rowSelection }}
								rowKey={(record) => record._id} // Change 'record._id' to 'record._id'
								title={() => (
									<div className={'flex justify-between'}>
										<div>
											<Segmented
												options={
													confirmExpensesMode ? filterTypeShort : filterTypeMap
												}
												onChange={(value) => handleFilterTypeChange(value)}
												value={filter}
											/>
										</div>

										<div className={'mr-20'}>
											<RangePicker
												value={dateRange}
												allowEmpty
												presets={rangePresets}
												onChange={(dates) => {
													setFilter('All');
													setDateRange(dates);
												}}
												disabledDate={disabledDate}
											/>
										</div>

										{!confirmExpensesMode && (
											<div>
												<Button
													href={'./expenses/create'} // Change '/sales/create' to '/expenses/create'
													type='primary'
													icon={<ArrowRightOutlined />}
												>
													Add Expense
												</Button>
											</div>
										)}
										{confirmExpensesMode && (
											<div>
												<Popconfirm
													placement='topLeft'
													disabled={!isSelected}
													description="This action can't be undone!"
													title='Confirm Expenses?' // Change 'Confirm Sales?' to 'Confirm Expenses?'
													onConfirm={handleConfirmExpense} // Change 'handleConfirmSale' to 'handleConfirmExpense'
													okText='Yes'
													cancelText='No'
												>
													<Button
														disabled={!isSelected}
														type='primary'
														icon={<CheckCircleOutlined />}
													>
														Confirm
													</Button>
												</Popconfirm>
											</div>
										)}
									</div>
								)}
								bordered
								dataSource={
									expenses?.length > 0 // Change 'sales' to 'expenses'
										? expenses.filter((expense) => {
												// Change 'sale' to 'expense'
												let expenseDate = moment(expense?.date).startOf('day'); // Change 'sale.createdAt' to 'expense.date'

												if (dateRange?.length > 0) {
													expenseDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
													const startDate = dateRange[0]
														.startOf('day')
														.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
													const endDate = dateRange[1]
														.endOf('day')
														.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
													return expenseDate.isBetween(
														startDate,
														endDate,
														'day',
														'[]'
													);
												}

												if (filter === 'All') return true;

												if (filter === 'Yearly') {
													return expenseDate.year() === moment().year();
												}

												if (filter === 'Monthly') {
													return (
														expenseDate.year() === moment().year() &&
														expenseDate.month() === moment().month()
													);
												}

												if (filter === 'Weekly') {
													const startOfWeek = moment().startOf('week');
													const endOfWeek = moment().endOf('week');
													return expenseDate.isBetween(
														startOfWeek,
														endOfWeek,
														'day',
														'[]'
													);
												}

												if (filter === 'Daily') {
													return expenseDate
														.startOf('day')
														.isSame(moment().startOf('day'), 'day');
												}

												return false; // Return false for unsupported filter types
										  })
										: []
								}
								columns={tableColumns}
								rowClassName={(record, index) =>
									index % 2 === 0 ? 'even-row' : 'odd-row'
								}
								pagination={{
									defaultPageSize: 30,
									pageSizeOptions: ['10', '20', '50', '100'],
									showSizeChanger: true,
								}}
							/>
							{showModal && selectedExpense && (
								<ExpensesModal
									openModal={showModal}
									handleClose={handleModalClose}
									expense={selectedExpense}
								/>
							)}
						</div>
					</div>
				</>
			</div>
		</>
	);
}

export default ExportableTableHOC({
	TableComponent: ExpenseManagement,
	FileName: 'expenses',
});
