import React from 'react';
import {
	Page,
	Document,
	Text,
	StyleSheet,
	View,
	PDFViewer,
} from '@react-pdf/renderer';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

const HeaderRow = ({ text, width }) => (
	<Text style={{ ...styles.headerCell, width }}>{text}</Text>
);

const DataCell = ({ text, width }) => (
	<Text style={{ ...styles.tableCell, width }}>{text}</Text>
);

const InstallmentHeaderRow = () => (
	<View style={styles.installmentHeaderRow}>
		<HeaderRow text='Installment' width={80} />
		<HeaderRow text='Number' width={90} />
		<HeaderRow text='Amount' width={90} />
		<HeaderRow text='Approved' width={90} />
		<HeaderRow text='Payment Date' width={90} />
	</View>
);

const getPaymentMethodText = (value) => {
	const paymentMethodMap = {
		barter: 'Barter System',
		installments: 'Installment',
		net_payment: 'Cheque/Cash',
	};

	return paymentMethodMap[value] || value;
};

function getAllInstallmentsWithParent(inputObject) {
	const { installments, ...parentObject } = inputObject;
	const resultArray = [{ ...parentObject }];

	installments.forEach((installment) => {
		resultArray.push({ ...installment });
	});

	return resultArray;
}

const DataRow = ({ data, index }) => (
	<View key={index} style={styles.tableRow}>
		<DataCell text={data?.customer?.cnic} width={100} />
		<DataCell text={getPaymentMethodText(data?.paymentMethod[0])} width={90} />
		<DataCell text={data?.approved ? 'Yes' : 'No'} width={85} />
		<DataCell text={data?.customer?.name} width={100} />
		<DataCell text={data?.plot?.number} width={70} />
		<DataCell text={data?.phase?.name} width={80} />
		<DataCell text={data?.society?.name} width={80} />
		<DataCell text={data?.salePrice} width={80} />
		<DataCell text={moment(data.createdAt).format('DD/MM/YYYY')} width={80} />
	</View>
);

const GenerateSalesPDF = () => {
	const { state } = useLocation();

	const sales = state || [];

	const chunks = [];

	// Loop through sales and use getAllInstallmentsWithParent on each
	sales.forEach((data, index) => {
		const dataWithInstallments = getAllInstallmentsWithParent(data);
		// Push the final array to chunks
		chunks.push(dataWithInstallments);
	});

	const finalChunk = chunks.flat();

	// Number of items per new array
	const itemsPerArray = 11;

	// Calculate the number of new arrays needed
	const numberOfArrays = Math.ceil(finalChunk.length / itemsPerArray);

	// Create an array to store the new arrays
	const newArrayChunks = [];

	// Iterate through the finalChunk array and create new arrays
	for (let i = 0; i < numberOfArrays; i++) {
		const startIdx = i * itemsPerArray;
		const endIdx = startIdx + itemsPerArray;
		const newArray = finalChunk.slice(startIdx, endIdx);
		newArrayChunks.push(newArray);
	}

	let currentPageIndex = 0;

	return (
		<PDFViewer width='1000' height='600' className='app'>
			<Document pageLayout='singlePage'>
				{newArrayChunks.map((dataArray, arrayIndex) => (
					<Page key={arrayIndex} size='A4' style={styles.page} wrap>
						<View style={styles.titleContainer}>
							<Text style={styles.reportTitle}>Usmania & Sons</Text>
							<Text style={styles.subTitle}>{`\n`} Leading in Real Estate</Text>
						</View>

						<View style={styles.table}>
							{dataArray?.map((data, index) => (
								<View key={index}>
									{data?.paymentAmount ? (
										<View>
											<InstallmentHeaderRow />
											<View key={index} style={styles.installmentRowsWrapper}>
												<DataCell text='Installment' width={90} />
												<DataCell text={data.installmentNumber} width={90} />
												<DataCell text={data.paymentAmount} width={90} />
												<DataCell
													text={data.approved ? 'Yes' : 'No'}
													width={90}
												/>
												<DataCell
													text={moment(data.paymentDate).format('DD/MM/YYYY')}
													width={90}
												/>
											</View>
										</View>
									) : (
										<>
											<View style={styles.tableRow}>
												<HeaderRow text='CNIC' width={100} />
												<HeaderRow text='Pay. Method' width={90} />
												<HeaderRow text='Approved' width={85} />
												<HeaderRow text='Customer' width={100} />
												<HeaderRow text='Plot' width={70} />
												<HeaderRow text='Phase' width={80} />
												<HeaderRow text='Society' width={80} />
												<HeaderRow text='Sale Price' width={80} />
												<HeaderRow text='Sale Date' width={80} />
											</View>
											<DataRow data={data} index={index} />
										</>
									)}
								</View>
							))}
						</View>
						{arrayIndex !== newArrayChunks.length - 1 && currentPageIndex++}
					</Page>
				))}
			</Document>
		</PDFViewer>
	);
};

const styles = StyleSheet.create({
	page: {
		fontFamily: 'Helvetica',
		fontSize: 8,
		flexDirection: 'column',
		padding: 20,
	},
	table: { width: '100%', border: '1px solid #2980BA' },
	tableRow: { flexDirection: 'row', borderBottom: '1px solid #2980BA' },
	installmentRowsWrapper: {
		flexDirection: 'row',
		borderBottom: '1px solid #2980BA',
		justifyContent: 'flex-end',
	},
	installmentHeaderRow: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
	tableCell: { padding: 8, textAlign: 'center' },
	headerCell: {
		padding: 8,
		width: 80,
		textAlign: 'center',
		backgroundColor: '#2980BA',
		color: 'white',
		fontSize: 9,
	},
	paymentMethodRow: {
		padding: 8,
		textAlign: 'center',
		fontSize: 8,
		width: 80,
	},
	titleContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
		marginTop: 24,
		textAlign: 'center',
		marginBottom: 20,
	},
	reportTitle: {
		color: '#4943E4',
		fontSize: 24,
		textAlign: 'center',
	},
	subTitle: {
		color: '#EDBE2F',
		fontSize: 18,
		textAlign: 'center',
	},
});

export default GenerateSalesPDF;
