import React, { useState } from 'react';
import { ProjectCard } from '@components/macros';
import { SearchBar } from '@components';
import { useSocieties } from '@hooks';
import { Spin } from 'antd';
import { Empty } from 'antd';

function Projects() {
  const [searchValue, setSearchValue] = useState();
  const { societies, loading } = useSocieties(searchValue);

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  return (
    <div>
      <div className='flex-column gap-4 w-full'>
        <section className='bg-white container-padding'>
          <div className='flex justify-center items-center w-full'>
            <SearchBar
              placeholder='input search text'
              handleSearch={handleSearch}
            />
          </div>
        </section>
        <Spin spinning={loading}>
          {societies.length > 0 && (
            <div className='card-list-wrapper'>
              {societies.map((item, index) => (
                <ProjectCard {...item} key={index} />
              ))}
            </div>
          )}
          {societies.length == 0 && (
            <div className='flex items-center justify-center h-full'>
              <Empty />
            </div>
          )}
        </Spin>
      </div>
    </div>
  );
}

export default Projects;
