import React, { useState } from 'react';
import { Header } from 'antd/es/layout/layout';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../@store/auth/authActions';
import { LogoutOutlined, ProfileFilled } from '@ant-design/icons';
import ProfileModal from '../UserProfile/ProfileModal';
import { BsFillPersonFill } from 'react-icons/bs';

function NavBar() {
  const dispatch = useDispatch();
  const [profileVisible, setProfileVisible] = useState(false);
  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const handleProfileClick = () => {
    setProfileVisible(true);
  };

  const handleProfileModalClose = () => {
    setProfileVisible(false);
  };

  return (
    <>
      <Header
        style={{
          backgroundImage: 'linear-gradient(-200deg, white 50%, #4942E4 100%)',
        }}
        className='h-[50px] drop-shadow-sm p-5 font-mono flex items-center justify-between font-bold text-2xl w-full'
      >
        <div
          className='flex gap-0 items-center text-center w-full cursor-pointer'
          // onClick={() => (window.location.href = '/')}
        >
          <div className='flex flex-col p-0 m-0 ml-4 gap-0'>
            <div className='flex items-center p-0 m-0'>
              <p
                className='text-xl font-mono mb-4 m-0 p-0  text-primary'
                style={{
                  fontSize: '24px',
                }}
              >
                Usmania & Sons
              </p>
              <p
                style={{ fontSize: '12px' }}
                className='ml-1 ps-0 m-0 mb-4 text-black'
              >
                ©
              </p>
            </div>
            <div>
              <p
                style={{
                  fontSize: '13px',
                  position: 'absolute',
                  top: 18,
                  marginLeft: '4%',
                }}
                className='font-mono text-yellow-500'
              >
                Leading in Real Estate
              </p>
            </div>
          </div>
        </div>
        <div className='flex gap-5 absolute right-6'>
          <BsFillPersonFill
            className='mt-1 hover:text-[#4942E4] cursor-pointer w-5 text-white border-2'
            onClick={handleProfileClick}
          />
          <Button
            icon={<LogoutOutlined />}
            className='text-black hover:bg-yellow-500 h-8 hover:text-white bg-white'
            onClick={handleLogout}
            style={{}}
            type={'danger'}
          >
            Logout
          </Button>
        </div>
      </Header>
      <ProfileModal
        visible={profileVisible}
        setProfileVisible={setProfileVisible}
        onCancel={handleProfileModalClose}
      />
    </>
  );
}

export default NavBar;
