import { Form, Input, Button, Spin } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMultiplePhases } from '../../@store/phase/phaseActions'
import { useParams } from 'react-router-dom'

const MyForm = ({
  onNext,
  handlePrevious,
  current,
  phaseRef,
  setFormState,
}) => {
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const dispatch = useDispatch()
  const { phases, loading } = useSelector((state) => state.phase)
  const [currentPhases, setCurrentPhases] = useState()
  const touchedFields = []
  const { id } = useParams()

  const onAdd = () => {
    const formContainer = formRef.current
    formContainer.scrollTo({
      left: formContainer.scrollWidth + 20,
      behavior: 'smooth',
    })
  }

  const onFinish = (values) => {
    const finalFormData = values
    onNext('3')
    if (id) {
      const { phases } = finalFormData
      touchedFields.forEach((index) => {
        if (index >= 0 && index < phases.length) {
          const obj = phases[index]
          obj.edited = true
          console.log(obj)
        }
      })
    }
    setFormState((prev) => ({ ...prev, ...finalFormData }))
  }

  console.log(touchedFields)
  let defaultFields =
    phases && id
      ? phases
      : [
          {
            name: '',
            totalArea: '',
            cost: '',
            association: '',
            financer: '',
            legalAuthority: '',
          },
        ]
  useEffect(() => {
    if (current?.phases) {
      dispatch(getMultiplePhases(current.phases))
        .unwrap()
        .then((data) => {
          setCurrentPhases(data)
          defaultFields = data
          form.setFieldsValue({ phases: defaultFields })
        })
    }
  }, [current?.phases])

  if (loading && !currentPhases && defaultFields.length === 0) {
    return (
      <div className="flex items-center justify-center h-[70vh]">
        <Spin loading={true} />
      </div>
    )
  } else {
    return (
      <div className="w-full">
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className="w-full"
          ref={phaseRef}
          onFieldsChange={(changedFields) => {
            // --- track the form objects which are changed and
            // --- which are not.
            if (id) {
              let changed = changedFields.map((item) => item.name[1])
              if (
                !touchedFields.includes(changed[0]) &&
                changed[0] !== undefined
              ) {
                touchedFields.push([...changed])
              }
            }
          }}
        >
          <div className="flex justify-between">
            <Form.Item className="flex justify-end">
              <Button onClick={() => handlePrevious('1')} type="default">
                Previous
              </Button>
            </Form.Item>
            <Form.Item className="flex justify-end">
              <Button type="primary" htmlType="submit">
                Next
              </Button>
            </Form.Item>
          </div>
          <Form.List name="phases" initialValue={defaultFields}>
            {(fields, { add, remove }) => (
              <div>
                <div
                  ref={formRef}
                  className={`w-full flex flex-col gap-4 overflow-auto ${
                    fields.length > 1
                      ? 'items-start'
                      : 'items-center justify-center'
                  }`}
                >
                  {fields.map((field, index) => (
                    <div key={field.key} className="w-full">
                      <div className="flex items-center text-center justify-between">
                        <h3>Phase {index + 1}</h3>
                        {index === 0 ? null : (
                          <Button
                            type="default"
                            onClick={() => remove(field.name)}
                          >
                            Remove
                          </Button>
                        )}
                      </div>
                      <Form.Item
                        label="Phase Name"
                        name={[field.name, 'name']}
                        rules={[
                          {
                            required: true,
                            message: 'Please input phase name!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Total Area"
                        name={[field.name, 'totalArea']}
                        rules={[
                          {
                            required: true,
                            message: 'Please input total area!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Cost"
                        name={[field.name, 'cost']}
                        rules={[
                          { required: true, message: 'Please input cost!' },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Association"
                        name={[field.name, 'association']}
                        rules={[
                          {
                            required: true,
                            message: 'Please input association!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Financer"
                        name={[field.name, 'financer']}
                        rules={[
                          { required: true, message: 'Please input financer!' },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Legal Authority"
                        name={[field.name, 'legalAuthority']}
                        rules={[
                          {
                            required: true,
                            message: 'Please input legal authority!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  ))}
                </div>

                <div className="flex justify-between">
                  {!id && (
                    <Button
                      type="primary"
                      onClick={() => {
                        add()
                        onAdd()
                      }}
                      block
                      icon={<PlusOutlined />}
                      className="w-full"
                    >
                      Add Phase
                    </Button>
                  )}
                </div>
              </div>
            )}
          </Form.List>
        </Form>
      </div>
    )
  }
}

export default MyForm
