import React, { useState } from "react";
import Modal from "./Modal";
import { Button, Input, Form, Radio, Spin, InputNumber } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

function AddPhaseModal({
  openModal,
  handleClose,
  form,
  onFinishFailed,
  onFinish,
  isEdit,
}) {
  const { loading } = useSelector((state) => state.phase);
  const [marlaFlag, setMarlaFlag] = useState(false);
  return (
    <Modal open={openModal} handleClose={handleClose} className="w-[full]">
      <Spin spinning={loading}>
        <div className="w-[500px] p-2">
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            name="basic"
            className="w-full"
          >
            <Form.Item
              label="Phase Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input phase name!",
                },
              ]}
              className="w-full"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Total Area"
              name="totalArea"
              rules={[
                {
                  required: true,
                  message: "Please input total area!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <div className="flex justify-between">
              <Form.Item
                  label={"Area Unit"}
                  name="areaUnit"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
              >
                <Radio.Group
                    onChange={(e) => setMarlaFlag(e.target.value === "marla")}
                >
                  <Radio value="square feet">Square Feet</Radio>
                  <Radio value="marla">Marla</Radio>
                </Radio.Group>
              </Form.Item>
              {marlaFlag && (
                  <Form.Item label="Marla Size" name={"marlaSize"}>
                    <InputNumber defaultValue={272.25} />
                  </Form.Item>
              )}
            </div>

            <Form.Item
              label="Cost"
              name="cost"
              rules={[{ required: true, message: "Please input cost!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Association"
              name="association"
              rules={[
                {
                  required: true,
                  message: "Please input association!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Financer"
              name="financer"
              rules={[{ required: true, message: "Please input financer!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Legal Authority"
              name="legalAuthority"
              rules={[
                {
                  required: true,
                  message: "Please input legal authority!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <div className="flex justify-between">
              <Button
                block
                icon={isEdit ? <EditOutlined /> : <PlusOutlined />}
                className="w-full"
                loading={loading}
                type="primary"
                htmlType="submit"
              >
                {isEdit ? "Edit Phase" : "Add Phase"}
              </Button>
            </div>
          </Form>
        </div>
      </Spin>
    </Modal>
  );
}

export default AddPhaseModal;
