import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../@api/axios";
import { message } from "antd";

const createPhase = createAsyncThunk(
  "phases/add",
  async (phaseData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/phases", phaseData);
      message.success("Phases added Successfully");
      return response.data;
    } catch (err) {
      message.error("Society creation failed", err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchPhasesBySocietyId = createAsyncThunk(
  "societies/:id/phases",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/societies/${id}/phases`);
      return response.data;
    } catch (err) {
      message.error("Something went wrong");
      return rejectWithValue(err.response.data);
    }
  }
);

const updatePhase = createAsyncThunk(
  "update/phase",
  async (phaseData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/phases/${phaseData._id}`,
        phaseData
      );
      message.success("Phase updated successfully");
      return response.data;
    } catch (err) {
      message.error("Something went wrong");
      return rejectWithValue(err.response.data);
    }
  }
);

const deletePhase = createAsyncThunk(
  "delete/phase",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/phases/${id}`);
      message.success("Phase deleted successfully");
      return id;
    } catch (err) {
      message.error("Something went wrong");
      return rejectWithValue(err.response.data);
    }
  }
);

const getMultiplePhases = createAsyncThunk(
  "phases/getByIds",
  async (ids, { rejectWithValue }) => {
    try {
      const requests = ids.map((id) => axiosInstance.get(`/phases/${id}`));
      const responses = await Promise.all(requests);
      const data = responses.map((response) => response.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const updateMultiplePhases = createAsyncThunk(
  "phases/updateByIds",
  async (phases, { rejectWithValue }) => {
    console.log(phases);
    try {
      const requests = phases.map((phase) =>
        axiosInstance.put(`/phases/${phase._id}`, phase)
      );
      const responses = await Promise.all(requests);
      const data = responses.map((response) => response.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchPhaseById = createAsyncThunk(
  "phases/getById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/phases/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export {
  createPhase,
  fetchPhasesBySocietyId,
  deletePhase,
  updatePhase,
  getMultiplePhases,
  updateMultiplePhases,
  fetchPhaseById,
};
