import { Tabs } from 'antd';
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSocietyById } from '@store/society/societyActions';
import {
  SocietyDetail,
  Phase,
  Expenses,
  OfficeDetails,
} from '@components';

const { TabPane } = Tabs;

const SocietyManagement = () => {
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [formState, setFormState] = useState({});
  const [logo, setLogo] = useState([]);
  const [fileList, setFileList] = useState([]);
  const societyDetailRef = useRef(null);
  const phaseRef = useRef(null);
  const expenseRef = useRef(null);
  const officeDetailRef = useRef(null);
  const dispatch = useDispatch();
  const [current, setCurrrent] = useState();

  console.log('form state', formState);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getSocietyById(id))
        .unwrap()
        .then((data) => {
          setCurrrent(data);
          setFileList(data.images);
          setLogo([data.logo]);
        });
    } else {
      setCurrrent(null);
    }
  }, [id]);

  const handleNext = (tabKey) => {
    setActiveTabKey(tabKey);
  };
  const handlePrevious = (tabKey) => {
    setActiveTabKey(tabKey);
  };

  const resetAllForms = () => {
    societyDetailRef.current.resetFields();
    phaseRef.current.resetFields();
    expenseRef.current.resetFields();
    officeDetailRef.current.resetFields();
    setLogo([]);
    setFileList([]);
    setActiveTabKey('1');
  };
  console.log('formState', formState);
  return (
    <div className='bg-white container-padding h-full overflow-auto w-full'>
      <Tabs
        activeKey={activeTabKey}
        onChange={setActiveTabKey}
        className='w-full'
        tabBarGutter={50}
        centered
      >
        <TabPane
          tab='Society Detail'
          key='1'
          className='flex justify-center items-center'
          disabled
        >
          <div className='w-[750px]'>
            <SocietyDetail
              onNext={handleNext}
              formState={formState}
              handlePrevious={handlePrevious}
              setFormState={setFormState}
              societyDetailRef={societyDetailRef}
              logo={logo}
              setLogo={setLogo}
              fileList={fileList}
              setFileList={setFileList}
              current={current}
            />
          </div>
        </TabPane>
        <TabPane
          tab='Phases'
          key='2'
          disabled
          className='flex justify-center items-center'
        >
          <div className='w-[750px]'>
            <Phase
              onNext={handleNext}
              formState={formState}
              handlePrevious={handlePrevious}
              setFormState={setFormState}
              phaseRef={phaseRef}
              current={current}
            />
          </div>
        </TabPane>
        <TabPane
          tab='Expense detail'
          key='3'
          disabled
          className='flex justify-center items-center'
        >
          <div className='w-[750px]'>
            <Expenses
              onNext={handleNext}
              formState={formState}
              handlePrevious={handlePrevious}
              setFormState={setFormState}
              expenseRef={expenseRef}
              current={current}
            />
          </div>
        </TabPane>
        <TabPane
          tab='Office detail'
          key='4'
          disabled
          className='flex justify-center items-center'
        >
          <div className='w-[750px]'>
            <OfficeDetails
              onNext={handleNext}
              handlePrevious={handlePrevious}
              formState={formState}
              current={current}
              resetForm={() => {
                resetAllForms();
              }}
              officeDetailRef={officeDetailRef}
            />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default SocietyManagement;
