import React from 'react';

function BorderHeading({ heading }) {
  return (
    <div className='pb-3 w-full border-b-2 border-[#3F4254]'>
      <p className='text-[20px] font-semibold text-primary'>{heading}</p>
    </div>
  );
}

export default BorderHeading;
