import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import Highlighter from 'react-highlight-words';

const getColumnSearchProps = (
  dataIndex,
  searchText,
  searchedColumn,
  setSearchText,
  setSearchedColumn,
  searchInput,
  handleSearch,
  handleReset
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
  }) => (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        ref={searchInput}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space>
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{
            width: 90,
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            clearFilters && handleReset(clearFilters);
            setSearchText('');
            setSearchedColumn('');
          }}
          size='small'
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>
        <Button
          type='link'
          size='small'
          onClick={() => {
            close();
          }}
        >
          close
        </Button>
      </Space>
    </div>
  ),

  onFilter: (value, record) => {
    const keys = dataIndex.split('.');
    let data = record;
    for (let i = 0; i < keys.length; i++) {
      data = data[keys[i]] || '';
    }
    return data.toString().toLowerCase().includes(value.toLowerCase());
  },
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.current?.select(), 100);
    }
  },
  render: (text, record) => {
    const keys = dataIndex.split('.');
    let data = record;
    if (data) {
      for (let i = 0; i < keys.length; i++) {
        data = data[keys[i]] || '';
      }
    }
    return searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{
          backgroundColor: '#ffc069',
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={data ? data.toString() : ''}
      />
    ) : (
      data
    );
  },
});

export default getColumnSearchProps;
