import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form, Popconfirm, Tag, Table } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { BorderHeading } from '@components/macros';
import useBanks from '@hooks/useBanks';
import ExportableTableHOC from '@hoc/ExportTable';
import {
	createBank,
	deleteBank,
	fetchBanks,
	updateBank,
} from '@store/banks/bankActions';
import BankForm from '@components/Forms/BankForm';
import '../../antd.css';

const BankManagement = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [formValues, setFormValues] = useState({});
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [isEdit, setIsEdit] = useState(false);
	const [toEdit, setToEdit] = useState({});
	const { formedBanks } = useBanks('main');

	useEffect(() => {
		setLoading(true);
		// call GET plot by phase id API here with phaseId
		updateDataState();
	}, []);

	const handleClose = useCallback(() => {
		setModalVisible(false);
		setIsEdit(false);
		form.resetFields();
	}, [form]);

	const handleAddBank = () => {
		setFormValues({});
		setModalVisible(true);
	};

	const handleEditBank = (record) => {
		setModalVisible(true);
		setIsEdit(true);
		setToEdit(record);
		const { accountHolder, ...otherFields } = record;
		form.setFieldsValue({
			...otherFields,
			...accountHolder,
		});
	};

	const updateDataState = useCallback(() => {
		setLoading(true);
		dispatch(fetchBanks())
			.unwrap()
			.then((res) => {
				res.length > 0 && setData(res);
				setLoading(false);
			})
			.catch((error) => {
				// handle error
				setLoading(false);
			});
	}, [form]);

	const handleDeleteBank = async (record) => {
		console.log('delete clicked!');
		// call DELETE plot API here
		dispatch(deleteBank(record._id))
			.unwrap()
			.then(() => {
				updateDataState();
			})
			.catch((error) => {
				// handle error
				setLoading(false);
			});
	};

	const onFinish = (values) => {
		console.log('values', values);
		values.accountHolder = {
			firstName: values.firstName,
			lastName: values.lastName,
			phoneNumber: values.phoneNumber,
			email: values.email,
		};
		try {
			if (isEdit) {
				dispatch(updateBank({ ...values, _id: toEdit._id }))
					.unwrap()
					.then(() => {
						handleClose();
						updateDataState();
					});
			} else {
				dispatch(createBank({ ...values }))
					.unwrap()
					.then(() => {
						handleClose();
						updateDataState();
					});
			}
		} catch (e) {
			console.log('Error:', e);
			handleClose();
		}
	};

	const roleColors = {
		main: 'green',
		sub: 'pink',
	};

	const handleFinish = async (values) => {
		try {
			setLoading((prevIsLoading) => true);
			onFinish(values);
		} catch (error) {
		} finally {
			setLoading((prevIsLoading) => false);
		}
	};

	const columns = [
		{
			title: 'Account Number',
			dataIndex: 'accountNumber',
			key: 'accountNumber',
			align: 'center',
		},
		{
			title: 'Type',
			dataIndex: 'accountType',
			key: 'accountType',
			align: 'center',
		},
		{
			title: 'Bank Type',
			dataIndex: 'bankType',
			key: 'bankType',
			align: 'center',
			render: (bankType) => (
				<Tag color={roleColors[bankType]}>
					{bankType.substring(0, 1).toUpperCase() +
						bankType
							.substring(1)
							.replace(/([A-Z])/g, ' $1')
							.trim()}
				</Tag>
			),
		},
		{
			title: 'Bank Name',
			dataIndex: 'bankName',
			key: 'bankName',
			align: 'center',
		},
		{
			title: 'Branch',
			dataIndex: 'branch',
			key: 'branch',
			align: 'center',
		},
		{
			title: 'Balance',
			dataIndex: 'balance',
			key: 'balance',
			align: 'center',
		},
		{
			title: 'Account Holder',
			render: (text, record) => (
				<>
					{record.accountHolder.firstName} {record.accountHolder.lastName}
				</>
			),
			align: 'center',
		},
		{
			title: 'Action',
			key: 'action',
			render: (text, record) => (
				<>
					<EditOutlined
						className='cursor-pointer text-blue-600 text-base'
						onClick={() => handleEditBank(record)}
					/>
					<Popconfirm
						title='Are you sure to delete this plot?'
						onConfirm={() => handleDeleteBank(record)}
						okText='Yes'
						cancelText='No'
					>
						<DeleteOutlined className='cursor-pointer text-red-600 text-base ml-5' />
					</Popconfirm>
				</>
			),
			align: 'center',
		},
	];

	return (
		<div className='w-full'>
			<div className='flex justify-between items-center m-10 mb-6'>
				<BorderHeading heading='Banks' />
				<Button
					icon={<PlusOutlined />}
					className='shadow-sm'
					onClick={handleAddBank}
					type='primary'
				>
					Add Bank
				</Button>
			</div>
			<Table
				className='items-center justify-items-center ml-7 mr-7'
				id={'table-to-export'}
				loading={loading}
				size='small'
				bordered
				dataSource={data}
				columns={columns}
				rowClassName={(record, index) =>
					index % 2 === 0 ? 'even-row' : 'odd-row'
				}
				rowKey='id'
				pagination={{
					defaultPageSize: 10,
					pageSizeOptions: ['10', '20', '50', '100'],
					showSizeChanger: true,
				}}
				onChange={(__, sorter) => {
					setData((prevData) => {
						if (sorter.order) {
							return [...prevData].sort((a, b) =>
								sorter.order === 'ascend'
									? a[sorter.field] - b[sorter.field]
									: b[sorter.field] - a[sorter.field]
							);
						} else {
							return prevData;
						}
					});
				}}
			/>

			<BankForm
				openModal={modalVisible}
				handleClose={handleClose}
				form={form}
				onFinish={handleFinish}
				isEdit={isEdit}
				toEdit={toEdit}
			/>
		</div>
	);
};

export default ExportableTableHOC({
	TableComponent: BankManagement,
	FileName: 'banks',
});
