import React from 'react';
import SalesManagement from './SalesManagement';

export default function ConfirmSales() {
	return (
		<div className='container-padding'>
			<SalesManagement confirmSalesMode={true} />
		</div>
	);
}
