import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowRightOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Segmented, Table } from 'antd';
import {
	confirmSales,
	deleteSale,
	getAllSales,
} from '@store/sales/salesActions';
import SalesModal from '@components/Modals/SalesModal';
import './sales.css';
import moment from 'moment';
import SalesMetadata from '@utils/salesMetaData';
import { getStructuredRole } from '@utils/functions';
import ExportableTableHOC from '@hoc/ExportTable';
import {
	RangePicker,
	filterTypeMap,
	filterTypeShort,
	rangePresets,
} from './helper';
import { tableColumns } from './tableColumns';

const InstallmentTable = ({ data, setInstallmentsSelect }) => {
	const rowSelection = {
		onSelect: (record, selected, selectedRows) => {
			if (selectedRows.length > 0) {
				setInstallmentsSelect([...selectedRows]);
			} else {
				setInstallmentsSelect([]);
			}
		},
		onSelectAll: (selected, selectedRows, changeRows) => {
			if (selectedRows.length > 0) {
				setInstallmentsSelect([...selectedRows]);
			} else {
				setInstallmentsSelect();
			}
		},
	};

	const columns = [
		{
			title: 'Installment Number',
			dataIndex: 'installmentNumber',
			key: 'installmentNumber',
			align: 'center',
		},
		{
			title: 'Amount',
			dataIndex: 'paymentAmount',
			key: 'paymentAmount',
			align: 'center',
		},
		{
			title: 'Approved',
			dataIndex: 'isApproved',
			key: 'isApproved',
			align: 'center',
			render: (record) => <p>{record?.isApproved ? 'Yes' : 'No'}</p>,
		},
		{
			title: 'Payment Date',
			dataIndex: 'paymentDate',
			key: 'paymentDate',
			align: 'center',
			render: (paymentDate, record) => moment(paymentDate).format('YYYY-MM-DD'),
		},
	];

	return (
		<Table
			rowSelection={{
				...rowSelection,
			}}
			columns={columns}
			dataSource={data}
		/>
	);
};

function SalesManagement({ confirmSalesMode = false }) {
	const navigate = useNavigate();
	const [searchedColumn, setSearchedColumn] = useState('');
	const [searchText, setSearchText] = useState('');
	const [dateRange, setDateRange] = useState([]);
	const { entities, loading } = useSelector((state) => state.sale);
	const [filter, setFilter] = useState('Daily');
	const dispatch = useDispatch();
	const searchInput = useRef(null);
	const [showModal, setShowModal] = useState(false);
	const [editModalVisible, setEditModalVisible] = useState(false);
	const [selectedSale, setSelectedSale] = useState(null);
	const [isSelected, setIsSelected] = useState([]);
	const { entities: auth } = useSelector((state) => state.auth);
	const [installmentsSelect, setInstallmentsSelect] = useState([]);

	let total = 0;

	if (entities?.sales?.length > 0) {
		entities.sales.forEach((sale) => {
			total += sale?.salePrice;
		});
	}

	const handleShowModalOpen = (record) => {
		setShowModal(true);
		setSelectedSale(record);
	};

	const handleModalClose = () => {
		setShowModal(false);
		setEditModalVisible(false);
		setSelectedSale(null);
	};

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};
	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText('');
		setSearchedColumn('');
	};

	const handleDeleteSale = (record) => {
		dispatch(deleteSale(record._id))
			.unwrap()
			.then((res) => {
				dispatch(getAllSales());
			});
	};

	function handleFilterTypeChange(value) {
		setFilter(value);
		setDateRange([]); // Reset the date range
	}

	useEffect(() => {
		dispatch(getAllSales());
	}, [dispatch]);

	const handleEditSale = (saleId) => {
		const url = new URL(window.location.href);
		url.pathname = `${getStructuredRole(auth?.role)}/sales/create/`;
		url.searchParams.set('saleId', saleId);
		window.location.href = url.toString();
	};

	const rowSelection = {
		onSelect: (record, selected, selectedRows) => {
			if (selectedRows.length > 0) {
				setIsSelected([...selectedRows]);
			} else {
				setIsSelected();
			}
		},
		onSelectAll: (selected, selectedRows, changeRows) => {
			if (selectedRows.length > 0) {
				setIsSelected([...selectedRows]);
			} else {
				setIsSelected();
			}
		},
	};

	const handlePDFOpener = () => {
		const finalData = filterSales(entities, dateRange, filter);
		navigate('/super-admin/pdf-sales', { state: finalData });
	};

	const handleConfirmSale = () => {
		dispatch(
			confirmSales({
				sales: isSelected,
				installmentsSelect: installmentsSelect,
			})
		);
	};

	const handleCreateSale = () => {
		const r = `/${getStructuredRole(auth?.role)}/sales/create/`;
		window.location.replace(r);
	};

	useEffect(() => {}, [installmentsSelect]);

	const disabledDate = (current) => {
		// Disable dates that are more than 30 days in the past
		return (
			confirmSalesMode &&
			current &&
			current < moment().subtract(30, 'days').endOf('day')
		);
	};

	const filterSales = (entities, dateRange, filter) => {
		if (!entities || !entities.sales || entities.sales.length === 0) {
			return [];
		}

		return entities.sales.filter((sale) => {
			let saleDate = moment(sale?.createdAt).startOf('day');

			if (dateRange?.length > 0) {
				saleDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
				const startDate = dateRange[0]
					.startOf('day')
					.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
				const endDate = dateRange[1]
					.endOf('day')
					.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
				return saleDate.isBetween(startDate, endDate, 'day', '[]');
			}

			if (filter === 'All') return true;
			if (filter === 'Yearly') return saleDate.year() === moment().year();
			if (filter === 'Monthly') return saleDate.isSame(moment(), 'month');
			if (filter === 'Weekly') return saleDate.isSame(moment(), 'week');
			if (filter === 'Daily') return saleDate.isSame(moment(), 'day');

			return false; // Return false for unsupported filter types
		});
	};

	return (
		<>
			<div>
				<>
					<div className='container-padding h-full bg-white'>
						<div className='w-full flex-column gap-2'>
							<div className='w-full justify-between mx-auto'>
								{!confirmSalesMode && (
									<div>
										{entities?.sales?.length > 0 && entities?.metadata && (
											<SalesMetadata
												metadata={entities.metadata}
												total={total}
												handleFilterTypeChange={handleFilterTypeChange}
											/>
										)}
									</div>
								)}
							</div>
							<Table
								size='small'
								id={'table-to-export'}
								showHeader={true}
								loading={loading}
								filterType={filter}
								rowSelection={{ ...rowSelection }}
								rowKey={(record) => record?._id}
								expandable={{
									expandedRowRender: (record) => {
										const data = record?.installments?.map((item, index) => {
											return {
												key: index,
												_id: record._id,
												...item,
											};
										});
										return (
											<InstallmentTable
												setInstallmentsSelect={setInstallmentsSelect}
												installmentsSelect={installmentsSelect}
												setIsSelected={setIsSelected}
												data={data}
											/>
										);
									},
									rowExpandable: (record) => record?.installments?.length > 0,
								}}
								onChange={() => {
									console.log('here');
								}}
								title={() => (
									<div className={'flex justify-between'}>
										<div>
											<Segmented
												options={
													confirmSalesMode ? filterTypeShort : filterTypeMap
												}
												onChange={(value) => handleFilterTypeChange(value)}
												value={filter}
											/>
										</div>

										<div className='mr-40'>
											<RangePicker
												value={dateRange}
												allowEmpty
												presets={rangePresets}
												onChange={(dates) => {
													setFilter('All');
													setDateRange(dates);
												}}
												disabledDate={disabledDate}
											/>
										</div>

										{!confirmSalesMode && (
											<div>
												<Button
													onClick={() => handleCreateSale()}
													type='primary'
													icon={<ArrowRightOutlined />}
												>
													Add Sale
												</Button>
											</div>
										)}
										{confirmSalesMode && (
											<div>
												<Popconfirm
													placement='topLeft'
													description="This action can't be undone!"
													title='Confirm Sales?'
													disabled={
														(isSelected?.length === 0 ||
															isSelected === undefined) &&
														(installmentsSelect?.length === 0 ||
															installmentsSelect === undefined)
													}
													onConfirm={handleConfirmSale}
													okText='Yes'
													cancelText='No'
												>
													<Button
														disabled={
															(isSelected?.length === 0 ||
																isSelected === undefined) &&
															(installmentsSelect?.length === 0 ||
																installmentsSelect === undefined)
														}
														type='primary'
														icon={<CheckCircleOutlined />}
													>
														Confirm
													</Button>
												</Popconfirm>
											</div>
										)}
									</div>
								)}
								bordered
								dataSource={filterSales(entities, dateRange, filter)}
								columns={
									tableColumns({
										handleSearch,
										handleReset,
										setSearchedColumn,
										searchedColumn,
										searchInput,
										setSearchText,
										searchText,
										handleDeleteSale,
										handleEditSale,
										handleShowModalOpen,
										confirmSalesMode,
									}) || []
								}
								rowClassName={(record, index) =>
									index % 2 === 0 ? 'even-row' : 'odd-row'
								}
								pagination={{
									defaultPageSize: 30,
									pageSizeOptions: ['10', '20', '50', '100'],
									showSizeChanger: true,
								}}
							/>
							<div className={'flex gap-3 justify-end m-3'}>
								<Button
									onClick={handlePDFOpener}
									icon={<DownloadOutlined />}
									type={'default'}
									size='small'
								>
									Generate PDF
								</Button>
							</div>

							{showModal && selectedSale && (
								<SalesModal
									openModal={showModal}
									handleClose={handleModalClose}
									sale={selectedSale}
								/>
							)}
						</div>
					</div>
				</>
			</div>
		</>
	);
}

export default SalesManagement;
