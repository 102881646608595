import React from 'react';
import { Modal, Table } from 'antd';
import moment from 'moment';

const TableComponent = ({ openModal, sale: data, handleClose }) => {
  const columns = [
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
      className: 'font-bold',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Field',
      dataIndex: 'field2',
      key: 'field2',
      className: 'font-bold',
    },
    {
      title: 'Value',
      dataIndex: 'value2',
      key: 'value2',
    },
  ];

  const dataSource = [
    {
      field: 'Customer Name',
      value: data?.customer?.name || '---',
      field2: 'Cnic',
      value2: data?.customer?.cnic || '---',
    },
    {
      field: 'Phone',
      value: data?.customer?.contact?.phone || '---',
      field2: 'Sale Price',
      value2: data?.salePrice || '---',
    },
    {
      field: 'Society Name',
      value: data?.society.name || '---',
      field2: 'Plot Number',
      value2: data?.plot.number || '---',
    },
    {
      field: 'Payment Method',
      value: data?.paymentMethod.join(', ') || '---',
      field2: 'Phase',
      value2: data?.phase.name || '---',
    },
  ];

  if (data.paymentMethod.includes('installments')) {
    dataSource.push(
      {
        field: 'Number of Installments',
        value: data.installmentDetails?.numberOfInstallments || '---',
        field2: 'Installment Start date',
        value2: data.installmentDetails?.startDate
          ? moment(data.installmentDetails.startDate).format('YYYY-MM-DD')
          : '---',
      },
      {
        field: 'Installments end date',
        value: data.installmentDetails?.endDate
          ? moment(data.installmentDetails.endDate).format('YYYY-MM-DD')
          : '---',
        field2: '',
        value2: '',
      }
    );
  }

  if (data.paymentMethod === 'barter') {
    dataSource.push({
      field: 'Barter Details',
      value: data.barterDetails || '---',
    });
  }

  return (
    <Modal open={openModal} onCancel={handleClose} footer={null} width={800}>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        showHeader={false}
      />
    </Modal>
  );
};

export default TableComponent;
