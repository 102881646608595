import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getExpenseById } from "../@store/expense/expenseActions";

export function useExpenseById(expenseId) {
  const [expense, setExpense] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (expenseId) {
      setLoading(true);
      dispatch(getExpenseById(expenseId))
        .unwrap()
        .then((res) => {
          setExpense(res);
          setLoading(false);
        });
    } else {
      setExpense(null);
    }
  }, [expenseId]);

  return { expense, loading };
}
