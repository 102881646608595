import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from '../macros/Modal';
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Radio,
  Spin,
  Switch,
} from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';

const PlotForm = ({
  openModal,
  handleClose,
  form,
  onFinishFailed,
  onFinish,
  isEdit,
}) => {
  const { loading } = useSelector((state) => state.plot);
  const [marlaFlag, setMarlaFlag] = useState(false);
  return (
    <>
      <Modal open={openModal} handleClose={handleClose} className='w-[full]'>
        <Spin spinning={loading}>
          <div className='w-[500px] p-2'>
            <Form
              form={form}
              onFinish={onFinish}
              layout='vertical'
              name='basic'
              className='w-full'
            >
              <Form.Item
                label='Plot Number'
                name='number'
                rules={[
                  {
                    required: true,
                    message: 'Please input plot name!',
                  },
                ]}
                className='w-full'
              >
                <Input />
              </Form.Item>
              <Form.Item
                label='Area'
                name='area'
                rules={[
                  {
                    required: true,
                    message: 'Please input area!',
                  },
                ]}
              >
                <Input type='number' />
              </Form.Item>
              <div className='grid grid-cols-2'>
                <Form.Item
                  label={'Area Unit'}
                  name='areaUnit'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(e) => setMarlaFlag(e.target.value === 'marla')}
                  >
                    <Radio value='square feet'>Square Feet</Radio>
                    <Radio value='marla'>Marla</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label='Registered With Registrar?'
                  name='registeredWithRegistrar'
                  className='cursor-pointer'
                  rules={[{ required: true, message: 'Please Check' }]}
                >
                  <Switch />
                </Form.Item>
              </div>
              {marlaFlag && (
                <Form.Item label='Marla Size' name={'marlaSize'}>
                  <InputNumber defaultValue={272.25} />
                </Form.Item>
              )}
              <Form.Item
                label='Price'
                name='price'
                rules={[{ required: true, message: 'Please input price!' }]}
              >
                <Input type='number' />
              </Form.Item>

              <Form.Item
                label='Customer Payable'
                name='customerPayable'
                rules={[{ required: true, message: 'Please input payable!' }]}
              >
                <Input type='number' />
              </Form.Item>

              <div className='flex justify-between'>
                <Button
                  block
                  icon={isEdit ? <EditOutlined /> : <PlusOutlined />}
                  className='w-full'
                  loading={loading}
                  type='primary'
                  htmlType='submit'
                >
                  {isEdit ? 'Edit Plot' : 'Add Plot'}
                </Button>
              </div>
            </Form>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default PlotForm;
