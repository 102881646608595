import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../@api/axios';
import { message } from 'antd';

const signUp = createAsyncThunk(
  'users/create',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/signup', userData);
      message.success(response.data.message);
      return response.data;
    } catch (err) {
      message.error(err.message);
      return rejectWithValue(err.response.data);
    }
  }
);

const loginUser = createAsyncThunk(
  'users/login',
  async (userData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.post('/login', userData);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user[0]));
      message.success('Logged in Successfully 🎉');
      setTimeout(() => {
        if (response.data.role === 'superAdmin') {
          window.location.href = '/super-admin/workplace';
        }
        if (response.data.role === 'admin') {
          window.location.href = '/admin/workplace';
        }
        if (response.data.role === 'societyHead') {
          window.location.href = '/society-head/projects';
        }
        if (response.data.role === 'societyHeadStaff') {
          window.location.href = '/society-staff/projects';
        }
      }, 780);

      return response.data;
    } catch (err) {
      message.error(err.message);
      return rejectWithValue(err.response.data);
    }
  }
);
const logoutUser = createAsyncThunk('users/logout', async (_, { dispatch }) => {
  localStorage.removeItem('token');
  localStorage.removeItem('persist:root');
  window.location.href = '/login-signup';
});

const resetPassword = createAsyncThunk(
  'users/resetPassword',
  async (resetData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.post('/reset-password', resetData);
      message.success(response.data.message);
      return response.data;
    } catch (err) {
      message.error(err.message);
      return rejectWithValue(err.response.data);
    }
  }
);

const verifyToken = createAsyncThunk(
  'users/verifyToken',
  async (_, { rejectWithValue, dispatch }) => {
    const token = 'asasas';
    try {
      const response = await axiosInstance.get('/verify-token', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.message === 'Invalid token') {
        return false;
      }
      if (response.data.message === 'Token is valid') {
        return true;
      }
    } catch (err) {
      message.error(err.message);
      return rejectWithValue(err.response.data);
    }
  }
);

const updateUser = createAsyncThunk(
  'users/update',
  async (userData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.post(
        `/user/${userData._id}`,
        userData
      );
      message.success(response.data.message);
      return response.data.user;
    } catch (err) {
      message.error(err.message);
      return rejectWithValue(err.response.data);
    }
  }
);

// TODO: forgot password and reset password is remaining

export {
  signUp,
  loginUser,
  logoutUser,
  resetPassword,
  verifyToken,
  updateUser,
};
