import {Button, Table} from "antd";
import "../../antd.css";

function SalesOverviewTable({ onViewDetails, filterType, data }) {
    const columns = [
        {
            title: "Society",
            dataIndex: "societyName",
            key: "societyName",
            align: "center",
            render: (societyName) => <p>{societyName}</p>,
        },
        {
            title: "Plots",
            dataIndex: "plots",
            key: "plots",
            align: "center",
            render: (plots) => <p>{plots}</p>,
        },
        {
            title: "Phases",
            dataIndex: "phases",
            key: "phases",
            align: "center",
            render: (phases) => <p>{phases}</p>,
        },
        {
            title: "Total Debit",
            dataIndex: "totalDebit",
            key: "totalDebit",
            align: "center",
            render: (totalDebit) => <p>{totalDebit}</p>,
        },
        {
            title: "Total Credit",
            dataIndex: "totalCredit",
            key: "totalCredit",
            align: "center",
            render: (totalCredit) => <p>{totalCredit}</p>,
        },
        {
            title: "Actions",
            key: "actions",
            align: "center",
            render: (text, record) => (
                <div>
                    <Button
                        type="link"
                        onClick={() => {
                            onViewDetails(record);
                        }}
                    >
                        View Details
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <Table
            size="small"
            bordered
            rowClassName={(record, index) => (index % 2 === 0 ? 'even-row' : 'odd-row')}
            dataSource={data}
            columns={columns}
            pagination={{
                defaultPageSize: 10,
                pageSizeOptions: ["10", "20", "50", "100"],
                showSizeChanger: true,
            }}
        />
    );
}

export default SalesOverviewTable;
