import { Button, Form, message, Segmented } from 'antd';
import { UserModal } from '../../components';
import { UserTable } from '../../components/Tables';
import React, { useState, useCallback, useEffect } from 'react';
import {
	createUser,
	updateUser,
	getAllUsers,
} from '../../@store/users/userActions';
import { getRoles } from '../../utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import { useSocieties } from '../../hooks';
import ExportableTableHOC from '../../hoc/ExportTable';

const filterTypeMap = {
	'Super Admin': 'superAdmin',
	'Society Head': 'societyHead',
	'Society Staff': 'societyHeadStaff',
	Admin: 'admin',
	All: 'all',
};

function Users() {
	const [current, setCurrent] = useState(null);
	const [filterType, setFilterType] = useState('all');
	const [isEdit, setIsEdit] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const { entities } = useSelector((state) => state.auth);
	const { societies } = useSocieties('', false, true);
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	const onFinishFailed = (errorInfo) => {};

	const handleClose = useCallback(() => {
		setOpenModal(false);
		setIsEdit(false);
		form.resetFields();
	}, [form]);

	function handleFilterTypeChange(value) {
		setFilterType(filterTypeMap[value]);
	}

	const onFinish = (values) => {
		if (isEdit) {
			dispatch(
				updateUser({
					userId: current,
					userData: {
						...values,
						phone: '+92' + values.phone,
						societies: [values?.societies],
					},
				})
			)
				.unwrap()
				.then(() => {
					handleClose();
				})
				.catch((error) => {
					switch (error.status) {
						case 400:
							message.error(
								error.data ? error.data.message : 'Something went wrong'
							);
							break;
						default:
							message.error('Something went wrong');
							break;
					}
				});
		} else {
			dispatch(
				createUser({
					...values,
					societies: [values?.societies],
					phone: '+92' + values.phone,
				})
			)
				.unwrap()
				.then(() => {
					dispatch(getAllUsers());
					handleClose();
				})
				.catch((error) => {
					console.log(error);
					switch (error.status) {
						case 400:
							message.error(
								error.data ? error.data?.message : 'Something went wrong'
							);
							break;
						default:
							message.error('Something went wrong');
							break;
					}
				});
		}
	};

	const handleFinish = async (values) => {
		try {
			setIsLoading((prevIsLoading) => true);
			onFinish(values);
		} catch (error) {
		} finally {
			setIsLoading((prevIsLoading) => false);
		}
	};

	const onEditClick = useCallback(
		(record) => {
			if (record) {
				let filteredSocieties = [];
				if (record?.societies?.length > 0) {
					if (Array.isArray(record.societies)) {
						filteredSocieties = societies?.filter((user) =>
							record.societies.includes(user.value)
						);
					} else {
						filteredSocieties = societies?.filter(
							(user) => user.value === record.societies
						);
					}
				}
				setCurrent(record._id);
				setOpenModal(true);
				setIsEdit(true);
				form.setFieldsValue({
					_id: record._id,
					name: record.name,
					email: record.email,
					password: '',
					cnic: record.cnic,
					role: record.role,
					phone: record.phone.substring(3),
					societies: filteredSocieties || [],
				});
			}
		},
		[form]
	);

	return (
		<>
			<div className='container-padding h-full bg-white'>
				<div className='w--[50%] md:w-full flex-column gap-6'>
					<div className='flex flex-col gap-5 md:flex-row justify-between'>
						<Segmented
							options={[...getRoles(entities?.role)]}
							onChange={(value) => handleFilterTypeChange(value)}
							default='All'
						/>

						<Button
							onClick={() => {
								setOpenModal(true);
							}}
							className='w-[100px]'
							type='primary'
						>
							Add User
						</Button>
					</div>
					<UserTable onEditClick={onEditClick} filterType={filterType} />
					<UserModal
						openModal={openModal}
						handleClose={handleClose}
						onFinish={handleFinish}
						onFinishFailed={onFinishFailed}
						form={form}
						isLoading={isLoading}
						isEdit={isEdit}
					/>
				</div>
			</div>
		</>
	);
}

export default ExportableTableHOC({
	TableComponent: Users,
	FileName: 'users',
});
