function getFilteredRoles(userRole) {
  const roles = [
    { key: 'superAdmin', name: 'Super Admin' },
    { key: 'admin', name: 'Admin' },
    { key: 'societyHead', name: 'Society Head' },
    { key: 'societyHeadStaff', name: 'Society Head Staff' },
  ];
  switch (userRole) {
    case 'superAdmin':
      return roles.filter(({ key }) => key !== 'superAdmin');
    case 'admin':
      return roles.filter(({ key }) => key !== 'superAdmin' && key !== 'admin');
    case 'societyHead':
      return [{ key: 'societyHeadStaff', name: 'Society Head Staff' }];
    case 'societyHeadStaff':
      return [];
    default:
      return [];
  }
}

const getStructuredRole = (role) => {
  switch (role) {
    case 'superAdmin':
      return 'super-admin';
      break;
    case 'admin':
      return 'admin';
      break;
    case 'superAdmin':
      return 'super-admin';
      break;
    case 'societyHead':
      return 'society-head';
    case 'societyHeadStaff':
      return 'society-staff';

    default:
      break;
  }
};

function convertToTitleCase(inputStr) {
  let output = inputStr.replace(/([a-z])([A-Z])/g, '$1 $2');
  output = output.charAt(0).toUpperCase() + output.slice(1).toLowerCase();
  return output;
}

function capitalizeSentence(sentence) {
  try {
    let output = sentence.replace(/([a-z])([A-Z])/g, '$1 $2');
    output = output
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    return output;
  } catch (error) {
    return sentence;
  }
}

function formattedCapitalCase(role) {
  return role
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    .replace(/^[a-z]/i, function (match) {
      return match.toUpperCase();
    });
}

function getRoles(userRole) {
  const roles = [
    'All',
    'Super Admin',
    'Admin',
    'Society Head',
    'Society Staff',
  ];

  if (userRole === 'admin') {
    roles.splice(1, 1); // remove 'Super Admin' from the array
  }
  if (userRole === 'societyHead') {
    roles.splice(1, 3);
  }
  return roles;
}

function getGreeting() {
  const now = new Date();
  const hour = now.getHours();
  if (hour >= 12 && hour < 18) {
    return 'Good afternoon';
  } else if (hour >= 18 || hour < 6) {
    return 'Good evening';
  } else {
    return 'Good morning';
  }
}

function* plotNameGenerator(plotClass, i) {
  // Plot A-1
  const plotName = plotClass + '-' + i;
  yield plotName;
  i++;
}

function redirectToWorkplace(role) {
  switch (role) {
    case 'superAdmin':
      window.location.href = '/super-admin/workplace';
      break;
    case 'admin':
      window.location.href = '/admin/workplace';
      break;
    case 'societyHead':
      window.location.href = '/society-head/search-list';
      break;
    case 'societyHeadStaff':
      window.location.href = '/society-staff/projects';
      break;
    default:
      console.error(`Invalid role: ${role}`);
      break;
  }
}

function getTimeDifference(dateString) {
  const date = new Date(dateString);
  const timeDifference = Date.now() - date.getTime();
  const seconds = Math.floor(timeDifference / 1000);

  if (seconds < 60) {
    return 'Just now';
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  }

  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  }

  const days = Math.floor(hours / 24);
  return `${days} day${days > 1 ? 's' : ''} ago`;
}

function getPermission(currentUserRole, otherUserRole) {
  const roleOrder = {
    superAdmin: 0,
    admin: 1,
    societyHead: 2,
    societyHeadStaff: 3,
  };

  return roleOrder[otherUserRole] > roleOrder[currentUserRole];
}

function isObject(variable) {
  return typeof variable === 'object' && variable !== null;
}

function isArrayContainObject(arr) {
  if (!Array.isArray(arr)) {
    throw new Error('Input is not an array');
  }
  for (let i = 0; i < arr.length; i++) {
    if (
      typeof arr[i] === 'object' &&
      arr[i] !== null &&
      !Array.isArray(arr[i])
    ) {
      return true;
    }
  }

  return false;
}

const getKeyLength = (x) => {
  if (x !== undefined && x !== null) {
    return Object.keys(x).length;
  }
  return 0; // Return 0 if x is undefined or null
};

const ones = [
  'Zero',
  'One',
  'Two',
  'Three',
  'Four',
  'Five',
  'Six',
  'Seven',
  'Eight',
  'Nine',
];
const teens = [
  'Eleven',
  'Twelve',
  'Thirteen',
  'Fourteen',
  'Fifteen',
  'Sixteen',
  'Seventeen',
  'Eighteen',
  'Nineteen',
];
const tens = [
  '',
  'Ten',
  'Twenty',
  'Thirty',
  'Forty',
  'Fifty',
  'Sixty',
  'Seventy',
  'Eighty',
  'Ninety',
];

function convertToIndianWords(number) {
  if (number === 0) {
    return ones[0];
  } else if (number < 10) {
    return ones[number];
  } else if (number < 20) {
    return teens[number - 11];
  } else if (number < 100) {
    const tenDigit = Math.floor(number / 10);
    const oneDigit = number % 10;
    return tens[tenDigit] + (oneDigit !== 0 ? '-' + ones[oneDigit] : '');
  } else if (number < 1000) {
    const hundredDigit = Math.floor(number / 100);
    const remaining = number % 100;
    return (
      ones[hundredDigit] +
      ' Hundred' +
      (remaining !== 0 ? ' and ' + convertToIndianWords(remaining) : '')
    );
  } else if (number < 100000) {
    const thousandDigit = Math.floor(number / 1000);
    const remaining = number % 1000;
    return (
      convertToIndianWords(thousandDigit) +
      ' Thousand' +
      (remaining !== 0 ? ' ' + convertToIndianWords(remaining) : '')
    );
  } else if (number < 10000000) {
    const lakhDigit = Math.floor(number / 100000);
    const remaining = number % 100000;
    return (
      convertToIndianWords(lakhDigit) +
      ' Lakh' +
      (remaining !== 0 ? ' ' + convertToIndianWords(remaining) : '')
    );
  } else {
    const croreDigit = Math.floor(number / 10000000);
    const remaining = number % 10000000;
    return (
      convertToIndianWords(croreDigit) +
      ' Crore' +
      (remaining !== 0 ? ' ' + convertToIndianWords(remaining) : '')
    );
  }
}

function getPastDateRange(pastDays) {
  const currentDate = new Date();
  const endDate = new Date(currentDate);
  const startDate = new Date(currentDate);

  startDate.setDate(currentDate.getDate() - pastDays);

  // Format dates to yyyy-mm-dd format
  const formattedStartDate = startDate.toISOString().split('T')[0];
  const formattedEndDate = endDate.toISOString().split('T')[0];

  return {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
  };
}

export {
  convertToTitleCase,
  formattedCapitalCase,
  getFilteredRoles,
  getGreeting,
  getRoles,
  redirectToWorkplace,
  capitalizeSentence,
  getTimeDifference,
  plotNameGenerator,
  getPermission,
  isObject,
  getStructuredRole,
  isArrayContainObject,
  getKeyLength,
  convertToIndianWords,
  getPastDateRange,
};
