import { Login } from '@components/Auth';

const LoginSignUp = () => {
  return (
    <div className='relative'>
      <span className='absolute -left-30 -top-10 z-10'>
        {/* <CustomSVG /> */}
      </span>
      <div className='flex justify-center align-middle items-center h-screen'>
        <div className='flex w-[90%] md:w-[70%] xl:w-[40%] justify-center p-11  rounded-2xl shadow-lg'>
          <div className='flex flex-col gap-2'>
            <div className='flex gap-4 items-center text-center w-full'>
              <div className='flex-col p-0 m-0 ml-3'>
                <div className='flex items-center p-0 m-0'>
                  <p className='text-5xl m-0 p-0 font-bold font-mono text-[#4942E4]  drop-shadow-xl'>
                    Usmania & Sons
                  </p>
                  <p className='mt-3 ml-1 p-0 m-0 font-bold font-mono text-md text-[#4942E4]  drop-shadow-2xl'>
                    ©
                  </p>
                </div>
                <p className='font-mono text-yellow-500 text-t1 font-bold  drop-shadow-xl m-0 p-0 ml-28 text-xl'>
                  Leading in Real Estate
                </p>
              </div>
            </div>
            <div className='flex bg-transparent flex-col mt-20'>
              <Login />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSignUp;
