import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, InputNumber, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
	createExpense,
	updateExpense,
} from '../../@store/expense/expenseActions';
import { useExpenseById } from '../../hooks/useExpenseById';
import useOffice from '../../hooks/useOffice';
import { useSocieties } from '../../hooks';
import ImagesUpload from '../macros/ImageUpload';
import { getStructuredRole } from '../../utils/functions';

const { Option } = Select;

const ExpenseForm = () => {
	const [buttonLoading, setButtonLoading] = useState(false);
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	const url = new URL(window.location.href);
	const searchParams = new URLSearchParams(url.search);
	const expenseId = searchParams.get('expenseId');
	const { expense, loading: expenseLoading } = useExpenseById(expenseId);

	const [mode, setMode] = useState(expenseId ? 'edit' : 'create');
	const [files, setFiles] = useState();
	const [officeId, setOfficeId] = useState();
	const [societyId, setSocietyId] = useState();
	const { offices, loading: officeLoading, error: officeError } = useOffice();
	const { societies, loading: societyLoading } = useSocieties();
	const { entities: auth } = useSelector((state) => state.auth);

	useEffect(() => {
		if (expense) {
			const { name, description, type, amount, date, office, society, files } =
				expense;
			form.setFieldsValue({
				name,
				description,
				type,
				amount,
				date: moment(date),
				office: (office || []).map((officeItem) => ({
					key: officeItem._id,
					label: officeItem.name,
				})),
				society: (society || []).map((societyItem) => ({
					key: societyItem._id,
					label: societyItem.name,
				})),
				files,
			});
			setOfficeId(expense.office?._id);
			setFiles(expense.files);
			setSocietyId(expense?.society[0]);
		}
	}, [expenseId, expense]);

	const handleSocietyChange = (value) => {
		setSocietyId(value);
	};

	const handleOfficeChange = (value) => {
		setOfficeId(value);
	};

	const handleNavigateToExpenses = () => {
		const r = `/${getStructuredRole(auth?.role)}/expenses`;
		window.location.replace(r);
	};

	const onFinish = async (data) => {
		try {
			setButtonLoading(true);

			data = {
				...data,
				files: files || [],
			};

			if (expenseId) {
				dispatch(updateExpense({ expenseId, expense: data }))
					.unwrap()
					.then(() => {
						setButtonLoading(false);
						form.resetFields();
					})
					.catch((error) => {
						console.log('updateExpense onFinish error:', error);
						setButtonLoading(false);
					});
			} else {
				data = {
					...data,
					files: files || [],
				};

				dispatch(
					createExpense({ ...data, society: societyId, office: officeId })
				)
					.unwrap()
					.then(() => {
						setButtonLoading(false);
						setTimeout(() => {
							handleNavigateToExpenses();
						}, 2000);

						form.resetFields();
					})
					.catch((error) => {
						console.log('createExpense onFinish error:', error);
						setButtonLoading(false);
					});
			}
		} catch (error) {
			console.error('Error:', error);
			setButtonLoading(false);
		}
	};

	return (
		<div className='w-[80%]'>
			<Form
				name='my-form'
				onFinish={onFinish}
				layout='vertical'
				className='w-full'
				form={form}
			>
				<Form.Item className='justify-end flex'>
					<Button
						type='primary'
						htmlType='submit'
						icon={<PlusOutlined />}
						loading={buttonLoading}
					>
						{mode === 'create' ? 'Create Expense' : 'Save'}
					</Button>
				</Form.Item>
				<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full'>
					<Form.Item
						name='name'
						label='Name'
						rules={[{ required: true, message: 'Please enter a name' }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name='type'
						label='Type'
						rules={[{ required: true, message: 'Please enter a type' }]}
					>
						<Select>
							<Option value='maintenance'>Maintenance</Option>
							<Option value='development'>Development</Option>
							<Option value='salary'>Salary</Option>
							<Option value='tax'>Tax</Option>
							<Option value='utilityBill'>Utility Bill</Option>
							<Option value='miscellaneous'>Miscellaneous</Option>
							<Option value='rent'>Rent</Option>
							<Option value='construction'>Construction</Option>
							<Option value='repair'>Repair</Option>
							<Option value='legal'>Legal</Option>
							<Option value='marketing'>Marketing</Option>
							<Option value='insurance'>Insurance</Option>
							<Option value='interest'>Interest</Option>
							<Option value='depreciation'>Depreciation</Option>
							<Option value='debt'>Debt</Option>
							<Option value='other'>Other</Option>
						</Select>
					</Form.Item>
					<Form.Item name='description' label='Description'>
						<Input.TextArea />
					</Form.Item>
					<Form.Item
						name='amount'
						label='Amount'
						rules={[{ required: true, message: 'Please enter an amount' }]}
					>
						<InputNumber />
					</Form.Item>
					<Form.Item
						name='date'
						label='Date'
						rules={[{ required: true, message: 'Please enter a date' }]}
					>
						<DatePicker format='YYYY-MM-DD' />
					</Form.Item>
					<Form.Item name='office' label='Office'>
						<Select
							showSearch
							placeholder='Select Office'
							optionFilterProp='children'
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={handleOfficeChange}
						>
							{offices &&
								offices?.length > 0 &&
								offices.map((office) => (
									<Option key={office._id} value={office._id}>
										{office.name}
									</Option>
								))}
						</Select>
					</Form.Item>
					<Form.Item name='society' label='Society' required={true}>
						<Select
							showSearch
							placeholder='Select society'
							optionFilterProp='children'
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={handleSocietyChange}
						>
							{societies &&
								societies?.length > 0 &&
								societies.map((society) => (
									<Option key={society._id} value={society._id}>
										{society.name}
									</Option>
								))}
						</Select>
					</Form.Item>

					<Form.Item name='files' label='Attach files'>
						<ImagesUpload
							fileList={files}
							className='w-full'
							setFileList={setFiles}
							acceptedTypes='.png,.jpg,.jpeg,.pdf,.doc,.docx'
							maxSize={5}
							maxFiles={5}
							form={form}
							disablePreview={true}
							label='Attachments'
						/>
					</Form.Item>
				</div>
			</Form>
		</div>
	);
};

export default ExpenseForm;
