import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomers } from "../@store/customer/customerActions";

const useCustomers = (filter = {}) => {
  const dispatch = useDispatch();
  const { customers, loading, error } = useSelector((state) => state.customer);
  const [filteredCustomers, setFilteredCustomers] = useState([]);


  useEffect(() => {
    dispatch(getAllCustomers());
  }, [dispatch]);

  useEffect(() => {
    if (
      Object.keys(filter).length > 0 &&
      Array.isArray(customers) &&
      customers.length > 0
    ) {
      const filteredData = customers.filter((customer) => {
        let match = true;
        if (
          filter.name &&
          !customer.name.toLowerCase().includes(filter.name.toLowerCase())
        ) {
          match = false;
        }
        if (
          filter.cnic &&
          !customer.cnic.toLowerCase().includes(filter.cnic.toLowerCase())
        ) {
          match = false;
        }
        return match;
      });
      setFilteredCustomers(filteredData);
    } else {
      setFilteredCustomers(customers);
    }
  }, [customers, filter]);

  return { customers: filteredCustomers, loading, error };
};

export { useCustomers };
