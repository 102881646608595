import { Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BorderHeading } from '@components/macros';
import SalesOverviewTable from '@components/Tables/salesOverviewTable';
import {
  createSale,
  getAllSales,
  updateSale,
} from '@store/sales/salesActions';

const filterTypeMap = {
  'Super Admin': 'superAdmin',
  'Society Head': 'societyHead',
  'Society Staff': 'societyHeadStaff',
  Admin: 'admin',
  All: 'all',
};

function SalesOverview() {
  const [current, setCurrent] = useState(null);
  const [filterType, setFilterType] = useState('all');
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { entities } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const nav = useNavigate();

  const handleClose = useCallback(() => {
    setOpenModal(false);
    setIsEdit(false);
    form.resetFields();
  }, [form]);

  const handleFilterTypeChange = (value) => {
    setFilterType(filterTypeMap[value]);
  };

  const onFinish = (values) => {
    if (isEdit) {
      dispatch(
        updateSale({
          ...values,
          _id: current,
        })
      )
        .unwrap()
        .then(() => {
          dispatch(getAllSales());
          handleClose();
        });
    } else {
      dispatch(
        createSale({
          ...values,
        })
      )
        .unwrap()
        .then(() => {
          dispatch(getAllSales());
          handleClose();
        });
    }
  };

  const handleFinish = async (values) => {
    try {
      setIsLoading(true);
      onFinish(values);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const onViewDetails = () => {
    if (entities?.role === 'superAdmin') {
      nav('/super-admin/societies/phases/645f88a51ffa4ed89b11430e');
    }
    if (entities?.role === 'admin') {
      nav('/admin/societies/phases/645f88a51ffa4ed89b11430e');
    }
  };

  // Dummy data for the sales table
  const salesData = [
    {
      societyName: 'Society A',
      plots: 10,
      phases: 5,
      totalDebit: 500000,
      totalCredit: 400000,
    },
    {
      societyName: 'Society B',
      plots: 20,
      phases: 3,
      totalDebit: 700000,
      totalCredit: 600000,
    },
    {
      societyName: 'Society C',
      plots: 15,
      phases: 4,
      totalDebit: 800000,
      totalCredit: 750000,
    },
    {
      societyName: 'Society D',
      plots: 20,
      phases: 3,
      totalDebit: 700000,
      totalCredit: 600000,
    },
  ];

  return (
    <>
      <div className='container-padding h-full bg-white'>
        <div className='w-[50%] md:w-full flex-column gap-6'>
          <div className='flex flex-col gap-5 md:flex-row justify-between ml-4 mr-4 mt-4'>
            <BorderHeading heading='Sales' />
          </div>
          <SalesOverviewTable
            onViewDetails={onViewDetails}
            filterType={filterType}
            data={salesData}
          />
        </div>
      </div>
    </>
  );
}

export default SalesOverview;
