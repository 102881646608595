export const validatePhone = (rule, value, callback) => {
	// Use a regular expression to check if the phone number is valid.
	const phonePattern = /^[\d-]+$/;
	if (!phonePattern.test(value)) {
		callback(
			'Please enter a valid contact number.'
		);
	} else {
		callback();
	}
};
