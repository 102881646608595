import { FiEdit } from 'react-icons/fi';
import {
  AiOutlineAccountBook,
  AiOutlineApartment,
  AiOutlineCheckCircle,
  AiOutlineDollarCircle,
  AiOutlineTable,
  AiOutlineUser,
  AiOutlineVideoCameraAdd,
} from 'react-icons/ai';
import { BsBarChart, BsPerson } from 'react-icons/bs';

const SUPER_ADMIN = [
  {
    key: '1',
    icon: <FiEdit />,
    label: 'Super Admin',
    children: [
      {
        key: '01',
        label: 'Work Place',
        path: '/super-admin/workplace',
      },
      // {
      //   key: '02',
      //   label: 'Configuration',
      //   path: '/configuration',
      // },
      // {
      //   key: '03',
      //   label: 'Search Users',
      //   path: '/super-admin/search-users',
      // },
    ],
  },
  {
    key: '13',
    icon: <AiOutlineUser />,
    label: 'Users',
    path: '/super-admin/users',
  },
  {
    key: '12',
    icon: <AiOutlineApartment />,
    label: 'Projects',
    path: '/super-admin/projects',
  },
  {
    key: '2',
    icon: <AiOutlineTable />,
    label: 'Societies',
    children: [
      {
        key: '03',
        label: 'New Society',
        path: '/super-admin/society/create',
      },
      {
        key: '04',
        label: 'All Societies',
        path: '/super-admin/societies/search-list',
      },
    ],
  },
  {
    key: '3',
    icon: <AiOutlineCheckCircle />,
    label: 'Banks',
    path: '/super-admin/banks',
  },
  {
    key: '5',
    icon: <BsPerson />,
    label: 'Customers',
    children: [
      {
        key: '09',
        label: 'All Customers',
        path: '/super-admin/customers',
      },
    ],
  },
  {
    key: '51',
    icon: <AiOutlineDollarCircle />,
    label: 'Sales',
    children: [
      {
        key: '15',
        icon: <BsBarChart />,
        label: 'All Sales',
        path: '/super-admin/sales',
      },
      {
        key: '19',
        icon: <BsBarChart />,
        label: 'New Sale',
        path: '/super-admin/sales/create',
      },
      {
        key: '20',
        icon: <BsBarChart />,
        label: 'Confirm Sales',
        path: '/super-admin/sales/confirm',
      },
    ],
  },
  {
    key: '22',
    icon: <AiOutlineAccountBook />,
    label: 'Expenses',
    children: [
      {
        key: '06',
        label: 'All Expenses',
        path: '/super-admin/expenses',
      },
      {
        key: '07',
        label: 'Create Expenses',
        path: '/super-admin/expenses/create',
      },
      {
        key: '08',
        label: 'Confirm Expenses',
        path: '/super-admin/expenses/confirm',
      },
    ],
  },
  {
    key: '11',
    icon: <AiOutlineVideoCameraAdd />,
    label: 'Tutorial',
    path: '/super-admin/tutorials',
  },
  {
    key: '20',
    icon: <AiOutlineAccountBook />,
    label: 'Plots',
    children: [
      {
        key: '30',
        label: 'All Plots',
        path: '/super-admin/plots',
      },
    ],
  },
  {
    key: '14',
    icon: <AiOutlineAccountBook />,
    label: 'Agents',
    children: [
      {
        key: '09',
        label: 'All Agents',
        path: '/super-admin/agent',
      },
    ],
  },
];

const ADMIN = [
  {
    key: '1',
    icon: <FiEdit />,
    label: 'Admin',
    children: [
      {
        key: '01',
        label: 'Work Place',
        path: '/admin/workplace',
      },
    ],
  },
  {
    key: '13',
    icon: <AiOutlineUser />,
    label: 'Users',
    path: '/admin/users',
  },
  // {
  //   key: '12',
  //   icon: <AiOutlineTable />,
  //   label: 'Projects',
  //   path: '/admin/projects',
  // },
  // {
  //   key: '2',
  //   icon: <AiOutlineTable />,
  //   label: 'Societies',
  //   children: [
  //     {
  //       key: '03',
  //       label: 'New Society',
  //       path: '/admin/society/create',
  //     },
  //     {
  //       key: '04',
  //       label: 'All Societies',
  //       path: '/admin/societies/search-list',
  //     },
  //   ],
  // },
  {
    key: '2',
    icon: <AiOutlineTable />,
    label: 'Societies',
    children: [
      {
        key: '03',
        label: 'New Society',
        path: '/admin/society/create',
      },
      {
        key: '04',
        label: 'All Societies',
        path: '/admin/societies/search-list',
      },
    ],
  },
  {
    key: '3',
    icon: <AiOutlineCheckCircle />,
    label: 'Banks',
    path: '/admin/banks',
  },
  // {
  //   key: '4',
  //   icon: <GiRoad />,
  //   label: 'Barter System',
  //   children: [
  //     {
  //       key: '07',
  //       label: 'Work Place',
  //       path: '/workplace-barter',
  //     },
  //     {
  //       key: '08',
  //       label: 'Configuration',
  //       path: '/configuration-barter',
  //     },
  //   ],
  // },
  {
    key: '5',
    icon: <BsPerson />,
    label: 'Customers',
    path: '/admin/customers',
  },
  {
    key: '10',
    icon: <BsPerson />,
    label: 'Sales',
    children: [
      {
        key: '15',
        icon: <BsBarChart />,
        label: 'All Sales',
        path: '/admin/sales',
      },
      {
        key: '19',
        icon: <BsBarChart />,
        label: 'New Sale',
        path: '/admin/sales/create',
      },
      {
        key: '20',
        icon: <BsBarChart />,
        label: 'Confirm Sales',
        path: '/admin/sales/confirm',
      },
    ],
  },
  {
    key: '11',
    icon: <AiOutlineVideoCameraAdd />,
    label: 'Tutorial',
    path: '/admin/tutorials',
  },
];

const SOCIETY_HEAD = [
  {
    key: '2',
    icon: <AiOutlineTable />,
    label: 'Projects',
    path: '/society-head/projects',
  },
  {
    key: '3',
    icon: <BsPerson />,
    label: 'Users',
    path: '/society-head/users',
  },
  {
    key: '4',
    icon: <AiOutlineTable />,
    label: 'Search List',
    path: '/society-head/search-list',
  },
  {
    key: '5',
    icon: <AiOutlineVideoCameraAdd />,
    label: 'Tutorial',
    path: '/society-head/tutorial',
  },
  {
    key: '6',
    icon: <BsPerson />,
    label: 'Sales',
    path: '/society-head/sales',
  },
  {
    key: '7',
    icon: <AiOutlineTable />,
    label: 'Banks',
    path: '/society-head/banks',
  },
  {
    key: '8',
    icon: <BsPerson />,
    label: 'Customers',
    path: '/society-head/customers',
  },
  {
    key: '9',
    icon: <AiOutlineAccountBook />,
    label: 'Expenses',
    path: '/society-head/expenses',
  },
];

const SOCIETY_STAFF = [
  {
    key: '2',
    icon: <AiOutlineTable />,
    label: 'Projects',
    path: '/society-staff/projects',
  },
  {
    key: '4',
    icon: <AiOutlineTable />,
    label: 'Search List',
    path: '/society-staff/search-list',
  },

  {
    key: '6',
    icon: <BsPerson />,
    label: 'Sales',
    path: '/society-staff/sales',
  },
  // {
  //   key: '7',
  //   icon: <AiOutlineTable />,
  //   label: 'Banks',
  //   path: '/society-staff/banks',
  // },
  {
    key: '8',
    icon: <BsPerson />,
    label: 'Customers',
    path: '/society-staff/customers',
  },
  {
    key: '5',
    icon: <AiOutlineVideoCameraAdd />,
    label: 'Tutorial',
    path: '/society-staff/tutorial',
  },
  {
    key: '6',
    icon: <AiOutlineAccountBook />,
    label: 'Expenses',
    path: '/society-staff/expenses',
  },
];

export { SUPER_ADMIN, ADMIN, SOCIETY_HEAD, SOCIETY_STAFF };
