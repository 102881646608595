import { createSlice } from '@reduxjs/toolkit';
import {
  createUser,
  updateUser,
  deleteUser,
  getAllUsers,
  blockUser,
  createAgent,
  getAgents,
  updateAgent,
  deleteAgent,
} from './userActions';

const userSlice = createSlice({
  name: 'users',
  initialState: {
    entities: [],
    loading: false,
    agents: [],
    agentLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAllUsers.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.entities.findIndex(
          (e) => e._id === action.payload.id
        );
        if (index !== -1) {
          state.entities.splice(index, 1);
        }
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(blockUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(blockUser.fulfilled, (state, action) => {
        state.loading = false;
        const { user } = action.payload;
        const index = state.entities.findIndex((e) => e._id === user._id);
        if (index >= 0) {
          state.entities[index] = user;
        }
      })
      .addCase(blockUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createAgent.pending, (state, action) => {
        state.agentLoading = true;
      })
      .addCase(createAgent.fulfilled, (state, action) => {
        state.agentLoading = false;
        state.agents.unshift(action.payload.data);
      })
      .addCase(createAgent.rejected, (state, action) => {
        state.agentLoading = false;
      })
      .addCase(getAgents.pending, (state, action) => {
        state.agentLoading = true;
      })
      .addCase(getAgents.fulfilled, (state, action) => {
        state.agentLoading = false;
        state.agents = action.payload.data;
      })
      .addCase(getAgents.rejected, (state, action) => {
        state.agentLoading = false;
      })
      .addCase(updateAgent.pending, (state, action) => {
        state.agentLoading = true;
      })
      .addCase(updateAgent.fulfilled, (state, action) => {
        state.agentLoading = false;
        const id = action.payload?.data?._id;
        const agentIndex = state.agents.findIndex((agent) => agent?._id === id);
        if (agentIndex !== -1) {
          state.agents[agentIndex] = action.payload.data;
        }
      })
      .addCase(updateAgent.rejected, (state, action) => {
        state.agentLoading = false;
      })
      .addCase(deleteAgent.pending, (state, action) => {
        state.agentLoading = true;
      })
      .addCase(deleteAgent.fulfilled, (state, action) => {
        state.agentLoading = false;
        const idToDelete = action.payload;
        state.agents = state.agents.filter(
          (agent) => agent?._id !== idToDelete
        );
      })
      .addCase(deleteAgent.rejected, (state, action) => {
        state.agentLoading = false;
      });
  },
});

export default userSlice.reducer;
