import React from 'react';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import getColumnSearchProps from '@components/Tables/searchFunction';
import { Popconfirm } from 'antd';
import { daysPresets, rangePresets } from './helper';

const paymentMethodNames = {
	net_payment: 'Cheque/Cash',
	installments: 'Installment',
	barter: 'Barter System',
};

export const tableColumns = ({
	handleSearch,
	handleReset,
	setSearchedColumn,
	searchedColumn,
	setSearchText,
	searchInput,
	searchText,
	handleShowModalOpen,
	handleEditSale,
	handleDeleteSale,
	confirmSalesMode,
}) => {
	const actions = {
		title: 'Action',
		key: 'action',
		align: 'center',
		render: (text, record) => (
			<div className='flex gap-3 justify-between '>
				<EyeOutlined
					className='text-green-600 text-base'
					onClick={() => handleShowModalOpen(record)}
				/>
				<EditOutlined
					className='text-blue-600 text-base'
					onClick={() => handleEditSale(record._id)}
				/>
				<Popconfirm
					placement='topLeft'
					title='Are you sure you want to delete this item?'
					onConfirm={() => {
						handleDeleteSale(record);
					}}
					okText='Yes'
					cancelText='No'
				>
					<DeleteOutlined className='text-red-600 text-base' />
				</Popconfirm>
			</div>
		),
	};

	const columns = [
		{
			title: 'CNIC',
			dataIndex: ['customer', 'cnic'],
			key: 'customer.cnic',
			align: 'center',
			render: (_, record) => {
				return <p>{record?.customer?.cnic || ''}</p>;
			},
			...getColumnSearchProps(
				'customer.cnic',
				searchText,
				searchedColumn,
				setSearchText,
				setSearchedColumn,
				searchInput,
				handleSearch,
				handleReset
			),
		},
		{
			title: 'Payment Method',
			dataIndex: 'paymentMethod',
			key: 'paymentMethod',
			align: 'center',
			...getColumnSearchProps(
				'paymentMethod',
				searchText,
				searchedColumn,
				setSearchText,
				setSearchedColumn,
				searchInput,
				handleSearch,
				handleReset
			),
			render: (paymentMethod) => {
				const paymentMethods = paymentMethod?.map(
					(method) => paymentMethodNames[method]
				);
				return paymentMethods?.join(', ');
			},
		},
		{
			title: 'Approved',
			dataIndex: 'approved',
			key: 'approved',
			align: 'center',
			render: (approved) => {
				return approved ? 'Yes' : 'No';
			},
			filters: [
				{ text: 'Yes', value: true },
				{ text: 'No', value: false },
			],
			onFilter: (value, record) => record.approved === value,
		},
		{
			title: 'Customer',
			dataIndex: ['customer', 'name'],
			key: 'customer.name',
			render: (_, record) => {
				return <p>{record?.customer?.name || ''}</p>;
			},
			align: 'center',
			...getColumnSearchProps(
				'customer.name',
				searchText,
				searchedColumn,
				setSearchText,
				setSearchedColumn,
				searchInput,
				handleSearch,
				handleReset
			),
		},
		{
			title: 'Plot',
			dataIndex: ['plot', 'number'],
			key: 'plot.number',
			align: 'center',
			...getColumnSearchProps(
				'plot.number',
				searchText,
				searchedColumn,
				setSearchText,
				setSearchedColumn,
				searchInput,
				handleSearch,
				handleReset
			),
		},
		{
			title: 'Phase',
			dataIndex: ['phase', 'name'],
			key: 'phase.name',
			align: 'center',
			...getColumnSearchProps(
				'phase.name',
				searchText,
				searchedColumn,
				setSearchText,
				setSearchedColumn,
				searchInput,
				handleSearch,
				handleReset
			),
		},
		{
			title: 'Society',
			dataIndex: ['society', 'name'],
			key: 'society.name',
			align: 'center',
			...getColumnSearchProps(
				'society.name',
				searchText,
				searchedColumn,
				setSearchText,
				setSearchedColumn,
				searchInput,
				handleSearch,
				handleReset
			),
		},
		{
			title: 'Sale Price',
			dataIndex: 'salePrice',
			key: 'salePrice',
			align: 'center',
		},
		{
			title: 'Sale Date',
			dataIndex: 'createdAt',
			key: 'saleDate',
			align: 'center',
			render: (text) => {
				const saleDate = new Date(text); // Convert the date string to a Date object
				// Format the date as DD/MM/YYYY
				return saleDate.toLocaleDateString('en-GB');
			},
		},
	];

	let updatedColumns = [...columns];

	if (!confirmSalesMode) {
		updatedColumns = [...columns, actions];
		rangePresets.push(daysPresets);
	}

	return updatedColumns;
};
