import {
	deleteUser,
	getAllUsers,
	blockUser,
} from '../../@store/users/userActions';
import {
	deleteCustomer,
	getAllCustomers,
} from '../../@store/customer/customerActions';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Table, Tag, Popconfirm, Switch, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import getColumnSearchProps from './searchFunction';
import React, { useState, useRef, useEffect } from 'react';
import { capitalizeSentence } from '../../utils/functions';
import '../../antd.css';
import { useSocieties } from '../../hooks';

const roleColors = {
	superAdmin: 'magenta',
	admin: 'green',
	societyHead: 'cyan',
	societyHeadStaff: 'purple',
};

function CustomerTable({ onEditClick, filterType }) {
	const [data, setData] = useState(null);
	const { entities: auth } = useSelector((state) => state.auth);
	const { customers, loading } = useSelector((state) => state.customer);
	const dispatch = useDispatch();
	const searchInput = useRef(null);
	const [searchedColumn, setSearchedColumn] = useState('');
	const [searchText, setSearchText] = useState('');
	const { societies: allSocieties } = useSocieties('', false, true);
	const { societies } = useSocieties();

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};
	const handleReset = (clearFilters) => {
		clearFilters && clearFilters();
		setSearchText('');
		setSearchedColumn('');
	};

	const handleDeleteUser = ({ _id }) => {
		dispatch(deleteCustomer(_id))
			.unwrap()
			.then((res) => {
				dispatch(getAllCustomers());
			});
	};

	useEffect(() => {
		dispatch(getAllCustomers());
	}, [dispatch]);

	const handleSocietyChange = (value) => {
		const filteredCustomers = value
			? customers?.filter((customer) => customer?.societies?.includes(value))
			: customers;

		setData(filteredCustomers);
	};

	useEffect(() => {
		dispatch(getAllCustomers());
	}, [dispatch]);

	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			align: 'center',
			render: (value) => capitalizeSentence(value),
		},
		{
			title: 'Email',
			dataIndex: 'contact.email',
			key: 'contact.email',
			align: 'center',
			render: (contact) => <p>{contact.email}</p>,
			...getColumnSearchProps(
				'contact.email',
				searchText,
				searchedColumn,
				setSearchText,
				setSearchedColumn,
				searchInput,
				handleSearch,
				handleReset
			),
		},
		{
			title: 'CNIC',
			dataIndex: 'cnic',
			key: 'cnic',
			align: 'center',
			...getColumnSearchProps(
				'cnic',
				searchText,
				searchedColumn,
				setSearchText,
				setSearchedColumn,
				searchInput,
				handleSearch,
				handleReset
			),
		},
		{
			title: 'Phone',
			dataIndex: 'contact.phone',
			key: 'contact.phone',
			align: 'center',
			render: (contact) => <p>{contact.phone}</p>,
			...getColumnSearchProps(
				'contact.phone',
				searchText,
				searchedColumn,
				setSearchText,
				setSearchedColumn,
				searchInput,
				handleSearch,
				handleReset
			),
		},
		{
			title: 'Address',
			dataIndex: 'address',
			key: 'address',
			align: 'center',
			render: (address) => (
				<>
					<div>{address.street}</div>
					<div>
						{address.city}, {address.state}, {address.country} - {address.zip}
					</div>
				</>
			),
		},
		{
			title: 'Societies',
			dataIndex: 'societies',
			key: 'address',
			align: 'center',
			render: (societies) => {
				const filtered =
					(Array.isArray(allSocieties) &&
						societies?.length > 0 &&
						allSocieties?.filter((s) => societies.includes(s.value))) ||
					[];

				const societyNames = filtered
					?.map((society) => society.label)
					.join(', ');
				return <span>{societyNames}</span>;
			},
		},
		{
			title: 'Action',
			key: 'action',
			render: (text, record) => (
				<div className='flex gap-5'>
					<EditOutlined
						className='text-blue-600 text-base'
						onClick={() => onEditClick(record)}
					/>
					<Popconfirm
						placement='topLeft'
						title='Are you sure you want to delete this item?'
						onConfirm={() => {
							handleDeleteUser(record);
						}}
						okText='Yes'
						cancelText='No'
					>
						<DeleteOutlined className='text-red-600 text-base' />
					</Popconfirm>
				</div>
			),
		},
	];

	const { Option } = Select;

	return (
		<>
			<div className='flex-column gap-4'>
				<Select
					showSearch
					placeholder='Select society'
					optionFilterProp='children'
					className='w-[25%]'
					filterOption={(input, option) =>
						option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}
					onChange={handleSocietyChange}
				>
					{societies &&
						societies?.length > 0 &&
						societies.map((society) => (
							<Option key={society._id} value={society._id}>
								{society.name}
							</Option>
						))}
				</Select>
				<Table
					size='small'
					id={'table-to-export'}
					bordered
					loading={loading}
					dataSource={data || customers}
					columns={columns}
					rowClassName={(record, index) =>
						index % 2 === 0 ? 'even-row' : 'odd-row'
					}
					pagination={{
						defaultPageSize: 10,
						pageSizeOptions: ['10', '20', '50', '100'],
						showSizeChanger: true,
					}}
				/>
			</div>
		</>
	);
}

export default CustomerTable;
