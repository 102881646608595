import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Form, Popconfirm, Table } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import getColumnSearchProps from './searchFunction';
import { BorderHeading } from '../macros';
import { useDispatch } from 'react-redux';
import {
  createPlot,
  deletePlot,
  fetchPlotByPhaseId,
  updatePlot,
} from '../../@store/plots/plotActions';
import PlotForm from '../Forms/PlotForm';
import '../../antd.css';
import ExportableTableHOC from '../../hoc/ExportTable';

const PlotTable = ({ phaseId, disableCrud, externalPlots }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [formValues, setFormValues] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [toEdit, setToEdit] = useState({});
  const [columns, setColumns] = useState();
  const [filteredData, setFilteredData] = useState();

  const handleClose = useCallback(() => {
    setModalVisible(false);
    setIsEdit(false);
    form.resetFields();
  }, [form]);

  const handleAddPlot = () => {
    setFormValues({});
    setModalVisible(true);
  };

  const handleEditPlot = (record) => {
    console.log('edit clicked!');
    setModalVisible(true);
    setIsEdit(true);
    setToEdit(record);
    form.setFieldsValue({
      _id: record._id,
      number: record.number,
      area: record.area,
      price: record.price,
      customerPayable: record.customerPayable,
    });
  };

  const handleDeletePlot = async (record) => {
    setLoading(true);
    // call DELETE plot API here
    dispatch(deletePlot(record._id))
      .unwrap()
      .then(() => {
        dispatch(fetchPlotByPhaseId(phaseId))
          .unwrap()
          .then((res) => {
            if (res.plots.length > 0) {
              setData(res.plots);
            } else {
              setData([]);
            }
            setLoading(false);
          });
      })
      .catch((error) => {
        // handle error
        setLoading(false);
      });
  };

  useEffect(() => {
    if (phaseId) {
      setLoading(true);
      // call GET plot by phase id API here with phaseId
      dispatch(fetchPlotByPhaseId(phaseId))
        .unwrap()
        .then((res) => {
          if (res.plots.length > 0) {
            setData(res.plots);
          } else {
            setData([]);
          }
          setLoading(false);
        })
        .catch((error) => {
          // handle error
          setLoading(false);
        });
    } else {
      setData([]);
    }
  }, [phaseId]);

  const onFinish = (values) => {
    console.log('values', values);

    setLoading((prevIsLoading) => true);
    if (isEdit) {
      dispatch(updatePlot({ ...values, _id: toEdit._id }))
        .unwrap()
        .then((res) => {
          handleClose();
          dispatch(fetchPlotByPhaseId(phaseId))
            .unwrap()
            .then((res) => res.plots.length > 0 && setData(res.plots));
        });
    } else {
      dispatch(createPlot({ ...values, phase: phaseId }))
        .unwrap()
        .then(() => {
          handleClose();
          dispatch(fetchPlotByPhaseId(phaseId))
            .unwrap()
            .then((res) => res.plots.length > 0 && setData(res.plots));
        });
    }
    setLoading((prevIsLoading) => true);
  };

  const handleFinish = async (values) => {
    try {
      setLoading((prevIsLoading) => true);
      onFinish(values);
    } catch (error) {
    } finally {
      setLoading((prevIsLoading) => false);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters && clearFilters();
    setSearchText('');
    setSearchedColumn('');
  };

  const actions = {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <>
        <EditOutlined
          className='cursor-pointer text-blue-600 text-base'
          onClick={() => handleEditPlot(record)}
        />
        <Popconfirm
          title='Are you sure to delete this plot?'
          onConfirm={() => handleDeletePlot(record)}
          okText='Yes'
          cancelText='No'
        >
          <DeleteOutlined className='cursor-pointer text-red-600 text-base ml-5' />
        </Popconfirm>
      </>
    ),
    align: 'center',
  };

  const tableColumns = [
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
      align: 'center',
    },
    {
      title: 'Area',
      dataIndex: 'area',
      key: 'area',
      align: 'center',
    },
    {
      title: 'Area Unit',
      dataIndex: 'areaUnit',
      key: 'areaUnit',
      align: 'center',
    },
    {
      title: 'Sold',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      ...getColumnSearchProps(
        'status',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        handleSearch,
        handleReset
      ),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      align: 'center',
    },
    {
      title: 'Registered With Registrar',
      dataIndex: 'registeredWithRegistrar',
      key: 'registeredWithRegistrar',
      align: 'center',
    },
    {
      title: 'Customer Payable',
      dataIndex: 'customerPayable',
      key: 'customerPayable',
      align: 'center',
    },
  ];

  useEffect(() => {
    let updatedColumns = [...tableColumns]; // Create a copy of the columns array

    if (!disableCrud) {
      updatedColumns = [...tableColumns, actions]; // Add the actions column to the copy
    }

    setColumns(updatedColumns); // Update the columns state with the new copy
  }, [disableCrud]);

  return (
    <div className='w-full'>
      <div className='flex justify-between items-center mb-3 ml-5'>
        {/* <BorderHeading heading='Plots' /> */}
        {!disableCrud && (
          <Button
            icon={<PlusOutlined />}
            className='shadow-sm'
            onClick={handleAddPlot}
            type='primary'
          >
            Add Plot
          </Button>
        )}
      </div>
      <Table
        loading={loading}
        size='small'
        id={'table-to-export'}
        bordered
        dataSource={disableCrud ? externalPlots : data}
        columns={columns}
        rowClassName={(record, index) =>
          index % 2 === 0 ? 'even-row' : 'odd-row'
        }
        rowKey='id'
        pagination={{
          defaultPageSize: 10,
          pageSizeOptions: ['10', '20', '50', '100'],
          showSizeChanger: true,
        }}
        onChange={(__, sorter, extra) => {
          setData((prevData) => {
            if (sorter.order) {
              return [...prevData].sort((a, b) =>
                sorter.order === 'ascend'
                  ? a[sorter.field] - b[sorter.field]
                  : b[sorter.field] - a[sorter.field]
              );
            } else {
              return prevData;
            }
          });
        }}
      />

      <PlotForm
        openModal={modalVisible}
        handleClose={handleClose}
        form={form}
        onFinish={handleFinish}
        isEdit={isEdit}
      />
    </div>
  );
};

export default ExportableTableHOC({
  TableComponent: PlotTable,
  FileName: 'plots',
});
