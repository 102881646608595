import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../@api/axios";
import { message } from "antd";
import uploadFilesToS3 from "../../utils/s3FileUpload";
import { isObject } from "../../utils/functions";

const createExpense = createAsyncThunk(
  "expense/create",
  async (expenseData, { rejectWithValue }) => {
    try {
      let data = expenseData;
      let uploadedFiles;

      if (data.files.length > 0) {
        uploadedFiles = await uploadFilesToS3(expenseData.files);
        if (uploadedFiles.length > 0) {
          data = {
            ...expenseData,
            files: uploadedFiles,
          };
        }
      }

      const response = await axiosInstance.post("/expenses", data);
      message.success("Expense created Successfully");
      return response.data;
    } catch (err) {
      message.error(err.message);
      return rejectWithValue(err.response.data);
    }
  }
);

const confirmExpense = createAsyncThunk(
  "expense/confirm",
  async (expense, { rejectWithValue }) => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const response = await axiosInstance.patch(`/expenses/approve`, {
        expense: expense,
        user: user._id,
      });
      message.success("Expense Confirmed Successfully");
      return response.data;
    } catch (err) {
      message.error(err.message);
      return rejectWithValue(err.response.data);
    }
  }
);

const getAllExpenses = createAsyncThunk(
  "expense/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/expenses");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const getExpenseById = createAsyncThunk(
  "expense/getById",
  async (expenseId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/expenses/${expenseId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const updateExpense = createAsyncThunk(
  "expense/update",
  async ({ expenseId, expense }, { rejectWithValue }) => {
    try {
      let newUrls;
      let newData = expense;

      const newFiles = newData.files.filter((file) => typeof file !== "string");

      //--- upload files and logos to the s3
      if (newFiles.length >= 0) {
        newUrls = await Promise.all([uploadFilesToS3(newFiles)]);
      }

      // --- check if files are upload then add/merge with the prev files
      if (newUrls[0].length >= 0 && newFiles) {
        newData.files = newData.files.concat(newUrls[0]);
        newData.files = newData.files.filter(
          (item) => typeof item === "string"
        );
      }

      const updatedInfo = {
        ...newData,
        society: isObject(newData.society)
          ? newData.society.key
          : newData.society,
      };

      const response = await axiosInstance.put(
        `/expenses/${expenseId}`,
        updatedInfo
      );
      message.success("Expense updated Successfully");
      return response.data;
    } catch (err) {
      message.error(err.message);
      return rejectWithValue(err.response.data);
    }
  }
);

const deleteExpense = createAsyncThunk(
  "expense/delete",
  async (expenseId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/expenses/${expenseId}`);
      message.success("Expense deleted Successfully");
      return response.data;
    } catch (err) {
      message.error(err.message);
      return rejectWithValue(err.response.data);
    }
  }
);

export {
  createExpense,
  confirmExpense,
  getAllExpenses,
  getExpenseById,
  updateExpense,
  deleteExpense,
};
