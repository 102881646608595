import React from 'react'
import { Card } from 'antd'
import AvatarGroup from './avatarGroup'
import { useNavigate } from 'react-router-dom'
import { getTimeDifference } from '../../utils/functions'
import { useSelector } from 'react-redux'

function ProjectCard({ logo, name, description, _id, createdAt }) {
  const navigate = useNavigate()
   const { entities } = useSelector((state) => state.auth)

  const handleProfileNavigate = () => {
    if(entities?.role === "superAdmin"){
       navigate(`/super-admin/societies/profile/${_id}`)
    }
    if(entities?.role === "admin"){
      navigate(`/admin/societies/profile/${_id}`)
    }
  }
  return (
    <Card
      onClick={handleProfileNavigate}
      cover={<img className="h-[300px] bg-cover px-24" alt="example" src={logo} />}
      className="pb-4 cursor-pointer"
    >
      <h1>{name}</h1>
      {description?.length > 200 ? (
        <p>{`${description.slice(0, 100)}...`}</p>
      ) : (
        <p>{description}</p>
      )}
      <div className="row-flex justify-between">
        <p className="text-t1 mt-2">{getTimeDifference(createdAt)}</p>
        <AvatarGroup />
      </div>
    </Card>
  )
}

export default ProjectCard
