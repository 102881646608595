import { createSlice } from "@reduxjs/toolkit";
import {
  createPhase,
  deletePhase,
  fetchPhasesBySocietyId,
  updatePhase,
  getMultiplePhases, fetchPhaseById,
} from "./phaseActions";

const phaseSlice = createSlice({
  name: "phase",
  initialState: {
    phases: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPhase.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createPhase.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createPhase.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchPhasesBySocietyId.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPhasesBySocietyId.fulfilled, (state, action) => {
        state.loading = false;
        state.phases = action.payload;
      })
      .addCase(fetchPhasesBySocietyId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deletePhase.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePhase.fulfilled, (state, action) => {
        state.loading = false;
        state.phases = state.phases.filter(
          (phase) => phase._id !== action.payload
        );
      })
      .addCase(deletePhase.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updatePhase.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePhase.fulfilled, (state, action) => {
        state.loading = false;
        state.phases = state.phases.map((phase) =>
          phase._id === action.payload._id ? action.payload : phase
        );
      })
      .addCase(updatePhase.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getMultiplePhases.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMultiplePhases.fulfilled, (state, action) => {
        state.loading = false;
        state.phases = action.payload;
      })
      .addCase(getMultiplePhases.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchPhaseById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPhaseById.fulfilled, (state, action) => {
        state.loading = false;
        state.phases = action.payload;
      })
      .addCase(fetchPhaseById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default phaseSlice.reducer;
