import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import Input from 'antd/es/input/Input';
import { resetPassword } from '@store/auth/authActions';

function ResetPassword() {
  const { token } = useParams();
  const dispatch = useDispatch();
  const onFinish = (values) => {
    const data = {
      token: token,
      password: values.password,
    };
    dispatch(resetPassword(data));
  };
  return (
    <div className='flex items-center justify-center h-screen'>
      <div className='max-w-[400px] w-full flex-column gap-10'>
        <div className='flex gap-4 items-center text-center w-full'>
          <img alt='logo' src='/assets/svgs/logo.svg' />
          <p className='text-3xl m-0'>Reset your password</p>
        </div>
        <div>
          <Form
            name='normal_login'
            className='login-form'
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Please input your Password!',
                },
              ]}
              label='Enter new password'
            >
              <Input.Password
                prefix={<LockOutlined className='site-form-item-icon' />}
                type='password'
                placeholder='Password'
              />
            </Form.Item>

            <Form.Item>
              <Button
                // loading={isLoading}
                type='primary'
                htmlType='submit'
                className='bg-primary w-full'
                // className="login-form-button"
              >
                Reset Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
