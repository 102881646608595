import React from 'react';
import ExpenseForm from '@components/Forms/expenseForm';

const MyForm = () => {
  return (
    <div
      className='bg-white flex flex-col
      items-center container-padding h-full overflow-auto w-full'
    >
      <ExpenseForm />
    </div>
  );
};

export default MyForm;
