import React, { useState, useEffect } from 'react';
import { Button, Input, Form, Radio, Spin, Select } from 'antd';
import { useSelector } from 'react-redux';
import { getFilteredRoles } from '../../utils/functions';
import { Modal } from '../macros';
import { useSocieties } from '../../hooks';

function CustomerModal({
	openModal,
	handleClose,
	formValues,
	onFinishFailed,
	onFinish,
	isEdit,
}) {
	const { entities } = useSelector((state) => state.auth);
	const { loading } = useSelector((state) => state.customer);
	const [filteredRoles, setFilteredRoles] = useState(null);
	const { societies: allSocieties } = useSocieties('', false, true);

	useEffect(() => {
		setFilteredRoles(getFilteredRoles(entities.role));
	}, []);

	return (
		<Modal open={openModal} handleClose={handleClose} className='w-[500px]'>
			<Spin spinning={loading}>
				<div className='w-full bg-white'>
					<Form
						layout='vertical'
						name='basic'
						form={formValues}
						initialValues={{ remember: false }}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						className='w-full'
					>
						<Form.Item
							label='Name'
							name='name'
							rules={[{ required: true, message: 'Please input your name!' }]}
						>
							<Input />
						</Form.Item>

						<div className='grid grid-cols-2 gap-5'>
							<Form.Item
								label='Phone'
								name={['contact', 'phone']}
								rules={[
									{ required: true, message: 'Please enter your phone number' },
									{
										pattern: /^[0-9]{10}$/,
										message: 'Please enter a valid phone number (+92)',
									},
								]}
							>
								<Input addonBefore='+92' placeholder='3311345678' />
							</Form.Item>
							<Form.Item
								label='Email'
								name={['contact', 'email']}
								rules={[
									{
										type: 'email',
										required: true,
										message: 'Please input valid email!',
									},
								]}
							>
								<Input />
							</Form.Item>
						</div>

						<Form.Item
							label='CNIC'
							name='cnic'
							rules={[
								{ required: true, message: 'Please input CNIC!' },
								{
									pattern: /^\d{5}-\d{7}-\d{1}$/,
									message: 'Invalid CNIC format!',
								},
								{ len: 15, message: 'CNIC must be 15 characters!' },
							]}
						>
							<Input placeholder='23322-9511870-1' />
						</Form.Item>

						<Form.Item label='Address'>
							<div className='grid grid-cols-2 gap-5'>
								<Form.Item
									label='Street'
									name={['address', 'street']}
									rules={[
										{ required: true, message: 'Please input street address!' },
									]}
								>
									<Input />
								</Form.Item>
								<Form.Item
									label='City'
									name={['address', 'city']}
									rules={[{ required: true, message: 'Please input city!' }]}
								>
									<Input />
								</Form.Item>
							</div>
							<div className='grid grid-cols-2 gap-5'>
								<Form.Item
									label='State'
									name={['address', 'state']}
									rules={[{ required: true, message: 'Please input state!' }]}
								>
									<Input />
								</Form.Item>
								<Form.Item
									label='
                  Zip Code'
									name={['address', 'zip']}
									rules={[
										{ required: true, message: 'Please input zip code!' },
									]}
								>
									<Input />
								</Form.Item>
							</div>
						</Form.Item>
						<Form.Item
							label='Select Societies you want to add the customer'
							name={['societies']}
							style={{ width: '100%' }}
							rules={[
								{
									required: true,
									message: 'Please select a societies!',
								},
							]}
						>
							<Select
								getPopupContainer={(triggerNode) => {
									return triggerNode.parentNode;
								}}
								mode='multiple'
                defaultValue={['societies']}
								showSearch
								filterOption={(input, option) =>
									(option?.label ?? '')
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								options={allSocieties}
							/>
						</Form.Item>
						<Form.Item>
							<div className='flex justify-center mt-5'>
								<Button type='primary' htmlType='submit' className='w-full'>
									{isEdit ? 'Update' : 'Submit'}
								</Button>
							</div>
						</Form.Item>
					</Form>
				</div>
			</Spin>
		</Modal>
	);
}

export default CustomerModal;
