import { useEffect, useMemo, useState } from 'react';
import { Upload, Button, message, List } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const ImagesUpload = ({
  fileList: initialFileList = [],
  setFileList,
  acceptedTypes = 'image/*',
  maxSize = 2,
  maxFiles = 5,
  label = 'Upload',
  disablePreview = false,
}) => {
  const fileListState = useMemo(() => initialFileList, [initialFileList]);
  const [previews, setPreviews] = useState([]);

  const handleRemove = (file) => {
    const index = fileListState.indexOf(file);
    const newFileList = fileListState.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
    const newPreviews = previews.slice();
    newPreviews.splice(index, 1);
    setPreviews(newPreviews);
  };

  const handleBeforeUpload = (file) => {
    const isLtMaxSize = file.size / 1024 / 1024 < maxSize;
    if (!isLtMaxSize) {
      message.error(`Image must be smaller than ${maxSize}MB!`);
      return false;
    }

    const existingFile = fileListState.find(
      (f) => f.name === file.name && f.size === file.size
    );
    if (existingFile) {
      message.error(`${file.name} is already added.`);
      return false;
    }

    if (fileListState.length >= maxFiles) {
      message.error(`You can only upload up to ${maxFiles} images.`);
      return false;
    }

    if (!disablePreview) {
      const previewUrl = URL.createObjectURL(file);
      setPreviews([...previews, previewUrl]);
    }

    const newFileList = [...fileListState, file];
    setFileList(newFileList);

    return true;
  };

  const imagesProps = {
    onRemove: handleRemove,
    beforeUpload: handleBeforeUpload,
    fileList: fileListState,
    accept: acceptedTypes,
    showUploadList: false,
  };

  return (
    <>
      <Upload {...imagesProps} className='min-w-[200px] w-full'>
        <Button className='w-full' icon={<UploadOutlined />}>
          {label}
        </Button>
      </Upload>
      {fileListState.length > 0 ? (
        <List
          dataSource={fileListState}
          className='max-h-[130px] overflow-auto'
          renderItem={(file, index) => (
            <List.Item
              actions={[
                <Button size='small' danger onClick={() => handleRemove(file)}>
                  Remove
                </Button>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  !disablePreview && previews[index] ? (
                    <img
                      src={previews[index]}
                      alt={file.name}
                      className='rounded-[100%] h-[50px] w-[50px]'
                    />
                  ) : null
                }
                title={
                  file?.name || file?.substring(file?.lastIndexOf('/') + 1)
                }
              />
            </List.Item>
          )}
        />
      ) : null}
    </>
  );
};

export default ImagesUpload;
