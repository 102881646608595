import { createSlice } from '@reduxjs/toolkit';
import {
  createSale,
  deleteSale,
  getAllSales,
  getSaleById,
  updateSale,
  updateInstallment,
  deleteInstallment,
  confirmSales,
  getGraphInfo,
  getSalesByMonth,
} from './salesActions';

const salesSlice = createSlice({
  name: 'sales',
  initialState: {
    entities: {
      sales: [],
      graphInfo: [],
      metadata: {
        plot: {
          totalPlots: 0,
          totalPlotsSold: 0,
          totalPlotsAvailable: 0,
          totalPlotsReserved: 0,
        },
        societyTotal: 0,
        phasesTotal: 0,
      },
    },
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllSales.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllSales.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = {
          metadata: action.payload.metadata,
          sales: [...action.payload.sales],
        };
      })
      .addCase(getAllSales.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(createSale.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSale.fulfilled, (state, action) => {
        state.entities.sales.push(action.payload);
        state.loading = false;
      })
      .addCase(createSale.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateSale.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSale.fulfilled, (state, action) => {
        state.entities.sales = state.entities?.sales?.map((entity) =>
          entity._id === action.payload._id ? action.payload : entity
        );
        state.loading = false;
      })
      .addCase(updateSale.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteSale.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSale.fulfilled, (state, action) => {
        state.entities.sales = state.entities.sales.filter(
          (entity) => entity._id !== action.payload
        );
        state.loading = false;
      })
      .addCase(deleteSale.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSaleById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSaleById.fulfilled, (state, action) => {
        state.entities = action.payload.sale.sale;
        state.loading = false;
      })
      .addCase(getSaleById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateInstallment.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateInstallment.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.sale;
      })
      .addCase(updateInstallment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteInstallment.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteInstallment.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.sale;
      })
      .addCase(deleteInstallment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(confirmSales.pending, (state) => {
        state.loading = true;
      })
      .addCase(confirmSales.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(confirmSales.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getGraphInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGraphInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.graphInfo = action.payload;
      })
      .addCase(getGraphInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSalesByMonth.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSalesByMonth.fulfilled, (state, action) => {
        state.loading = false;
        state.entities.sales = action.payload;
      })
      .addCase(getSalesByMonth.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default salesSlice.reducer;
