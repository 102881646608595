import React from 'react';
import SalesForm from '@components/Forms/salesForm';

const MyForm = () => {
  return (
    <div
      className='bg-white flex flex-col 
      items-center container-padding h-full overflow-auto w-full'
    >
      <SalesForm />
    </div>
  );
};

export default MyForm;
