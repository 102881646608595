import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSlice from "./auth/authSlice";
import userSlice from "./users/userSlice";
import societySlice from "./society/societySlice";
import phaseSlice from "./phase/phaseSlice";
import plotSlice from "./plots/plotSlice";
import customerSlice from "./customer/customerSlice";
import bankSlice from "./banks/bankSlice";
import salesSlice from "./sales/salesSlice";
import expenseSlice from "./expense/expensesSlice";
import officeSlice from "./office/officeSlice";

// Configuring Redux Persist
const rootReducer = combineReducers({
  auth: authSlice,
  user: userSlice,
  society: societySlice,
  phase: phaseSlice,
  plot: plotSlice,
  customer: customerSlice,
  bank: bankSlice,
  sale: salesSlice,
  expense: expenseSlice,
  office: officeSlice,
});
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
