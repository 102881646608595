import React, { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import { useDispatch } from 'react-redux';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { getGraphInfo, getSalesByMonth } from '@store/sales/salesActions';
import {
  convertToTitleCase,
  getPastDateRange,
} from '@utils/functions';
import moment from 'moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ArcElement,
  Legend
);

const do_data = {
  labels: ['Food', 'Rent', 'Transportation', 'Entertainment', 'Other'],
  datasets: [
    {
      data: [300, 600, 200, 150, 100],
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4CAF50', '#9E9E9E'],
      hoverBackgroundColor: [
        '#FF6384',
        '#36A2EB',
        '#FFCE56',
        '#4CAF50',
        '#9E9E9E',
      ],
    },
  ],
};

const do_options = {
  plugins: {
    title: {
      display: true,
      text: 'Expenses Breakdown',
      font: {
        size: 16,
      },
    },
  },
};

const DonutChart = () => <Doughnut data={do_data} options={do_options} />;

export const options = {
  responsive: true,
  text: 'Sales Data by Product',
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Sales information',
    },
  },
};

// Function to generate a random number between min and max (inclusive)
function getRandomColor(alpha) {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

// const labels = ['January'];
// const productNames = ['Society A', 'Society B', 'Society C'];

// const datasets = productNames.map((productName) => ({
//   label: productName,
//   data: labels.map(() => Math.floor(Math.random() * 901) + 100), // Generate random data between 100 and 1000
//   backgroundColor: getRandomColor(0.5), // Generate a random color with 50% opacity
// }));

// const data = {
//   labels,
//   datasets,
// };

const PieChart = ({ info }) => {
  let labels = [];
  let data = [];
  let colors = [];

  const uppercaseKeysObject = [];
  for (const key in info) {
    if (info.hasOwnProperty(key)) {
      uppercaseKeysObject.push(convertToTitleCase(key));
    }
  }

  if (Array.isArray(info)) {
    labels = info.map((item) => convertToTitleCase(item._id));
    data = info.map((item) => item.total);
    colors = Array.from({ length: info.length }, () => getRandomColor(0.5));
  } else if (typeof info === 'object' && info !== null) {
    labels = uppercaseKeysObject;
    data = Object.values(info);
    colors = Array.from({ length: labels.length }, () => getRandomColor(0.5));
  }

  const dataSet = {
    labels: labels,
    datasets: [
      {
        label: '# of Votes',
        data: data,
        backgroundColor: colors,
      },
    ],
  };

  return <Doughnut data={dataSet} />;
};

function WorkPlace() {
  const dispatch = useDispatch();
  const [salesVsExpenses, setSalesVsExpenses] = useState();
  const [expenseBreakDown, setExpenseBreakDown] = useState();
  const [salesInfo, setSalesInfo] = useState({
    labels: [],
    datasets: [],
  });
  const [dateRange, setDateRange] = useState(() => {
    const pastRange = getPastDateRange(200);
    return {
      startDate: pastRange.startDate,
      endDate: pastRange.endDate,
    };
  });

  const { RangePicker } = DatePicker;

  useEffect(() => {
    dispatch(
      getGraphInfo({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      })
    )
      .unwrap()
      .then((res) => {
        setSalesVsExpenses(res?.salesVsExpenses);
        setExpenseBreakDown(res?.expensesByType);
      });

    if (dateRange.startDate && dateRange?.endDate)
      dispatch(
        getSalesByMonth({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        })
      )
        .unwrap()
        .then((res) => {
          // const totalSalesBySociety = {};
          const societyNames = [...new Set(res.map((item) => item.name))];
          // res.forEach((sale) => {
          //   const societyName = sale.name;
          //   totalSalesBySociety[societyName] =
          //     (totalSalesBySociety[societyName] || 0) + sale.sale;
          // });

          // const colors = societyNames.map(() => getRandomColor(0.5));
          const datasets = societyNames.map((name) => {
            // Filter the sales data for societies with the same name
            const salesForSociety = res
              .filter((sale) => sale.name === name)
              .map((sale) => sale.sale);

            // Calculate the total sales for this society
            const totalSales = salesForSociety.reduce(
              (acc, sale) => acc + sale,
              0
            );

            return {
              label: name,
              data: [450], // Use an array with the total sales for this society
              backgroundColor: getRandomColor(0.5),
            };
          });

          let data = {
            label: societyNames,
            datasets,
          };
          setSalesInfo(data);
        });
  }, [dateRange.startDate, dateRange.startDate]);

  const handleDateChange = (dates) => {
    const formattedDates = dates.map((date) => date.format('YY-MM-DD'));
    if (formattedDates[0] && formattedDates[1]) {
      setDateRange({
        startDate: formattedDates[0],
        endDate: formattedDates[1],
      });
    }
  };
  return (
    <div className='container-padding space-y-5 bg-white'>
      {/* 
      hidden for now as the work has not been done.. zeeshan 
      <div className='flex w-full justify-end items-end'>
        <RangePicker
          format='YYY-MM-DD'
          onChange={(value) => {
            handleDateChange(value);
          }}
          allowEmpty
          disabledDate={(current) => current && current > moment().endOf('day')}
        />
      </div> */}
      <div className='grid grid-cols-1'>
        <span className=''>
          <Bar options={options} data={salesInfo} />;
        </span>
      </div>
      <div className='grid grid-cols-1 lg:grid-cols-3 place-items-center'>
        {/* <span>
          <DonutChart />
        </span> */}
        <span className='cols-span-1 items-center'>
          <PieChart info={salesVsExpenses} />
        </span>
        <span>
          <PieChart info={expenseBreakDown} />
        </span>
      </div>
    </div>
  );
}

export default WorkPlace;
