import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../@api/axios';
import uploadFilesToS3 from '../../utils/s3FileUpload';
import { message } from 'antd';

const createSociety = createAsyncThunk(
  'society/add',
  async (userData, { rejectWithValue }) => {
    try {
      const [imageUrls, logoUrls] = await Promise.all([
        uploadFilesToS3(userData.images),
        uploadFilesToS3(userData.logo),
      ]);

      const response = await axiosInstance.post('/societies', {
        ...userData,
        ...{ images: imageUrls, logo: logoUrls[0] },
      });

      message.success('Society created Successfully');
      return response.data;
    } catch (err) {
      console.log(err);
      message.error('Society creation failed', err?.response?.data);
      throw err; // Throw the error to be caught by the rejected state
    }
  }
);

const fetchSocieties = createAsyncThunk(
  'society/fetchAll',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get('/societies');
      return response.data;
    } catch (err) {
      message.error('Something went wrong');
      return rejectWithValue(err.response.data);
    }
  }
);

const deleteSociety = createAsyncThunk(
  'society/delete',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.delete(`/societies/${id}`);
      message.success('Society deleted successfully');
      return id;
    } catch (err) {
      message.error('Something went wrong');
      return rejectWithValue(err.response.data);
    }
  }
);

const updateSociety = createAsyncThunk(
  'society/update',
  async ({ societyData, id }, { rejectWithValue }) => {
    let newImagesUrl;
    let newLogoUrl;

    try {
      const editedPhases = societyData.phases.filter(
        (obj) => obj.edited === true && obj._id
      );
      if (editedPhases.length >= 0) {
        try {
          const requests = editedPhases.map((phase) =>
            axiosInstance.put(`/phases/${phase._id}`, phase)
          );
          const responses = await Promise.all(requests);
          const data = responses.map((response) => response.data);
        } catch (err) {
          console.log('failed to update the phases in the societies', err);
        }
      }

      let newSocietyData = societyData;

      // --- Check if there are any new images to upload
      const newImages = newSocietyData.images.filter(
        (file) => typeof file !== 'string'
      );
      // --- Check if there are any new logo to upload
      const newLogos = newSocietyData.logo.filter(
        (file) => typeof file !== 'string'
      );
      //--- upload images and logos to the s3
      if (newImages.length >= 0) {
        newImagesUrl = await Promise.all([uploadFilesToS3(newImages)]);
      }
      if (newLogos.length >= 0) {
        newLogoUrl = await Promise.all([uploadFilesToS3(newLogos)]);
      }
      // --- check if images are upload then add/merge with the prev images
      if (newImagesUrl[0].length >= 0 && newImages) {
        newSocietyData.images = newSocietyData.images.concat(newImagesUrl[0]);
        newSocietyData.images = newSocietyData.images.filter(
          (item) => typeof item === 'string'
        );
      }
      // --- add the logo in existing ddata
      if (newLogos.length >= 0 && newLogoUrl[0].length >= 0) {
        newSocietyData.logo = newLogoUrl[0][0];
      }
      const response = await axiosInstance.put(`/societies/${id}`, {
        ...newSocietyData,
      });
      message.success('Society updated successfully');
      return response.data;
    } catch (err) {
      message.error('Something went wrong');
      return rejectWithValue(err.response.data);
    }
  }
);

const getSocietyById = createAsyncThunk(
  'society/getById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/societies/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const searchSocieties = createAsyncThunk(
  'society/search',
  async (query, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/societies/search?query=${query}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export {
  createSociety,
  fetchSocieties,
  deleteSociety,
  updateSociety,
  getSocietyById,
  searchSocieties,
};
