import { Form, Input, InputNumber, Button, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  createSociety,
  updateSociety,
} from '../../@store/society/societyActions'

const OfficeDetails = ({
  handlePrevious,
  officeDetailRef,
  formState,
  resetForm,
  current,
}) => {
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.society)
  const { id } = useParams()
  const navigate = useNavigate()
  const onFinish = (values) => {
    if (id) {
      const finalSocietyData = {
        societyData: {
          ...formState,
          ...values
        },
        id:id
      }
      dispatch(updateSociety({ ...finalSocietyData }))
        .unwrap()
        .then(() => {
          setTimeout(() => {
            navigate('/super-admin/societies/search-list')
          }, 3000)
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      const finalSocietyData = {
        offices: { ...values },
        ...formState,
      }
      dispatch(createSociety({ ...finalSocietyData }))
        .unwrap()
        .then(() => {
          resetForm()
        })
    }
  }
  const defaultValues = {
    financialResources: current?.offices?.financialResources || '',
    hr: current?.offices?.hr || '',
    location: current?.offices?.location || '',
    missionAndGoal: current?.offices?.missionAndGoal || '',
    stakeHolder: current?.offices.stakeHolder || '',
  }

  if (!current && id) {
    return (
      <div className="flex items-center justify-center h-[70vh]">
        <Spin loading={true} />
      </div>
    )
  } else {
    return (
      <Form
        name="mission-form"
        onFinish={onFinish}
        layout="vertical"
        ref={officeDetailRef}
        initialValues={defaultValues}
      >
        <div className="flex justify-between">
          <Form.Item className="flex justify-end">
            <Button onClick={() => handlePrevious('3')} type="default">
              Previous
            </Button>
          </Form.Item>
          <Form.Item className="flex justify-end">
            <Button loading={loading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </div>
        <Form.Item
          label="Mission"
          name="missionAndGoal"
          rules={[{ required: true, message: 'Please input your mission!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Location"
          name="location"
          rules={[{ required: true, message: 'Please input your location!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="HR"
          name="hr"
          rules={[{ required: true, message: 'Please input your HR!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Stakeholders"
          name="stakeHolder"
          rules={[
            { required: true, message: 'Please input your stakeholders!' },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Financial Resources"
          name="financialResources"
          rules={[
            {
              required: true,
              message: 'Please input your financial resources!',
            },
          ]}
        >
          <InputNumber className="w-full" />
        </Form.Item>
      </Form>
    )
  }
}

export default OfficeDetails
