import React from 'react';
import { Modal, Table, Form, Input, DatePicker, Button, Spin } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { updateInstallment } from '../../@store/sales/salesActions';

const InstallmentModal = ({
  openModal = false,
  saleId,
  handleClose,
  setCreatedInstallments,
  createdInstallments,
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.sale);
  const handleFormSubmit = (values) => {
    const { installmentNumber, paymentDate, paymentAmount } = values;
    const formattedDate = moment(paymentDate).format('YYYY-MM-DD');
    setCreatedInstallments([
      ...createdInstallments,
      {
        installmentNumber,
        paymentDate: formattedDate,
        paymentAmount,
      },
    ]);
    form.resetFields();
    handleClose();
  };

  const [form] = Form.useForm();

  return (
    <Modal open={openModal} onCancel={handleClose} footer={null} width={500}>
      <Spin spinning={loading}>
        <Form layout='vertical' form={form} onFinish={handleFormSubmit}>
          <Form.Item
            label='Installment Number'
            name='installmentNumber'
            rules={[
              {
                required: true,
                message: 'Please enter the installment number',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Payment Date'
            name='paymentDate'
            className='w-full'
            rules={[
              { required: true, message: 'Please select the payment date' },
            ]}
          >
            <DatePicker className='w-full' format='YYYY-MM-DD' />
          </Form.Item>

          <Form.Item
            label='Payment Amount'
            name='paymentAmount'
            rules={[
              { required: true, message: 'Please enter the payment amount' },
            ]}
          >
            <Input type='number' />
          </Form.Item>

          <Form.Item>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default InstallmentModal;
