import { Layout, Spin } from 'antd'
import { NavBar, SideBar } from '../Layout'
import React, { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { SUPER_ADMIN } from '../../utils/mock/sidebar'
import { verifyToken } from '../../@store/auth/authActions'
import Spinner from '../macros/Spinner'

const { Content } = Layout

function SuperAdmin() {
  const { loading, isTokenValid, entities } = useSelector((state) => state.auth)
  const [isValid, setIsValid] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(verifyToken())
      .unwrap()
      .then((res) => {
        setIsValid(res)
      })
  }, [])

  if (loading) {
    return <Spinner size="middle" />
  }

  if (!isValid && loading) {
    return <Spinner size="middle" />
  }

  if (isValid === false) {
    return <Navigate to="/" />
  }

  if (
    isValid &&
    entities?.role === 'superAdmin' &&
    localStorage.getItem('token')
  ) {
    return (
      <div className="max-h-[100vh]">
        <NavBar />
        <Layout>
          <SideBar routes={SUPER_ADMIN} />
          <Content className="overflow-auto max-h-[90vh] bg-[white] w-full">
            <Outlet />
          </Content>
        </Layout>
      </div>
    )
  }
}

export { SuperAdmin }
