import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Form,
	Input,
	Button,
	Spin,
	Radio,
	InputNumber,
	message,
	Select,
} from 'antd';
import {
	FacebookOutlined,
	InstagramOutlined,
	TwitterOutlined,
	GlobalOutlined,
} from '@ant-design/icons';
import ImagesUpload from '../macros/ImageUpload';
import useBanks from '../../hooks/useBanks';
import { getAllUsers } from '../../@store/users/userActions';
import { getKeyLength, isArrayContainObject } from '../../utils/functions';
import { validatePhone } from '../../utils/validators/inputValidators';

const SocietyDetail = ({
	onNext,
	societyDetailRef,
	setFormState,
	logo,
	setLogo,
	fileList,
	setFileList,
	current,
}) => {
	const [form] = Form.useForm();
	const { id } = useParams();
	const { loading, formedBanks, getBanksByIds } = useBanks('main');
	const { formedBanks: childFoundBanks } = useBanks('sub');
	const dispatch = useDispatch();

	const [societyHeads, setSocietyHeads] = useState();
	const [childrenBanks, setChildrenBanks] = useState();

	const onFinish = (values) => {
		let users = [];
		let banks = [];

		if (id) {
			if (isArrayContainObject(values?.users)) {
				users = values?.users?.map((u) => u.value);
			}
			if (values?.childBanks) {
				banks.push(...values.childBanks);
			}
			if (values?.banks) {
				banks.push(...values.banks);
			}
		}
		if (values.name === values.location) {
			message.info('Name and location values cannot be same.');
			return;
		}
		if (values.childBanks) {
			banks.push(...values.childBanks);
		}
		if (values.banks) {
			banks.push(...values.banks);
		}
		if (fileList.length === 0 && logo.length === 0) {
			message.info('Please select logo and minimum 1 image.');
			return;
		}
		if (logo.length === 0) {
			message.info('Please select logo first');
			return;
		}
		if (fileList.length === 0) {
			message.info('Please select minimum 1 image.');
			return;
		}

		setFormState((prev) => ({
			...prev,
			images: fileList,
			logo: logo,
			name: values.name,
			users: users?.length > 0 ? users : values.users,
			location: values.location,
			contact: {
				phone: values.phone,
				email: values.email,
				website: values.website,
			},
			address: {
				street: values.street,
				city: values.city,
				state: values.state,
				country: values.country,
				zip: values.zip,
			},
			social: {
				facebook: values.facebook,
				twitter: values.twitter,
				instagram: values.instagram,
			},
			debit: values.debit,
			credit: values.credit,
			status: values.status,
			description: values.description,
			banks: banks?.length > 0 ? banks : values.banks,
		}));
		onNext('2');
	};

	useEffect(() => {
		if (!id) {
			setFileList([]);
			form.resetFields();
			setFileList([]);
			setLogo([]);
		}
	}, [id, form]);

	useEffect(() => {
		dispatch(getAllUsers())
			.unwrap()
			.then((users) => {
				if (users?.length > 0) {
					const societyHeads = users.filter((u) => u.role === 'societyHead');
					if (societyHeads.length > 0) {
						const formatted = societyHeads.map((s) => {
							return {
								label: s.name,
								value: s._id,
							};
						});
						setSocietyHeads(formatted);
					}
				}
			});
	}, [dispatch]);

	const onFinishFailed = (errorInfo) => {};

	let defaultValuesForm = {};

	const parentBankDefaultValue =
		(Array.isArray(formedBanks) &&
			current?.banks.length > 0 &&
			formedBanks?.filter((bank) => current?.banks.includes(bank.value))) ||
		[];

	const childBankDefaultValue =
		(Array.isArray(childFoundBanks) &&
			current?.banks.length > 0 &&
			childFoundBanks?.filter((bank) => current?.banks.includes(bank.value))) ||
		[];

	const defaultUsers =
		(Array.isArray(societyHeads) &&
			current?.users.length > 0 &&
			societyHeads?.filter((user) => current?.users.includes(user.value))) ||
		[];

	useEffect(() => {
		// only for the banks and users
		// the are not setting up with other fields in editing mode.
		if (
			id &&
			getKeyLength(current) > 0 &&
			defaultUsers?.length > 0 &&
			parentBankDefaultValue?.length > 0
		)
			form.setFieldValue('users', defaultUsers);
		form.setFieldValue('banks', parentBankDefaultValue);
	}, [id, current, defaultUsers?.length, parentBankDefaultValue?.length]);

	if (id) {
		defaultValuesForm = {
			name: current?.name && id ? current.name : '',
			city: current?.address?.city && id ? current.address.city : '',
			country: current?.address?.country && id ? current.address.country : '',
			state: current?.address?.state && id ? current.address.state : '',
			zip: current?.address?.zip && id ? current.address.zip : '',
			street: current?.address?.street && id ? current.address.street : '',
			phases: current?.phases && id ? current.phases : '',
			phone: current?.contact?.phone && id ? current.contact.phone : '',
			email: current?.contact?.email && id ? current.contact.email : '',
			debit: current?.debit && id ? current.debit : '',
			credit: current?.credit && id ? current.credit : '',
			images: [],
			logo: null,
			location: current?.location && id ? current.location : '',
			facebook: current?.social?.facebook && id ? current.social.facebook : '',
			instagram:
				current?.social?.instagram && id ? current.social.instagram : '',
			twitter: current?.social?.twitter && id ? current.social.twitter : '',
			website: current?.contact?.website && id ? current.contact.website : '',
			status: current?.status && id ? current.status : 'active',
			description: current?.description && id ? current.description : '',
		};
	}

	const handleParentBankChange = (value) => {
		const banks = getBanksByIds(value);
		if (banks.length > 0) {
			setChildrenBanks([...banks]); // Use the spread operator inside an array
		}
		// form.resetFields(['childBanks']);
	};

	if (!current && id) {
		return (
			<div className='flex items-center justify-center h-[70vh]'>
				<Spin loading={true} />
			</div>
		);
	} else {
		return (
			<Form
				name='my-form'
				layout='vertical'
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				initialValues={defaultValuesForm}
				ref={societyDetailRef}
				preserve={false}
				form={form}
			>
				<Form.Item className='flex justify-end'>
					<Button type='primary' htmlType='submit'>
						Next
					</Button>
				</Form.Item>
				<Form.Item
					label='Select Bank'
					name='banks'
					style={{ width: '100%' }}
					rules={[
						{
							required: !current && id && formedBanks.length > 0,
							message: 'Please select bank',
						},
					]}
				>
					<Select
						getPopupContainer={(triggerNode) => {
							return triggerNode.parentNode;
						}}
						mode='multiple'
						defaultValue={parentBankDefaultValue}
						onChange={handleParentBankChange}
						showSearch
						filterOption={(input, option) =>
							(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
						}
						options={formedBanks}
					/>
				</Form.Item>

				<Form.Item
					label='Select Child Bank'
					name='childBanks'
					style={{ width: '100%' }}
					rules={[
						{
							required: !current && id && childrenBanks.length > 0,
							message: 'Please select child bank',
						},
					]}
				>
					<Select
						getPopupContainer={(triggerNode) => {
							return triggerNode.parentNode;
						}}
						mode='multiple'
						defaultValue={childBankDefaultValue}
						showSearch
						filterOption={(input, option) =>
							(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
						}
						options={childrenBanks}
					/>
				</Form.Item>

				<Form.Item
					label='Select Society Head'
					name='users'
					style={{ width: '100%' }}
					rules={[
						{ required: true, message: 'Please enter the society head name' },
					]}
				>
					<Select
						getPopupContainer={(triggerNode) => {
							return triggerNode.parentNode;
						}}
						mode='multiple'
						defaultValue={defaultUsers}
						showSearch
						filterOption={(input, option) =>
							(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
						}
						options={societyHeads}
					/>
				</Form.Item>

				<Form.Item
					label='Name'
					shouldUpdate={(prevValues, curValues) =>
						prevValues.name !== curValues.name
					}
					name='name'
					rules={[{ required: true, message: 'Please enter the society name' }]}
				>
					<Input placeholder='Society name' />
				</Form.Item>

				<div className='grid gap-3 grid-cols-2'>
					<Form.Item
						rules={[{ required: true, message: 'Please enter location' }]}
						label='Location'
						name='location'
					>
						<Input />
					</Form.Item>
					<Form.Item
						rules={[{ required: true, message: 'Please enter street' }]}
						label='Street'
						name='street'
					>
						<Input />
					</Form.Item>
					<Form.Item
						rules={[{ required: true, message: 'Please enter city' }]}
						label='City'
						name='city'
					>
						<Input />
					</Form.Item>
					<Form.Item
						rules={[{ required: true, message: 'Please enter state' }]}
						label='State'
						name='state'
					>
						<Input />
					</Form.Item>
					<Form.Item
						rules={[{ required: true, message: 'Please enter country' }]}
						label='Country'
						name='country'
					>
						<Input />
					</Form.Item>
					<Form.Item
						rules={[{ required: true, message: 'Please enter Zip code' }]}
						label='Zip'
						name='zip'
					>
						<Input />
					</Form.Item>
				</div>

				<div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
					<Form.Item name='images'>
						<ImagesUpload
							fileList={fileList}
							setFileList={setFileList}
							acceptedTypes={'.png,.jpg,.jpeg'}
							maxSize={2}
							maxFiles={10}
							label='Upload Images'
						/>
					</Form.Item>
					<Form.Item name='logo'>
						<ImagesUpload
							fileList={logo}
							setFileList={setLogo}
							acceptedTypes={'.png,.jpg,.jpeg'}
							maxSize={2}
							maxFiles={1}
							form={form}
							label='Upload Logo'
						/>
					</Form.Item>
				</div>

				<Form.Item
					label='Contact'
					name='phone'
					rules={[
						{
							required: true,
							message: 'Please enter your contact',
						},
						{
							validator: validatePhone, // Use the custom validation function
						},
					]}
				>
					<Input placeholder='Please add contact number' />
				</Form.Item>

				<Form.Item
					label='Email'
					name='email'
					rules={[
						{
							type: 'email',
							message: 'Please enter a valid email address',
						},
						{
							required: true,
							message: 'Please enter your email',
						},
					]}
				>
					<Input placeholder='Please add your email' />
				</Form.Item>
				<div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
					<Form.Item
						label='Debit'
						name='debit'
						className='w-full'
						rules={[{ required: true, message: 'Please enter your message' }]}
					>
						<InputNumber className='w-full' placeholder='Debit Amount' />
					</Form.Item>
					<Form.Item
						label='Credit'
						name='credit'
						rules={[{ required: true, message: 'Please enter your message' }]}
					>
						<InputNumber className='w-full' placeholder='Credit Amount' />
					</Form.Item>
				</div>
				<div className='grid grid-cols-1 lg:grid-cols-2 gap-3'>
					<Form.Item name='facebook' label='Facebook'>
						<Input
							prefix={<FacebookOutlined type='filled' />}
							placeholder='Enter Facebook link'
						/>
					</Form.Item>
					<Form.Item name='instagram' label='Instagram'>
						<Input
							prefix={<InstagramOutlined type='filled' />}
							placeholder='Enter Instagram link'
						/>
					</Form.Item>
					<Form.Item name='twitter' label='Twitter'>
						<Input
							prefix={<TwitterOutlined type='filled' />}
							placeholder='Enter Twitter link'
						/>
					</Form.Item>
					<Form.Item name='website' label='Website'>
						<Input
							prefix={<GlobalOutlined type='filled' />}
							placeholder='Enter Website link'
						/>
					</Form.Item>
				</div>
				<Form.Item name='status' label='Status'>
					<Radio.Group>
						<Radio value='active'>Active</Radio>
						<Radio value='inactive'>Inactive</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item
					label='Description'
					name='description'
					rules={[
						{ required: true, message: 'Please enter description in detail.' },
					]}
				>
					<Input.TextArea placeholder='Please write something about your society' />
				</Form.Item>
			</Form>
		);
	}
};

export default SocietyDetail;
