import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../@api/axios";
import { message } from "antd";

const createOffice = createAsyncThunk(
  "office/create",
  async (officeData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/offices", officeData);
      message.success("Office created Successfully");
      return response.data;
    } catch (err) {
      message.error(err.message);
      return rejectWithValue(err.response.data);
    }
  }
);


const getAllOffices = createAsyncThunk(
  "office/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/offices");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const getOfficeById = createAsyncThunk(
  "office/getById",
  async (officeId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/offices/${officeId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const updateOffice = createAsyncThunk(
  "office/update",
  async ({ officeId, office }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/offices/${officeId}`,
        office
      );
      message.success("Office updated Successfully");
      return response.data;
    } catch (err) {
      message.error(err.message);
      return rejectWithValue(err.response.data);
    }
  }
);

const deleteOffice = createAsyncThunk(
  "office/delete",
  async (officeId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/offices/${officeId}`);
      message.success("Office deleted Successfully");
      return response.data;
    } catch (err) {
      message.error(err.message);
      return rejectWithValue(err.response.data);
    }
  }
);

export {
    createOffice,
    getAllOffices,
    getOfficeById,
    updateOffice,
    deleteOffice,
};
