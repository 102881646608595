import React, { useState } from 'react'
import { Select } from 'antd'

function DropDown({ onSelect, label, options }) {
  const [value, setValue] = useState(null)
  const handleChange = (value) => {
    onSelect(value)
  }

  return (
    <div className="flex gap-3 items-center">
      <label htmlFor="my-select">{label}:</label>
      <Select
        defaultValue="lucy"
        className="w-[120px]"
        onChange={handleChange}
        labelInValue="hello"
        options={options}
      />
    </div>
  )
}

export default DropDown
