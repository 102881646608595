import { Layout } from 'antd'
import { NavBar, SideBar } from '../Layout'
import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { ADMIN, SOCIETY_HEAD } from '../../utils/mock/sidebar'
import { useSelector } from 'react-redux'

const { Content } = Layout

function SocietyHead() {
  const { entities } = useSelector((state) => state.auth)
  if (
    entities.token &&
    entities.role === 'societyHead' &&
    localStorage.getItem('token')
  ) {
    return (
      <Layout className="h-[100vh]">
        <NavBar />
        <Layout>
          <SideBar routes={SOCIETY_HEAD} />
          <Content className="overflow-auto w-full">
            {/* <BreadCrumb /> */}
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    )
  } else {
    return <Navigate to="/" />
  }
}

export { SocietyHead }
