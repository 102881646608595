import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../@api/axios";
import { message } from "antd";

//     "name": "Plot A-1",
//     "area": 500,
//     "price": 2000,
//     "customerPayable": 2000,
//     "phase": "643c92b378278bdc61a44011"

const createPlot = createAsyncThunk(
  "/plots/add",
  async (plotData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/plots", plotData);
      message.success("Plot created Successfully");
      return response.data;
    } catch (err) {
      message.error("Plot creation failed", err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchPlotByPhaseId = createAsyncThunk(
  "/plots/fetchByPhaseId",
  async (phaseId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/plots/phase/${phaseId}`);
      return response.data;
    } catch (err) {
      message.error("Something went wrong");
      return rejectWithValue(err.response.data);
    }
  }
);

const deletePlot = createAsyncThunk(
  "/plots/delete",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.delete(`/plots/${id}`);
      message.success("Plot deleted successfully");
      return id;
    } catch (err) {
      message.error("Something went wrong");
      return rejectWithValue(err.response.data);
    }
  }
);

const updatePlot = createAsyncThunk(
  "/plots/update",
  async ( plotData, { rejectWithValue }) => {
    try {
        console.log("plotData: ", plotData)
      const response = await axiosInstance.put(`/plots/${plotData._id}`, plotData);
      message.success("Plot updated successfully");
      return response.data;
    } catch (err) {
      message.error("Something went wrong");
      return rejectWithValue(err.response.data);
    }
  }
);

export { createPlot, fetchPlotByPhaseId, deletePlot, updatePlot };
