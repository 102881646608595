import React from 'react';
import {
	BsFillHouseFill,
	BsPeopleFill,
	BsCheckCircleFill,
	BsClockFill,
	BsCurrencyDollar,
} from 'react-icons/bs';
import { SmallCard } from '../components/macros';

function SalesMetadata({ metadata, total }) {
	return (
		<div>
			<div className='grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 w-full mb-4'>
				<SmallCard
					cardHeight={'30px'}
					subheading='Total Plots'
					Icon={<BsFillHouseFill fill='#504AE5' size='30' />}
					variant='white'
					title={metadata.plot.totalPlots}
				/>

				<SmallCard
					subheading='Total Plots Sold'
					cardHeight={'30px'}
					Icon={<BsCheckCircleFill fill='#504AE5' size='30' />}
					variant='white'
					title={metadata.plot.totalPlotsSold}
				/>

				<SmallCard
					subheading='Total Plots Available'
					cardHeight={'30px'}
					Icon={<BsClockFill fill='#504AE5' size='30' />}
					variant='white'
					title={metadata.plot.totalPlotsAvailable}
				/>

				<SmallCard
					subheading='Total Plots Reserved'
					cardHeight={'30px'}
					Icon={<BsCurrencyDollar fill='#504AE5' size='30' />}
					variant='white'
					title={metadata.plot.totalPlotsReserved}
				/>

				<SmallCard
					subheading='Total Phases'
					Icon={<BsPeopleFill fill='#504AE5' size='30' />}
					cardHeight={'30px'}
					variant='white'
					title={metadata.phasesTotal}
				/>
			</div>

			<div className='flex flex-col sm:flex-row justify-between gap-4'>
				<SmallCard
					subheading='Total Societies'
					cardHeight={'60px'}
					Icon={<BsPeopleFill fill='#504AE5' size='30' />}
					variant='white'
					title={metadata.societyTotal}
				/>

				<SmallCard
					subheading='Total Sales'
					Icon={<BsCurrencyDollar fill='#504AE5' size='30' />}
					variant='white'
					cardHeight={'60px'}
					title={`${total} PKR`}
				/>
			</div>
		</div>
	);
}

export default SalesMetadata;
