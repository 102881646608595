import React, { useState } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import { updateUser } from '../../@store/users/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { data } from 'autoprefixer';

export default function ProfileModal({ visible, setProfileVisible, onCancel }) {
  const userProfile = JSON.parse(localStorage.getItem('user'));
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleOk = (data) => {
    dispatch(
      updateUser({
        userId: userProfile._id,
        userData: {
          ...data,
          password: data.newPassword,
        },
      })
    )
      .unwrap()
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res));
        setProfileVisible(false);
        form.resetFields();
      });
  };

  return (
    <Modal
      open={visible}
      title='User Profile'
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      footer={false}
    >
      <Form onFinish={handleOk} form={form}>
        <div className='flex-col'>
          <h4 className='flex  pt-2 pb-2 justify-between mr-2 ml-3'>
            <div>Name:</div>
            {userProfile.name}
          </h4>
          <h4 className='flex justify-between  pt-2 pb-2 mr-2 ml-3'>
            <div>Email:</div>
            {userProfile.email}
          </h4>
          <h4 className='flex justify-between mr-2 ml-3  pt-2 pb-2'>
            <div>Phone:</div>
            {userProfile.phone}
          </h4>
          <h4 className='flex justify-between mr-2 ml-3  pt-2 pb-2'>
            <div>CNIC:</div>
            {userProfile.cnic}
          </h4>
        </div>
        <Form.Item
          label='New Password'
          name='newPassword'
          rules={[{ required: true, message: 'Please enter a new password' }]}
        >
          <Input.Password className='ml-[29%] w-[71%]' />
        </Form.Item>
        <Form.Item
          label='Confirm Password'
          name='confirmPassword'
          dependencies={['newPassword']}
          rules={[
            { required: true, message: 'Please confirm your password' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords do not match')
                );
              },
            }),
          ]}
        >
          <Input.Password className='ml-[25%] w-3/4' />
        </Form.Item>
        <div className='w-full flex justify-end gap-2'>
          <Button
            key='cancel'
            onClick={() => {
              form.resetFields();
              onCancel();
            }}
          >
            Cancel
          </Button>

          <Button
            key='update'
            type='primary'
            htmlType='submit'
            loading={loading}
            // onClick={handleOk}
          >
            Update Password
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
